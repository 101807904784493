<template>
<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <g opacity="0.5" :fill="color" fill-rule="nonzero">
    <path d="M8,0 C3.58866667,0 0,3.55925137 0,7.93442623 C0,12.3096011 3.58866667,15.8688525 8,15.8688525 C12.4113333,15.8688525 16,12.3096011 16,7.93442623 C16,3.55925137 12.4113333,0 8,0 Z M9.772,12.0894208 C9.33133333,12.2613333 7.804,12.9846885 6.924,12.2157104 C6.66133333,11.9869344 6.53066667,11.6966667 6.53066667,11.3442459 C6.53066667,10.6843661 6.74933333,10.1091202 7.14333333,8.72786885 C7.21266667,8.46669399 7.29733333,8.12815847 7.29733333,7.85971038 C7.29733333,7.39620765 7.12,7.27322404 6.63933333,7.27322404 C6.40466667,7.27322404 6.14466667,7.35587432 5.90933333,7.44315301 L6.03933333,6.91485246 C6.564,6.70326776 7.22266667,6.44539891 7.78666667,6.44539891 C8.63266667,6.44539891 9.25533333,6.86393989 9.25533333,7.66002732 C9.25533333,7.88946448 9.21533333,8.29147541 9.13133333,8.56918033 L8.64466667,10.2764044 C8.544,10.6215519 8.362,11.3825956 8.644,11.6080656 C8.92133333,11.8308907 9.578,11.7125355 9.902,11.5611202 L9.772,12.0894208 Z M8.968,5.28961749 C8.416,5.28961749 7.968,4.84528962 7.968,4.29781421 C7.968,3.7503388 8.416,3.30601093 8.968,3.30601093 C9.52,3.30601093 9.968,3.7503388 9.968,4.29781421 C9.968,4.84528962 9.52,5.28961749 8.968,5.28961749 Z" />
  </g>
</svg>
</template>

<script>

export default {
  name: 'info',
  props: {
    color: {
      type: String,
      default: '#7C7C7E',
    },
  },
};
</script>
