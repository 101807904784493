<template>
<form class="login-form login-modal-form" @submit="handleSubmit">
  <div class="login-form-title">
    {{ $t('auth.signup') }}
  </div>
  <div class="login-subtitle">
    {{ $t('auth.already_have_account') }}
    <span class="signup-toggle" @click="gotoLogin">{{ $t('auth.login_here') }}</span>
  </div>
  <SocialButton socialType="facebook" @click="handleSocialSignup" />
  <SocialButton socialType="google" @click="handleSocialSignup" />
  <div v-if="socialError" class="error-message">
    {{ socialError }}
  </div>
  <div class="signup-or-wrap">
    <div class="signup-or-border" />
    <div class="signup-or">
      {{ $t('auth.or') }}
    </div>
    <div class="signup-or-border" />
  </div>
  <TitledInput
    v-model="email"
    name="email"
    class="form-input"
    type="email"
    :errorMessage="errorEmail"
    :placeholder="$t('auth.placeholders.email')"
    @input="clearErrors"
  />
  <TitledInput
    v-model="password"
    name="password"
    class="form-input"
    :inputClass="(!passwordValid && password.length) ? 'invalid' : ''"
    type="password"
    :placeholder="$t('auth.placeholders.create_password')"
    :errorMessage="errorPassword"
    :showError="password.length >= 1 && !passwordValid"
  />
  <PasswordValidate
    v-if="password.length > 0"
    :password="password"
    @password-validation="getPasswordStatus"
  />
  <div v-if="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
  <button
    type="submit"
    class="form-control login-modal-button"
    :class="(!passwordValid && password.length) ? 'invalid' : ''"
    :disabled="errorMessage || errorEmail || errorPassword || errorConfirm || !passwordValid"
  >
    <div>{{ $t('signup') }}</div>
    <Arrow color="white" />
  </button>
  <div class="disclaimer">
    {{ $t('auth.disclaimer') }}<span>{{ $t('auth.read') }}</span>
  </div>
</form>
</template>

<script>
import SocialButton from '@/components/widget/SocialButton.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import PasswordValidate from '@/components/widget/PasswordValidate.vue';
import Arrow from '@/components/svg/Arrow.vue';
import { validateEmail } from '@/utils/stringUtils';
import { userRegister } from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';

export default {
  name: 'signup',
  components: {
    SocialButton,
    TitledInput,
    Arrow,
    PasswordValidate,
  },
  props: {
    loginUser: {
      type: Function,
      default: () => {},
    },
    socialLoginSignup: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: null,
      errorEmail: null,
      errorPassword: null,
      errorConfirm: null,
      socialError: null,
      passwordValid: false,
    };
  },
  methods: {
    getPasswordStatus(status) {
      this.passwordValid = status;
    },
    gotoLogin() {
      this.email = '';
      this.password = '';
      this.clearErrors();
      this.$emit('goto-login');
    },
    clearErrors() {
      this.errorMessage = null;
      this.errorEmail = null;
      this.errorPassword = null;
      this.errorConfirm = null;
      this.socialError = null;
    },
    async handleSubmit(e) {
      e.preventDefault();
      const { email, password } = this;
      this.clearErrors();
      if(!email || email === '') {
        this.errorEmail = this.$t('errors.EMAIL_MISSING');
      } else if(!validateEmail(email)) {
        this.errorEmail = this.$t('errors.INVALID_VALUE.email');
      } else if(!password || password === '') {
        this.errorPassword = this.$t('errors.PASSWORD_MISSING');
      } else {
        const user = { email, password };
        if(this.password.length && this.passwordValid) {
          try {
            await userRegister(user);
            const credentials = {
              username: email,
              password,
            };

            await this.loginUser(credentials, true);
          } catch(err) {
            this.errorMessage = this.$t(fieldErrorKey(err.errors));
          }
        }
      }
    },
    async handleSocialSignup(type) {
      this.clearErrors();
      const success = await this.socialLoginSignup(type);
      if(!success) {
        this.socialError = `${type} signup currently unavailable.`;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.disclaimer {
  @include p_info;
  text-align: center;
  & > span {
    padding-left: 8px;
    font-weight: 700;
    cursor: pointer;
  }
}

</style>
