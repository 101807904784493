import { validateYouTubeUrl } from '@/utils/stringUtils';
import { arraySum } from '@/utils';
import { ProjectStatus } from '@/utils/constants';
import moment from 'moment';

export function copyFields(source, fieldNames) {
  const value = {};
  for(let i = 0; i < fieldNames.length; i += 1) {
    value[fieldNames[i]] = source[fieldNames[i]];
  }
  return value;
}

export function centsToDollars(cents) {
  const dollars = cents / 100;
  return parseFloat(dollars).toFixed(0);
}

export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

// Remove top level null values from an object
export function filterNull(obj) {
  const filteredObj = {};
  Object.keys(obj).forEach((key) => {
    if(obj[key] !== null) {
      filteredObj[key] = obj[key];
    }
  });
  return filteredObj;
}

// Transforms project fields to format accepted by backend
export function prepareProject(project) {
  const { category, main_video: mainVideo } = project;
  if(category) {
    project.category_id = category.id;
    delete project.category;
  }
  if(mainVideo && mainVideo) {
    project.main_video = { en: validateYouTubeUrl(project.main_video) } || null;
  }
  return project;
}

// Generate `count` project placeholders.
export function makeProjectPlaceholders(projects, count) {
  for(let i = 0; i < count; i += 1) {
    projects.push({ emptyProject: true, reserve_percent: 20 });
  }
  return projects;
}

export function isCrowdfundingProject(project) {
  const now = moment();

  if('preorder_start_time' in project) {
    if(now.isSameOrAfter(project.preorder_start_time)) {
      return false;
    }
  }
  if('storefront_start_time' in project) {
    if(now.isSameOrAfter(project.storefront_start_time)) {
      return false;
    }
  }

  return true;
}

export function currencyDisplay(currency) {
  return (currency === 'USD') ? '$' : `${currency} `;
}

export function priceDisplay(price, currency) {
  const display = (price / 100).toLocaleString();
  return `${currencyDisplay(currency)}${display}`;
}

export function listingFee(reserve) {
  // Less than 10% "Milestone Funds" -> 5% fee
  // < 20% -> 4% fee
  // < 30% -> 3% fee
  // < 40% -> 2% fee
  // Greater than 40% -> 1% fee
  if(reserve < 10) return 5;
  if(reserve < 20) return 4;
  if(reserve < 30) return 3;
  if(reserve < 40) return 2;
  if(reserve < 50) return 1;
  return 0;
}

export function reservePercent(milestones) {
  return arraySum(milestones.map(m => m.release_percent));
}

export function startingFundsPercent(milestones) {
  const reserve = reservePercent(milestones);
  return 100 - (reserve + listingFee(reserve));
}

export function projectStatus(project) {
  if(!project) {
    return ProjectStatus.DRAFT;
  }
  return project.status;
}

export function projectFundingPercent(project) {
  const goal = project.funding_goal;
  if(goal) {
    return Math.ceil(100 * (project.total_pledged / goal));
  }
  return 0;
}

export function cartShippingCost(cart, countryCode) {
  if(!cart.needsShipping) {
    return 0;
  }
  const products = cart.products || {};
  return Object.values(products).reduce((sum, product) => {
    const shipping = product.shippingCost;
    if(!shipping) {
      return sum;
    }
    if(countryCode in shipping) {
      return sum + (product.quantity * shipping[countryCode]);
    }
    if('*' in shipping) {
      return sum + (product.quantity * shipping['*']);
    }
    return sum;
  }, 0);
}

const paymentMapping = {
  CHECKED_OUT: 'Pending',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  COMPLETED: 'Collected',
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
};

export function variantImage(variant, reward) {
  const imageUrl = (variant.image || {}).url || (reward.image || {}).url;
  return imageUrl || require('@/static/img/reward_default.png'); // eslint-disable-line
}

export function formatShipping(shippingAddress) {
  const { line1, line2, city, state } = (shippingAddress || {});
  let shippingLine2 = '';
  if(shippingAddress) {
    shippingLine2 = `${city}, ${state} ${shippingAddress.postal_code} ${shippingAddress.country_code}`;
  }
  return {
    line1: (line1 || '?') + (line2 || ''),
    line2: shippingLine2,
  };
}

export function formatProduct(product) {
  const variant = product.reward_variant || {};
  return {
    id: variant.id,
    name: product.reward.title,
    variantName: variant.name || '',
    priceUsd: product.usd_price,
    quantity: product.quantity,
    rewardId: product.reward.id,
    donation: product.reward.is_donation,
    needsShipping: product.reward.needs_shipping,
    shippingCost: product.reward.shipping_cost,
    imageUrl: variantImage(variant, product.reward),
  };
}

export function formatPledge(pledge) {
  const shippingCost = pledge.shipping_price || 0;
  const total = pledge.sub_total_usd + shippingCost;
  return {
    ...pledge,
    created: moment(pledge.created).format('MM/DD/YY'),
    formattedSubtotal: `$${centsToDollars(pledge.sub_total_usd)}`,
    formattedTotal: `$${centsToDollars(total)}`,
    total,
    payment_status: paymentMapping[pledge.status] || 'Unknown',
    products: pledge.products && pledge.products.map(formatProduct),
  };
}

export function menuFromTranslation(obj) {
  return Object.entries(obj).map(([key, value]) => ({
    key,
    title: value,
  }));
}

export function formatCardAPI(card, save = false) {
  const address = card.billing_address || {};
  let expiration = card.expiration || '';
  if(expiration && typeof expiration === 'string' && expiration.length !== 5) {
    expiration = moment(expiration).format('MM/YY');
  }
  const number = card.card_number || '';
  return {
    card_number: number.replace(/\s/g, ''),
    name: card.name || '',
    expiration,
    user_saved: save,
    billing_address: {
      phone_number: address.phone_number || '',
      line1: address.line1 || '',
      line2: address.line2 || '',
      city: address.city || '',
      country_code: address.country_code || '',
      state: address.state || '',
      postal_code: address.postal_code || '',
    },
  };
}

export function validateImage(requirements, file, callback) {
  const errors = [];
  const URL = window.URL || window.webkitURL;
  let img = null;
  try {
    img = new Image();
    img.src = URL.createObjectURL(file);
  } catch(error) {
    errors.push('FILE_TYPE');
  }
  if(img) {
    img.onload = () => {
      const { width, height, ext, size } = requirements;
      if(width && height) {
        const minRatio = (width / height) * 0.95;
        const maxRatio = (width / height) * 1.05;
        const imageRatio = img.width / img.height;
        if(imageRatio < minRatio || imageRatio > maxRatio) {
          errors.push('IMAGE_ASPECT_RATIO');
        }
        if(img.width < width || img.height < height) {
          errors.push('IMAGE_DIMENSIONS');
        }
      }
      if(file.size > size) {
        errors.push('FILE_SIZE_BIG');
      }
      if(!ext.includes(file.name.substr(file.name.length - 3))) {
        errors.push('FILE_TYPE');
      }
      if(errors.length) {
        callback(file, errors);
      } else {
        callback(file, null);
      }
    };
  } else {
    callback(file, errors);
  }
}

/**
 * Check whether two objects are equal to each other at the top level
 */
export function isEquivalent(objectA, objectB) {
  if(objectA === objectB) {
    return true;
  }
  if(!objectA || !objectB) {
    return false;
  }
  // Get property names, ignoring Vuejs observer prop
  const aProps = Object.getOwnPropertyNames({ ...objectA });
  const bProps = Object.getOwnPropertyNames({ ...objectB });
  if(aProps.length !== bProps.length) {
    return false;
  }
  for(let i = 0; i < aProps.length; i += 1) {
    const aVal = objectA[aProps[i]];
    const bVal = objectB[aProps[i]];
    if(aVal !== bVal) {
      if(typeof aVal === 'object' && typeof bVal === 'object') {
        if(!isEquivalent(aVal, bVal)) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  return true;
}
