// User authorization
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

// User
export const USER_REQUEST = 'USER_REQUEST';
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS';
export const USER_NOTIFICATIONS_LOADING = 'USER_NOTIFICATIONS_LOADING';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_EMAIL_UPDATE = 'USER_EMAIL_UPDATE';
export const PROJECT_SUBSCRIPTION_UPDATE = 'PROJECT_SUBSCRIPTION_UPDATE';
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const TOGGLE_SAVE_ADDRESS = 'TOGGLE_SAVE_ADDRESS';
export const TOGGLE_SAVE_CREDIT_CARD = 'TOGGLE_SAVE_CREDIT_CARD';
export const REMOVE_ADDRESS = 'REMOVE_ADDRESS';
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';
export const SET_USER_SIGNUP = 'SET_USER_SIGNUP';
export const PREVIEW_MODE = 'PREVIEW_MODE';

// Ticker
export const EXCHANGE_RATE_REQUEST = 'EXCHANGE_RATE_REQUEST';
export const EXCHANGE_RATE_SUCCESS = 'EXCHANGE_RATE_SUCCESS';
export const EXCHANGE_RATE_FAILURE = 'EXCHANGE_RATE_FAILURE';

// Categories
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

// Search results
export const GET_SEARCH_RESULTS_REQUEST = 'GET_SEARCH_RESULTS_REQUEST';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';

// Referrals
export const SET_REFERRAL = 'SET_REFERRAL';
export const CLEAR_REFERRAL = 'CLEAR_REFERRAL';
export const SET_REFERRAL_SEARCH = 'SET_REFERRAL_SEARCH';
export const SET_REFERRAL_INTERNAL = 'SET_REFERRAL_INTERNAL';

// Cart Actions
export const GET_CART = 'GET_CART';
export const APPLY_COUPON = 'APPLY_COUPON';
export const CART_VARIANT_ADJUST = 'CART_VARIANT_ADJUST';
export const PLEDGE_CANCEL = 'PLEDGE_CANCEL';
export const MILESTONE_VOTE = 'MILESTONE_VOTE';

// Cart Mutations
export const ADDRESS_BILLING_SET = 'ADDRESS_BILLING_SET';
export const ADDRESS_SHIPPING_SET = 'ADDRESS_SHIPPING_SET';
export const CART_UPDATE_SHOPPING = 'CART_UPDATE_SHOPPING';
export const CART_PLEDGES_SET = 'CART_PLEDGES_SET';
export const PAYMENT_METHOD_SET = 'PAYMENT_METHOD_SET';
export const CART_RESET = 'CART_RESET';
export const CART_RESET_ALL = 'CART_RESET_ALL';

// Project Actions
export const GET_PROJECT_REWARD_ANALYTICS = 'GET_PROJECT_REWARD_ANALYTICS';

// Project Mutations
export const PROJECT_SET = 'PROJECT_SET';
export const SET_PROJECT_REWARD_ANALYTICS = 'SET_PROJECT_REWARD_ANALYTICS';
export const SET_PROJECT_ACTIVE_LANGUAGE = 'SET_PROJECT_ACTIVE_LANGUAGE';
