<template>
<div :class="`social-btn ${socialType}`" @click="$emit('click', socialType)">
  <div class="btn-text">
    {{ $t(`auth.button_text.${socialType}`) }}
  </div>
  <img class="social-icon" :src="require(`@/static/img/icons/ic_${socialType}.png`)">
</div>
</template>

<script>

export default {
  name: 'social-button',
  props: {
    socialType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.social-btn {
  @include button_large_text;
  height: 48px;
  margin-bottom: 15px;
  cursor: pointer;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .social-icon {
    width: 25px;
  }
  .btn-text {
    padding-top: 4px;
  }
  &.google {
    background-color: #4c8bf5;
  }
  &.facebook {
    background-color: #3b5998;
  }
}

</style>
