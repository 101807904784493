import Vue from 'vue';
import VueYoutube from 'vue-youtube';
import VueAnime from 'vue-animejs';
// import VueMeta from 'vue-meta';
import vSelect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vDatepicker from 'vuejs-datepicker';
import moment from 'moment';
import VueLogger from 'vuejs-logger';
import { client } from '@/utils/api';
import { isProduction, loggingEnabled } from '@/utils/config';
import VueSanitize from 'vue-sanitize';
import i18n from './i18n';
import '@/utils/filters';
import App from './App.vue';
import router from './router';
import store from './store';
import { USER_LOGOUT } from './store/actions';

moment.updateLocale('en', {
  relativeTime: {
    m: '1 minute',
    h: '1 hour',
    d: '1 day',
    M: '1 month',
    y: '1 year',
  },
});

const loggerOptions = {
  isEnabled: loggingEnabled,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

Vue.config.productionTip = false;
Vue.use(VueLogger, loggerOptions);
Vue.use(VueAnime);
Vue.use(VueYoutube);

Vue.use(VueSanitize, {
  allowedTags: ['b', 'a', 'br', 'i', 'ul', 'ol', 'li'],
  allowedAttributes: {
    a: ['href', 'target'],
  },
});
// Vue.use(VueMeta);
Vue.component('v-select', vSelect);
Vue.component('v-datepicker', vDatepicker);
Vue.config.development = process.env.NODE_ENV === 'development';

Vue.mixin({
  methods: {
    scrollToId(elementId) {
      const element = document.getElementById(elementId);
      window.scrollTo(0, element.offsetTop);
    },
  },
});

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

client.interceptors.response.use(response => response, (err) => {
  const res = err.response;
  if(!res) {
    throw new Error('NETWORK_FAILURE');
  }

  const { status, config, data } = res;
  if(config && config.skipErrorHandling) {
    throw err;
  }

  if(status >= 500) {
    throw new Error('NETWORK_FAILURE');
  } else if(status === 401) {
    store.commit(USER_LOGOUT);
    res.errors = ['RESTRICTED_AREA'];
    throw res;
  } else if(status === 403) {
    Vue.set(app.$children[0], 'showEmailConfirm', true);
    throw res;
  } else if(status === 404 || (data.errors && data.errors[0] === 'MISSING_PERMISSION')) {
    // Reactively set show404 on App.vue
    Vue.set(app.$children[0], 'show404', true);
    throw res;
  } else if(status === 408) {
    throw new Error('REQUEST_TIMEOUT');
  } else {
    throw res.data;
  }
});

// If running e2e tests...
if(process.env.VUE_APP_TEST === 'e2e' || window.Cypress) {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.login()`.
  window.pledgecampApp = app;
}
