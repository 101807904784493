<template>
<div class="builder-intro-name">
  <h1 class="intro-header">
    {{ $t('builder_intro.intro_name.title') }}
  </h1>
  <div
    class="intro-name-desc"
    v-html="$t('builder_intro.intro_name.description')"
  />
  <TitledInput
    class="project-name"
    :value="name"
    :errorMessage="error"
    :placeholder="$t('builder_intro.intro_name.placeholder')"
    @input="inputName"
    @handle-enter="continueClick"
  />
  <PButton
    :animate="isChecking"
    class="builder-continue-button"
    :disabled="!name || !!error"
    @click="continueClick"
  >
    {{ $t('builder_intro.continue') }}
    <Arrow color="white" />
  </PButton>
</div>
</template>

<script>
import Arrow from '@/components/svg/Arrow.vue';
import { getProjectExists } from '@/utils/api';
import TitledInput from '@/components/widget/TitledInput';
import PButton from '@/components/widget/PButton';

export default {
  name: 'builder-intro-name',
  components: {
    Arrow,
    TitledInput,
    PButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    categoryId: {
      type: Number,
      default: null,
    },
    introError: {
      type: String,
      default: null,
    },
    eligible: Boolean,
  },
  data() {
    return {
      error: null,
      isChecking: false,
    };
  },
  methods: {
    inputName(value) {
      this.error = null;
      this.$emit('update:name', value);
    },
    async continueClick() {
      try {
        this.isChecking = true;
        const { data: { project } } = await getProjectExists(this.name);

        // Show error if there is a project exist
        if(project) {
          this.isChecking = false;
          this.error = this.$t('errors.PROJECT_EXISTS', { name: this.name });
        }
      } catch(error) {
        if(error.status === 404) {
          // continue if the project doesn't exist
          this.$emit('next-tab');
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.builder-intro-name {
  text-align: center;
  h1 {
    margin-top: 80px;
  }
  .intro-name-desc {
    @include p_large;
    margin-top: 32px;
  }
  .project-name {
    margin: 0 auto;
    margin-top: 24px;
    max-width: 600px;
  }

  .p-button > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: $mobile-width) {
  .builder-intro {
    h1 { margin-top: 48px; }
    button { width: 100%; }
  }
}
</style>
