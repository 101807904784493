<template>
<div ref="pcCarousel" class="pc-carousel">
  <div
    ref="pcCarouselWrap"
    class="pc-carousel-slide-wrap"
    :class="{ noscroll: !enableScroll }"
    :style="{ width: `${totalWidth}px` }"
  >
    <div
      class="pc-carousel-inner"
      :style="{
        transform: `translate(${currentOffset}px, 0)`,
        'transition': dragging ? 'none' : transitionStyle,
      }"
    >
      <slot />
    </div>
  </div>
  <slot v-if="showNav" name="nav">
    <div class="pc-carousel-nav">
      <CarouselNext :disabled="activeSlide >= (slideCount - 1)" @click="nextSlide" />
      <CarouselPrev :disabled="activeSlide == 0" @click="prevSlide" />
    </div>
  </slot>
</div>
</template>

<script>
import CarouselNext from '@/components/widget/CarouselNext';
import CarouselPrev from '@/components/widget/CarouselPrev';
import { debounce } from '@/utils';

export default {
  name: 'pc-carousel',
  components: {
    CarouselNext,
    CarouselPrev,
  },
  props: {
    showNav: {
      type: Boolean,
      default: true,
    },
    slideWidth: {
      type: Number,
      required: true,
    },
    slideCount: {
      type: Number,
      required: true,
    },
    gutter: {
      type: Number,
      default: 24,
    },
    friction: {
      type: Number,
      default: 25,
    },
    refreshRate: {
      type: Number,
      default: 16,
    },
    // Slide transition speed in milliseconds
    transitionSpeed: {
      type: Number,
      default: 500,
    },
    // Transition easing type
    transitionEasing: {
      type: String,
      validator: value => (
        ['ease', 'linear', 'ease-in', 'ease-out', 'ease-in-out', 'cubic-bezier']
          .indexOf(value) !== -1
      ),
      default: 'ease',
    },
    // Minimum distance before a swipe is triggered
    minSwipeDistance: {
      type: Number,
      default: 8,
    },
    enableScroll: Boolean,
  },
  data() {
    return {
      slidesVisible: 1,
      offset: 0,
      startTime: 0,
      dragging: false,
      dragMomentum: 0,
      dragOffset: 0,
      dragStartY: 0,
      dragStartX: 0,
      isTouchScreen: (typeof window !== 'undefined') && ('ontouchstart' in window),
    };
  },
  computed: {
    totalWidth() {
      if(this.slideCount <= 0) {
        return 0;
      }
      const totalGutter = (this.slideCount - 1) * this.gutter;
      return (this.slideWidth * this.slideCount) + totalGutter;
    },
    transitionStyle() {
      const speed = `${this.transitionSpeed / 1000}s`;
      return `${speed} ${this.transitionEasing} transform`;
    },
    currentOffset() {
      return (this.offset + this.dragOffset) * -1;
    },
    activeSlide() {
      return Math.round(Math.abs(this.currentOffset / this.slideWidth));
    },
  },
  watch: {
    activeSlide(newVal) {
      this.$emit('slide-change', newVal);
    },
  },
  methods: {
    nextSlide() {
      this.goToSlide(this.activeSlide + 1);
    },
    prevSlide() {
      this.goToSlide(this.activeSlide - 1);
    },
    goToSlide(slide) {
      if(slide >= 0 && slide < this.slideCount) {
        this.offset = Math.round(slide * (this.slideWidth + this.gutter));
      }
    },
    onMouseStart(e) {
      // Ignore right click
      if(e.button === 2) {
        return;
      }
      if(this.isTouchScreen) {
        document.addEventListener('touchend', this.onMouseEnd, true);
        document.addEventListener('touchmove', this.onMouseDrag, true);
      } else {
        document.addEventListener('mouseup', this.onMouseEnd, true);
        document.addEventListener('mousemove', this.onMouseDrag, true);
      }
      e.preventDefault();
      this.startTime = e.timeStamp;
      this.dragging = true;
      this.dragStartX = this.isTouchScreen ? e.touches[0].clientX : e.clientX;
      this.dragStartY = this.isTouchScreen ? e.touches[0].clientY : e.clientY;
    },
    onMouseClick(e) {
      if(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        clearTimeout(this.onMouseClickTimeout);
      }
      this.$refs.pcCarouselWrap.removeEventListener('click', this.onMouseClick, true);
    },
    onMouseDrag(e) {
      const eventPosX = this.isTouchScreen ? e.touches[0].clientX : e.clientX;
      const eventPosY = this.isTouchScreen ? e.touches[0].clientY : e.clientY;
      const newOffsetX = this.dragStartX - eventPosX;
      const newOffsetY = this.dragStartY - eventPosY;
      // On touch devices check if we exceed the min swipe threshold
      if(this.isTouchScreen && Math.abs(newOffsetX) < Math.abs(newOffsetY)) {
        return;
      }
      e.stopImmediatePropagation();
      this.dragOffset = newOffsetX;
      const nextOffset = this.offset + newOffsetX;
      if(nextOffset < 0) {
        this.dragOffset = -Math.sqrt(-this.friction * this.dragOffset);
      } else if(nextOffset > this.totalWidth) {
        this.dragOffset = Math.sqrt(this.friction * this.dragOffset);
      }
    },
    onMouseEnd(e) {
      // Calculate momentum
      const eventPosX = this.isTouchScreen ? e.changedTouches[0].clientX : e.clientX;
      const dX = this.dragStartX - eventPosX;
      this.dragMomentum = dX / (e.timeStamp - this.startTime);

      if(this.minSwipeDistance !== 0 && Math.abs(dX) >= this.minSwipeDistance) {

        this.dragOffset += Math.sign(dX);
        this.$refs.pcCarouselWrap.addEventListener('click', this.onMouseClick, true);
        this.onMouseClickTimeout = setTimeout(this.onMouseClick, 200);
      }
      this.offset += this.dragOffset;

      this.dragOffset = 0;
      this.dragging = false;
      this.render();
      // Clear events listeners
      if(this.isTouchScreen) {
        document.removeEventListener('touchend', this.onMouseEnd, true);
        document.removeEventListener('touchmove', this.onMouseDrag, true);
      } else {
        document.removeEventListener('mouseup', this.onMouseEnd, true);
        document.removeEventListener('mousemove', this.onMouseDrag, true);
      }
    },
    onResize() {
      // Briefly force dragging to disable animation
      this.dragging = true;
      this.render();
      if(this.$refs.pcCarousel) {
        const { width } = this.$refs.pcCarousel.getBoundingClientRect();
        const visible = Math.round(width / (this.slideWidth + this.gutter));
        if(this.slidesVisible !== visible) {
          this.slidesVisible = visible;
          this.$emit('slides-visible', visible);
        }
      }
      setTimeout(() => {
        this.dragging = false;
      }, this.refreshRate);
    },
    render() {
      // Increase offset depending on drag momentum
      this.offset += Math.round(this.dragMomentum) * (this.slideWidth * 0.7);

      // Snap to a page
      this.offset = Math.max(Math.min(this.offset, this.totalWidth - this.slideWidth), 0);
      this.offset = this.slideWidth * (Math.round(this.offset) / this.slideWidth);
    },
  },
  mounted() {
    this.onResizeDebounce = debounce(this.onResize, this.refreshRate);
    window.addEventListener('resize', this.onResizeDebounce);
    this.$refs.pcCarouselWrap.addEventListener(
      this.isTouchScreen ? 'touchstart' : 'mousedown',
      this.onMouseStart,
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizeDebounce);
    this.$refs.pcCarouselWrap.removeEventListener(
      this.isTouchScreen ? 'touchstart' : 'mousedown',
      this.onMouseStart,
    );
  },
};
</script>

<style lang="scss">

.pc-carousel {
  position: relative;
}
.pc-carousel-slide-wrap {
  overflow-x: scroll;
  &.noscroll {
    overflow-x: hidden;
  }
}
.pc-carousel-inner {
  display: flex;
  justify-content: space-between;
}
.pc-carousel-nav {
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;

  .btn-prev, .btn-next {
    margin-bottom: 15px;
  }
}
@media (max-width: $desktop-width-small) {
  .pc-carousel {
    max-width: 692px;
    overflow: hidden;
  }
  .pc-carousel-nav {
    display: none;
  }
}
@media (max-width: $mobile-width) {
  .pc-carousel {
    max-width: 300px;
    .pc-carousel-inner {
      width: 110%;
    }
  }
}
</style>
