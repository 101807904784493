<template>
<div class="links-menu" :class="{ 'no-auth': !authenticated }">
  <div v-if="authenticated" class="links-menu-items">
    <a href="#" class="links-menu-item" @click="$emit('explore-click')">
      {{ $t('header.explore_projects') }}
    </a>
    <router-link
      id="create-link"
      class="links-menu-item"
      to="/create"
      @click.native="$emit('item-click')"
    >
      {{ $t('start_project') }}
    </router-link>
    <p id="account-label" class="links-menu-title">
      {{ $t('header.user_menu.account') }}
    </p>
    <router-link
      v-for="(link, index) in $t('header.user_menu.user_links')"
      :id="link.route"
      :key="index"
      class="links-menu-item"
      :to="{ name: link.route }"
      @click.native="$emit('item-click')"
    >
      {{ link.title }}
    </router-link>
    <router-link
      id="wallet-link"
      :to="{name: 'wallet' }"
      class="links-menu-item"
      @click.native="$emit('item-click')"
    >
      {{ $t('header.user_menu.wallet') }}
    </router-link>
    <a class="links-menu-item" href="#" @click="logout">
      {{ $t('logout') }}
    </a>
  </div>
  <div v-else class="links-menu-items">
    <router-link
      class="links-menu-item"
      :to="{ name: 'login' }"
      @click.native="$emit('item-click')"
    >
      {{ $t('header.login') }}
    </router-link>
    <router-link
      class="links-menu-item"
      :to="{ name: 'signup' }"
      @click.native="$emit('item-click')"
    >
      {{ $t('header.signup') }}
    </router-link>
  </div>
</div>
</template>

<script>
import { USER_LOGOUT } from '@/store/actions';

export default {
  name: 'link-menu',
  props: {
  },
  computed: {
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(USER_LOGOUT);
      this.$emit('item-click');
    },
  },
};
</script>

<style lang="scss" >
@import 'general';
.links-menu {
  padding: 40px 24px 24px;
  box-sizing: border-box;
  background: $bg-grey-light;
  &.no-auth {
    background: $white;
    padding-top: 48px;
  }
}
.links-menu-title {
  @include h5;
  color: $grey50;
  margin: 16px 0 8px 0;
}
.links-menu-items {
  display: flex;
  flex-direction: column;

  a.links-menu-item {
    @include h5;
    margin-bottom: 8px;
  }
}
#create-link, #wallet-link {
  display: none;
}

@media (max-width: $tablet-width-large) {
  #create-link, #wallet-link {
    display: block;
  }
}
</style>
