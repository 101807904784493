<template>
<transition name="modal">
  <div class="auth-wrap">
    <div class="modal-auth-inner">
      <div class="modal-auth-main">
        <div class="login modal-auth-form-wrap">
          <slot name="content" />
        </div>
        <div class="images">
          <div class="images-left">
            <img class="image-item gin" :src="require('@/static/img/login_left2.jpg')">
            <img class="image-item socks" :src="require('@/static/img/login_left1.jpg')">
            <img class="image-item singer" :src="require('@/static/img/login_left3.jpg')">
          </div>
          <div class="images-right">
            <img class="image-item bottles" :src="require('@/static/img/login_right1.jpg')">
            <img class="image-item phone" :src="require('@/static/img/login_right2.jpg')">
            <img class="image-item gadget" :src="require('@/static/img/login_right3.jpg')">
          </div>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>

export default {
  name: 'auth-layout',
};

</script>

