<template>
<div v-if="authenticated" class="user-menu-wrap" :class="{ sidebar }">
  <div class="user-projects">
    <div class="created">
      <span class="label">{{ $t('header.user_menu.created_projects') }}</span>
      <ul v-if="!!user.latest_created_projects.length" class="created-project-list">
        <li
          v-for="project in createdProjects"
          :key="`created_${project.id}`"
          class="created-project-item"
        >
          <div
            class="p-image"
            :style="{ 'background-image': mainImage(project) }"
          />
          <div class="p-info">
            <div>
              {{ project.name }}
            </div>
            <router-link
              :to="{name: 'dashboard', params: { id: project.id, tab: 'campaign' }}"
              @click.native="$emit('item-click')"
            >
              {{ $t('header.user_menu.project_dashboard') }}
            </router-link>
            <router-link
              v-if="showManagerLink(project)"
              :to="{name: 'manager', params: { id: project.id }}"
              @click.native="$emit('item-click')"
            >
              {{ $t('header.user_menu.project_manager') }}
            </router-link>
          </div>
        </li>
      </ul>
      <div v-else>
        {{ $t('header.user_menu.no_created_projects') }}
      </div>
    </div>
    <hr>
    <div class="backed">
      <span class="label">
        {{ $tc('header.user_menu.backed_projects', user.backed_projects_count || 0) }}
      </span>
      <div v-if="!user.latest_backed_projects">
        {{ $t('header.user_menu.no_backed_projects') }}
      </div>
      <ul v-else class="backed-project-list">
        <li
          v-for="(project, idx) in user.latest_backed_projects"
          :key="`backed_${idx}`"
          class="backed-project-item"
        >
          <router-link
            class="view-project"
            :to="{name: 'project', params: { id: project.vanity_url || project.id }}"
            target="_blank"
            @click.native="$emit('item-click')"
          >
            {{ project.name }}
          </router-link>
        </li>
        <li class="backed-project-item view-all-link">
          <router-link :to="{ name: 'user', hash: '#backed' }" @click.native="$emit('item-click')">
            {{ $t('view_all') }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="user-account">
    <LinkMenu @item-click="$emit('item-click')" />
    <!-- TODO: uncomment when the PLG wallet is ready
    <router-link :to="{name: 'wallet' }" @click.native="$emit('item-click')">
      <div class="wallet">
        <span class="w-label">{{ $t('header.user_menu.wallet') }}</span>
        <span class="w-value">{{ $t('header.user_menu.wallet_placeholder') }}</span>
      </div>
    </router-link>
    -->
  </div>
</div>
</template>

<script>
import LinkMenu from '@/components/nav/LinkMenu.vue';
import { ProjectStatus } from '@/utils/constants';

export default {
  name: 'user-menu',
  components: {
    LinkMenu,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    createdProjects() {
      const { user: { latest_created_projects: created }, sidebar } = this;
      return sidebar && created ? [created[0]] : created;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    showManagerLink(project) {
      return (project.status === ProjectStatus.ACTIVE) || (project.status === ProjectStatus.COMPLETE);
    },
    mainImage(project) {
      const image = project.main_image;
      return (image && image.url) ? `url(${image.url}` : null;
    },
  },
};
</script>

<style lang="scss">
@import 'general';
.user-menu-wrap {
  display: flex;
  overflow: hidden;
  border-radius: 1px;
  background-color: $white;
  box-shadow: 0 16px 56px rgba(0, 0, 0, 0.15);
  font-family: $font-title;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: $main-black;
  cursor: initial;

  .label {
    margin-bottom: 8px;
    display: inline-block;
    color: rgba(0, 0, 0, 0.5);
  }

  .user-projects {
    padding: 24px 32px 24px 32px;
    min-width: 355px;
    display: block;

    hr {
      margin: 16px 0;
    }

    .created, .backed {
      min-height: 100px;
    }

    .created-project-item {
      display: flex;
      padding-top: 12px;

      .p-image {
        width: 48px;
        height: 64px;
        object-fit: cover;
        margin: 2px 16px 0 0;
        background-color: $blue-med;
        background-position: 0 0;
        background-size: cover;
        background-repeat: no-repeat;
      }
      a {
        color: $main-blue;
        display: block;
      }
    }

    .backed-project-list {
      .backed-project-item {
        a {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;
          margin: 0 0 8px 0;

          &::after {
            display: block;
            content: "";
            background-image: url('~@/static/img/icons/ic_right.png');
            width: 6px;
            height: 9px;
            background-size: 6px;
            opacity: 0.5;
            position: absolute;
            top: 6px;
            right: 0;
          }
        }
        &.view-all-link {
          margin-top: 15px;

          a {
            color: $main-blue;
            display: block;
            &::after {
              background-image: url('~@/static/img/icons/ic_right_blue.png');
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.sidebar {
    box-shadow: none;
    .user-projects {
      padding: 24px;
      box-sizing: border-box;
      min-width: 300px;
    }
  }

  .user-account {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $bg-grey-light;
    padding: 24px 20px 24px;
    min-width: 230px;

    .wallet {
      display: flex;
      margin-top: 30px;
      margin-left: -10px;
      margin-right: -10px;
      width: 100%;
      padding: 16px 15px 11px 15px;
      background: $white;
      border: 2px solid $border-light;
      justify-content: space-between;
      min-width: 184px;

      .w-label {
        color: $main-blue;
      }

      .w-value {
        color: $grey;
      }
    }
  }
}
</style>
