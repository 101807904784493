
import i18n from '@/i18n';

export function errorKey(apiError) {
  const key = `errors.${apiError}`;
  if(apiError && i18n.te(key)) {
    return key;
  }
  return 'errors.default';
}

export function firstErrorKey(errors) {
  return errors ? errorKey(errors[0]) : 'errors.default';
}

export function errorKeys(errors) {
  return (errors || []).map(e => errorKey(e));
}

/** Given an API error object and a field, get the first error key
  * Falls back on defaults as necessary
  * {
  *   'name': [INVALID_VALUE]
  * }
  * => 'errors.INVALID_VALUE.name'
  */
export function fieldErrorHelper(errors, fieldKey) {
  if(errors[fieldKey]) {
    const errKey = `errors.${errors[fieldKey][0]}.${fieldKey}`;
    if(errKey && i18n.te(errKey)) {
      return errKey;
    }
    const defaultKey = `errors.${errors[fieldKey][0]}.default`;
    if(i18n.te(defaultKey)) {
      return defaultKey;
    }
  }
  return 'errors.default';
}

export function fieldErrors(errors) {
  const errorMessages = {};
  Object.keys(errors).forEach((key) => {
    errorMessages[key] = i18n.t(fieldErrorHelper(errors, key));
  });
  return errorMessages;
}

export function fieldErrorKey(errors) {
  const fields = Object.keys(errors || {});
  return fieldErrorHelper(errors, fields[0]);
}
