<template>
<div class="project-item" :class="[viewClass]">
  <a
    :href="link"
    class="project-link"
  />
  <div
    class="p-image"
    :style="backgroundImage"
  >
    <PercentageTag
      v-if="showBackerInsurance"
      :message="tagMessage"
      :small="viewSmall"
    />
  </div>
  <div class="p-info">
    <div v-if="project.emptyProject" class="start-project">
      {{ $t('start_project') }}
    </div>
    <div v-else class="subheading p-status">
      <div class="fund">
        <img v-if="!viewSmall" :src="require('@/static/img/icons/ic_raised.svg')">
        <span>{{ $tc('project.raised', raised) }}</span>
      </div>
      <div v-if="daysLeft >= 0" class="time">
        <img v-if="!viewSmall" :src="require('@/static/img/icons/ic_time.svg')">
        <span>{{ $tc('project.days_left', daysLeft) }}</span>
      </div>
    </div>
    <div class="p-title">
      {{ projectName }}
    </div>
    <div v-if="!viewSmall" class="p-desc">
      {{ projectBlurb }}
    </div>
    <div class="p-progress">
      <ProgressBar :percent="fundingPercent" />
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import PercentageTag from '@/components/widget/PercentageTag.vue';
import ProgressBar from '@/components/widget/ProgressBar.vue';
import { isCrowdfundingProject } from '@/utils/objectUtils';
import { SET_REFERRAL_INTERNAL } from '@/store/actions';
import imagePlaceholder from '@/static/img/project-placeholder.jpg';

export default {
  name: 'project-item',
  components: {
    ProgressBar,
    PercentageTag,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    viewSmall: {
      type: Boolean,
      default: false,
    },
    searchPage: {
      type: Boolean,
      default: false,
    },
    funded: {
      type: Boolean,
      default: false,
    },
    toDashboard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      const location = this.toDashboard
        ? { name: 'dashboard', params: { id: this.project.id } }
        : { name: 'project', params: { id: this.project.vanity_url } };
      return this.$router.resolve(location).href;
    },
    viewClass() {
      if(this.viewSmall) {
        return 'small';
      }
      if(this.searchPage) {
        return 'search-page';
      }
      return 'normal';
    },
    projectName() {
      if(this.project.emptyProject) {
        return this.$t('project.your_project');
      }
      return this.project.name;
    },
    projectBlurb() {
      if(this.project.emptyProject) {
        return this.$t('project.your_description');
      }
      return this.project.blurb || this.$t('no_description');
    },
    fundingPercent() {
      const goal = this.project.funding_goal;
      return goal ? Math.ceil(100 * (this.project.total_pledged / goal)) : 0;
    },
    daysLeft() {
      return this.project.end_time ? this.project.end_time.diff(moment(), 'days') : '0';
    },
    raised() {
      return this.$options.filters.from_cents(this.project.total_pledged);
    },
    showBackerInsurance() {
      return isCrowdfundingProject(this.project);
    },
    tagMessage() {
      return `${this.project.reserve_percent || 0}%`;
    },
    backgroundImage() {
      if(this.project.main_image && this.project.main_image.url) {
        return { 'background-image': `url('${this.project.main_image.url}')` };
      }
      if(this.project.emptyProject) {
        return { 'background-image': `url('${imagePlaceholder}')` };
      }
      return null;
    },
  },
  methods: {
    projectClick() {
      if(this.project.emptyProject) {
        this.$router.replace({ path: '/create' });
      } else {
        this.$store.dispatch(SET_REFERRAL_INTERNAL, this.$route.name);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

a.project-link {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.project-item {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 297px;
  position: relative;
  font-family: $font-title;

  .p-status {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 16px;

    > div {
      margin-right: 15px;
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
      }
      img {
        margin-bottom: 4px;
      }
    }

    .fund {
      color: $main-blue;
    }
  }

  .p-image {
    height: 396px;
    width: 100%;
    background-color: $blue-med;
    background-size: cover;
    background-position: center;
    position: relative;

    .percentage-tag:not(.small) {
      top: 16px;
      right: 16px;
    }
  }

  .p-info {
    color: $main-black;
    padding: 8px 0 0 0;
    display: flex;
    flex-direction: column;
    .start-project {
      @include button($main-blue, small, secondary);
      margin: 8px 0 16px;
    }
  }

  .p-desc {
    @include p_small;
    color: rgba(0, 0, 0, 0.8);
    height: 80px;
    overflow: hidden;
  }

  .p-progress {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  &.normal {
    flex-direction: column;

    .p-title {
      @include h3;
      margin-bottom: 8px;
      overflow: hidden;
    }
  }

  &.search-page {
    flex-direction: column;
    max-width: none;

    .p-image {
      height: 28vw;
      max-height: 60%;
    }
  }

  &.small {
    flex-direction: row;
    min-height: 134px;

    .p-status {
      flex-direction: column;
      align-items: flex-start;

      span { margin: 0; }
    }

    .p-image {
      width: 83px;
      height: 111px;
      margin-right: 25px;
      margin-bottom: 23px;
    }

    .p-info {
      width: calc(100% - 110px);
      padding: 0;
      height: 110px;
    }

    .p-title {
      @include h4;
      margin-top: 5px;
      margin-bottom: 10px;
      height: 50px;
    }
  }

  @media (max-width: $tablet-width) {
    &.search-page .p-image {
      height: 50vw;
    }
  }

  @media (max-width: $mobile-width) {
    max-width: $mobile-width;
    &.normal {
      box-sizing: border-box;
      padding-right: 16px;
    }
    &.search-page .p-title {
      height: unset;
    }
  }
}
</style>
