<template>
<div id="app" :class="backgroundClass">
  <PreviewHeader v-if="previewMode" />
  <Header
    v-if="!isDashboardPage || show404"
    :dashboard="isDashboard"
    @show-builder="$router.replace({ path: '/create' })"
  />
  <NotFound v-if="show404" />
  <router-view v-else :key="routeKey" />
  <Footer
    v-if="!isDashboardPage || show404"
  />
  <BuilderIntro
    v-if="showBuilder"
    :creator="true"
    @close-modal="hideBuilder"
    @builder-complete="builderComplete"
  />
  <ConfirmStatusModal
    v-if="showConfirmStatus"
    :status="confirmStatus"
    @close-modal="hideConfirmEmailStatus"
  />
  <ConfirmEmailModal
    v-if="showEmailConfirm"
    @close-modal="showEmailConfirm = false"
  />
</div>
</template>

<script>
import PreviewHeader from '@/components/nav/PreviewHeader';
import Header from '@/components/nav/Header';
import Footer from '@/components/nav/Footer';
import NotFound from '@/components/nav/NotFound';
import BuilderIntro from '@/components/builder/BuilderIntro';
import ConfirmEmailModal from '@/components/auth/ConfirmEmailModal';
import ConfirmStatusModal from '@/components/auth/ConfirmStatusModal';
import * as api from '@/utils/api';
import {
  GET_CATEGORIES_REQUEST,
  USER_NOTIFICATIONS,
  USER_REQUEST,
  SET_REFERRAL,
  SET_LOGIN_REDIRECT,
  SET_USER_SIGNUP,
} from '@/store/actions';

import { EmailConfirmStatus } from '@/utils/constants';

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    BuilderIntro,
    ConfirmEmailModal,
    ConfirmStatusModal,
    PreviewHeader,
    NotFound,
  },
  data() {
    return {
      showBuilder: false,
      backgroundClass: null,
      showConfirmStatus: false,
      confirmStatus: null,
      show404: false,
      showEmailConfirm: false,
    };
  },
  computed: {
    previewMode() {
      return this.$store.state.user.previewMode;
    },
    isDashboard() {
      return this.$route.name === 'dashboard';
    },
    isDashboardPage() {
      if(this.$route.name) {
        return this.$route.name.startsWith('dashboard-');
      }
      return false;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
    routeKey() {
      if(['project', 'project-update'].includes(this.$route.name)) {
        return 'project';
      }
      if(['project-preview'].includes(this.$route.name)) {
        return this.$route.name;
      }
      if(['dashboard', 'manager'].includes(this.$route.name)) {
        return `${this.$route.name}/${this.$route.params.id}`;
      }
      return this.$route.fullPath;
    },
  },
  watch: {
    $route(to, _) {
      const { ref } = to.query;
      this.show404 = false;
      this.backgroundClass = to.meta.backgroundClass;
      if(ref && (ref.length > 0)) {
        this.$store.dispatch(SET_REFERRAL, { referral_link: ref });
      }
      this.checkAuth(to);
    },
    authenticated() {
      this.checkAuth(this.$route);
    },
  },
  methods: {
    hideBuilder() {
      this.showBuilder = false;
      this.$router.replace({ path: '/' });
    },
    builderComplete(projectId) {
      this.showBuilder = false;
      this.$router.push({ name: 'dashboard', params: { id: projectId } });
    },
    checkAuth(routeObj = this.$route) {
      const { meta, matched, path, query } = routeObj;
      const createProject = path.startsWith('/create');
      const parentRequiresAuth = matched.some(record => !!record.meta.requiresAuth);
      const requiresAuth = createProject || (meta && meta.requiresAuth) || parentRequiresAuth;

      // Check for email confirmation
      if(path === '/confirmemail') {
        if(query && query.code) {
          this.verifyEmailConfirmCode(query.code);
        } else {
          this.$router.replace({ path: '/' });
        }
      } else if(this.$store.getters.userSignup) {
        this.$store.dispatch(SET_USER_SIGNUP, false);
        this.confirmStatus = EmailConfirmStatus.SUCCESS_NO_EMAIL;
        this.showConfirmStatus = true;
      }

      if(requiresAuth && !this.authenticated) {
        this.redirectToLogin();
      }

      if(createProject) {
        this.showBuilder = this.authenticated;
      }
      if(this.authenticated && !path.startsWith('/notifications')) {
        this.$store.dispatch(USER_NOTIFICATIONS, true);
      }
    },
    redirectToLogin() {
      this.$store.dispatch(SET_LOGIN_REDIRECT, this.$route.fullPath);
      this.$router.replace({ name: 'login' });
    },
    hideConfirmEmailStatus({ success, status }) {
      this.showConfirmStatus = false;
      if(status !== EmailConfirmStatus.SUCCESS_NO_EMAIL) {
        this.$router.replace({ path: '/' });
        if(success) {
          this.$store.dispatch(USER_REQUEST, true);
        }
      }
    },
    async verifyEmailConfirmCode(code) {
      try {
        await api.confirmEmail(code);
        this.confirmStatus = EmailConfirmStatus.SUCCESS;
        this.showConfirmStatus = true;
      } catch(error) {
        this.confirmStatus = EmailConfirmStatus[error.errors[0] || 'NOT_FOUND'];
      }
      this.showConfirmStatus = true;
    },
  },
  mounted() {
    const { referrer: docRef, domain } = document;
    if(docRef && (docRef.length > 0) && !docRef.includes(domain)) {
      this.$store.dispatch(SET_REFERRAL, { external_referral: docRef });
    }
    this.backgroundClass = this.$route.meta.backgroundClass;

    /* eslint-disable */
    (function(w,d,s,l,i) {
      w[l]=w[l]||[];w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),
      dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5BJW5CC');
    /* eslint-disable */
  },
  async created() {
    this.$store.dispatch(GET_CATEGORIES_REQUEST);
    // Ensure the user is authorized
    await this.$store.dispatch(USER_REQUEST, true);
    // block user from entering the page if not authorized
    this.checkAuth();
  },
};
</script>

<style lang="scss">
@import 'general';

html,body {
  padding: 0;
  margin: 0;
  width: 100%;
  background: $white;

  color: $main-black;
  * {
    box-sizing: border-box;
  }
}
html {
  font-family: $font-normal;
}
a {
  text-decoration: none;
  color: $main-black;
}
a:hover {
  color: $main-blue;
}
ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  position: relative;
  &.gradient {
    background: linear-gradient(0deg, $white 0%, $blue-light 100%);
  }
}
#nav {
  padding: 30px;
  a {
    font-weight: 700;
    color: $main-black;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.noscroll {
  overflow: hidden;
}
.mobile-only {
  display: none;
}
button::-moz-focus-inner {
  border: 0;
}
@media only screen and (max-width: 568px) {
  .mobile-only {
    display: unset;
  }
  .no-mobile {
    display: none;
  }
}
</style>
