<template>
<form class="subscribe-section-content container section" @submit="subscribe">
  <img :src="require('@/static/img/art_icons/newsletter_small.png')">
  <h2>{{ $t('home.subscribe_title') }}</h2>
  <div class="subscribe-form-wrap">
    <input
      v-model="email"
      :placeholder="$t('home.subscribe_enter_email')"
      @keydown="result = null"
    >
    <PButton
      :text="$t('subscribe')"
      :animate="animating"
      :complete="complete"
      @click="subscribe"
    />
  </div>
  <div :class="{'email-result': true, error}">
    {{ result }}
  </div>
</form>
</template>

<script>
import PButton from '@/components/widget/PButton.vue';
import { validateEmail } from '@/utils/stringUtils.js';
import { listSubscribe } from '@/utils/api.js';
import { fieldErrorKey } from '@/utils/apiError.js';

export default {
  name: 'subscribe-section',
  components: {
    PButton,
  },
  data() {
    return {
      email: '',
      result: null,
      error: false,
      animating: false,
      complete: false,
    };
  },
  methods: {
    async subscribe(event) {
      if(event) {
        event.preventDefault();
      }
      this.error = false;
      this.animating = true;
      this.result = '';
      if(!this.email || this.email === '' || !validateEmail(this.email)) {
        this.result = this.$t('errors.INVALID_VALUE.email');
        this.error = true;
        this.animating = false;
        return;
      }
      try {
        await listSubscribe(this.email);
        this.result = this.$t('home.subscribe_success');
        this.complete = true;
      } catch(e) {
        this.result = this.$t(fieldErrorKey(e.errors));
        this.error = true;
      }
      this.animating = false;
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.home .section.subscribe-section-content {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;

  img {
    width: 66px;
    height: 66px;
  }

  h2 {
    margin: 40px auto;
    max-width: 580px;
    box-sizing: border-box;
    padding: 0 5%;
  }

  .subscribe-form-wrap {
    display: flex;
    justify-content: center;
    input {
      width: 500px;
      max-width: 100%;
      color: black;
      border: 1px solid $border-light;
      font-family: $font-title;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      height: 52px;
      outline: none;
      margin-right: 8px;

      &::placeholder {
        color: $black-lighter;
      }
    }
    .p-button {
      height: 52px;
    }
  }
  .email-result {
    height: 24px;
    color: $main-blue;
    margin-top: 16px;
    &.error {
      color: $main-red;
    }
  }
  @media (max-width: $mobile-width) {
    margin-top: 24px;
    svg {
      display: none;
    }
  }
}
</style>
