import moment from 'moment';
import * as api from '@/utils/api';
import { keepAliveInMinutes } from '@/utils/config';
import { LoginMethod } from '@/utils/constants';
import {
  PREVIEW_MODE,
  TOGGLE_SAVE_ADDRESS,
  TOGGLE_SAVE_CREDIT_CARD,
  USER_FAILURE,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REQUEST,
  USER_SUCCESS,
  USER_UPDATE,
  USER_EMAIL_UPDATE,
  PROJECT_SUBSCRIPTION_UPDATE,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_SUCCESS,
  SET_LOGIN_REDIRECT,
  SET_USER_SIGNUP,
  USER_NOTIFICATIONS,
  USER_NOTIFICATIONS_LOADING,
  CART_RESET_ALL,
} from '../actions';

const state = {
  profile: null,
  notifications: null,
  notificationsLoading: false,
  error: null,
  dataAge: null,
  saveAddressForLater: true,
  saveCreditCard: false,
  loginRedirect: null,
  userSignup: false,
  previewMode: false,
  projectSubscriptions: [],
};

const getDefault = (obj, field, defaultVal) => (
  obj ? (obj[field] || defaultVal) : defaultVal
);

const getters = {
  profile: userState => userState.profile,
  hasLocalLogin: userState => (userState.profile.login_methods.includes(LoginMethod.LOCAL) || false),
  isProfileLoaded: userState => userState.profile && !!userState.profile.email,
  notifications: userState => userState.notifications || [],
  notificationsLoading: userState => userState.notificationsLoading,
  saveAddressStatus: userState => userState.saveAddressForLater,
  saveCreditCardStatus: userState => userState.saveCreditCard,
  userError: userState => userState.error,
  isAuthenticated: userState => !!userState.profile,
  loginRedirect: userState => userState.loginRedirect || '/user/me',
  userSignup: userState => userState.userSignup,
  twoFactorEnabled: userState => getDefault(userState.profile, 'totp_enabled', false),
  projectSubscriptions: userState => getDefault(userState.profile, 'project_subscriptions', []),
};

const actions = {
  [USER_REQUEST]: async (context, forceUpdate = false) => {
    const { commit } = context;
    const isExpired = state.dataAge ? moment().diff(state.dataAge, 'minutes') > keepAliveInMinutes : true;
    const shouldCallAPI = forceUpdate || isExpired;

    if(shouldCallAPI) {
      try {
        const response = await api.getProfile();
        commit(USER_SUCCESS, response.data.user);
      } catch(error) {
        commit(USER_FAILURE, error);
      }
    }
  },
  [USER_UPDATE]: async ({ commit }, payload) => {
    try {
      const response = await api.updateProfile(payload);
      commit(USER_SUCCESS, response.data.user);
    } catch(error) {
      commit(USER_FAILURE, error);
    }
  },
  [USER_EMAIL_UPDATE]: async (context, payload) => {
    await api.updateEmail(payload);
    context.commit(USER_SUCCESS, {
      ...context.state.profile,
      email: payload.email,
    });
  },
  [PROJECT_SUBSCRIPTION_UPDATE]: async (context, { projectId, subscribe }) => {
    const subscribed = await api.subscribeProject(projectId, subscribe);

    const subscriptions = subscribed
      ? [projectId, ...context.state.projectSubscriptions]
      : context.state.projectSubscriptions.filter(sub => sub !== projectId);

    context.commit(USER_SUCCESS, {
      ...context.state.profile,
      project_subscriptions: subscriptions,
    });
  },
  [USER_NOTIFICATIONS]: async ({ commit }, showLoading = false) => {
    commit(USER_NOTIFICATIONS_LOADING, showLoading);
    try {
      const { data: { notifications } } = await api.getUserNotifications({
        page: 1,
        perPage: 4,
      });
      commit(USER_NOTIFICATIONS, notifications.slice(0, 4));
    } catch(err) {
      commit(USER_NOTIFICATIONS, null);
      console.log('err: ', err);
    }
    commit(USER_NOTIFICATIONS_LOADING, false);
  },
  [CREATE_PAYMENT_METHOD]: async ({ commit }, paymentData) => {
    const res = await api.createUserPaymentMethod(paymentData);
    commit(CREATE_PAYMENT_SUCCESS, res.data.payment_method);
  },
  [TOGGLE_SAVE_ADDRESS]: ({ commit }) => {
    commit(TOGGLE_SAVE_ADDRESS);
  },
  [TOGGLE_SAVE_CREDIT_CARD]: ({ commit }) => {
    commit(TOGGLE_SAVE_CREDIT_CARD);
  },
  [USER_LOGIN]: async ({ dispatch }, user) => {
    const response = await api.userLogin(user);
    await dispatch(USER_REQUEST, true);
    return response.data;
  },
  [USER_LOGOUT]: async ({ commit }) => {
    await api.userLogout();
    commit(USER_LOGOUT);
    commit(CART_RESET_ALL);
  },
  [SET_LOGIN_REDIRECT]: ({ commit }, url) => {
    commit(SET_LOGIN_REDIRECT, { url });
  },
  [SET_USER_SIGNUP]: ({ commit }, signup) => {
    commit(SET_LOGIN_REDIRECT, { signup });
  },
};

const mutations = {
  [PREVIEW_MODE]: (userState, previewMode) => {
    userState.previewMode = previewMode;
  },
  [USER_SUCCESS]: (userState, profile) => {
    userState.profile = profile;
    userState.error = null;
    userState.dataAge = moment();
  },
  [CREATE_PAYMENT_SUCCESS]: (userState, paymentMethod) => {
    userState.profile.payment_methods.push(paymentMethod);
  },
  [USER_FAILURE]: (userState, error) => {
    userState.error = error;
    userState.profile = null;
    userState.dataAge = moment();
  },
  [USER_LOGOUT]: (userState) => {
    userState.profile = null;
    userState.error = null;
    userState.dataAge = moment();
  },
  [USER_NOTIFICATIONS]: (userState, notifications) => {
    userState.notifications = notifications;
  },
  [USER_NOTIFICATIONS_LOADING]: (userState, loading) => {
    userState.notificationsLoading = loading;
  },
  [TOGGLE_SAVE_ADDRESS]: (userState) => {
    userState.saveAddressForLater = !userState.saveAddressForLater;
  },
  [TOGGLE_SAVE_CREDIT_CARD]: (userState) => {
    userState.saveCreditCard = !userState.saveCreditCard;
  },
  [SET_LOGIN_REDIRECT]: (userState, data) => {
    if(data.url !== undefined) {
      userState.loginRedirect = data.url;
    }
    if(data.signup !== undefined) {
      userState.userSignup = data.signup;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
