<template>
<div :class="{ header: true, dashboard }">
  <div class="container header-content">
    <Logo />
    <transition name="fade">
      <HeaderCategories
        v-if="dropdownOpened"
        class="dropdown-content"
        :toggleDropdown="toggleDropdown"
      />
    </transition>
    <UpDownChevron
      v-if="!dashboard"
      class="header-explore-dropdown"
      :down="dropdownOpened"
      :text="$t('header.explore_projects')"
      @toggle="toggleDropdown"
    />
    <div v-if="dashboard" class="header-right-first header-dashboard">
      <router-link
        :to="{ name: 'creator-handbook' }"
        event=""
        @click.native="showUnimplemented = true"
      >
        {{ $t('header.creator_handbook') }}
      </router-link>
      <router-link
        :to="{ name: 'knowledge-base' }"
        event=""
        @click.native="showUnimplemented = true"
      >
        {{ $t('header.knowledge_base') }}
      </router-link>
      <router-link
        :to="{ name: 'creator-forum' }"
        event=""
        @click.native="showUnimplemented = true"
      >
        {{ $t('header.creator_forum') }}
      </router-link>
    </div>
    <SearchBar
      v-else
      class="header-right-first"
      @show-overlay="showOverlay = $event"
    />
    <div v-if="authenticated" class="header-right">
      <div class="notification-menu menu-item" @click="notificationsMenuToggle">
        <img :src="require('@/static/img/icons/ic_notification.svg')">
        <span v-if="hasNotifications" class="badge" />
        <transition name="fade">
          <NotificationsMenu
            v-if="showNotificationsMenu"
            ref="notificationsMenu"
            class="notification-menu-dropdown-content"
            :user="user"
            @item-click="showNotificationsMenu = false"
          />
        </transition>
      </div>
      <div class="user-menu menu-item" @click="userMenuClick">
        <Spinner v-if="!$store.getters.isProfileLoaded && $store.getters.profileStatus === 'loading'" :size="40" />
        <div
          v-else
          class="user-avatar"
          :style="{ 'background-image': `url(${$options.filters.avatar(user)})` }"
          @click="userMenuToggle"
        />
        <transition name="fade">
          <UserMenu
            v-if="showUserMenu"
            ref="userMenu"
            class="user-menu-dropdown-content"
            :user="user"
            @item-click="showUserMenu = false"
          />
        </transition>
      </div>
      <a v-if="!dashboard" class="start-project menu-item" @click="$emit('show-builder')">
        {{ $t('start_project') }}
      </a>
    </div>
    <div
      v-if="!hideAuthMenu"
      class="sidebar-toggle menu-item"
      @click="sidebarOpened = true"
    >
      <em /><em /><em />
    </div>
    <div v-if="!authenticated && !hideAuthMenu" id="auth-menu">
      <div class="auth-click" @click="goto('signup')">
        {{ $t('header.signup') }}
      </div>
      <hr class="vertical">
      <div class="auth-click" @click="goto('login')">
        {{ $t('header.login') }}
      </div>
    </div>
    <div
      class="header-sidebar-wrap"
      :class="{ opened: sidebarOpened }"
      @click="sidebarOpened = false"
    >
      <div class="header-sidebar" @click.stop>
        <Cross
          class="sidebar-close sidebar-toggle"
          :clickable="true"
          color="black"
          @click="sidebarOpened = false"
        />
        <LinkMenu
          @item-click="sidebarOpened = false"
          @explore-click="sidebarOpened = false; toggleDropdown();"
        />
        <UserMenu
          ref="userMenu"
          class="user-menu-dropdown-content"
          :user="user"
          :sidebar="true"
        />
      </div>
    </div>
  </div>
  <UnimplementedModal
    v-if="showUnimplemented"
    @close-modal="showUnimplemented = false"
  />
  <div v-show="showOverlay" class="overlay" />
</div>
</template>

<script>
import Logo from '@/components/nav/Logo.vue';
import SearchBar from '@/components/nav/SearchBar.vue';
import UserMenu from '@/components/nav/UserMenu.vue';
import LinkMenu from '@/components/nav/LinkMenu.vue';
import NotificationsMenu from '@/components/nav/NotificationsMenu.vue';
import HeaderCategories from '@/components/nav/HeaderCategories.vue';
import UnimplementedModal from '@/components/widget/UnimplementedModal.vue';
import UpDownChevron from '@/components/widget/UpDownChevron.vue';
import Cross from '@/components/svg/Cross';
import Spinner from '@/components/widget/Spinner';
import { SET_LOGIN_REDIRECT } from '@/store/actions';

export default {
  name: 'pledgecamp-header',
  components: {
    Cross,
    HeaderCategories,
    LinkMenu,
    Logo,
    NotificationsMenu,
    SearchBar,
    Spinner,
    UnimplementedModal,
    UpDownChevron,
    UserMenu,
  },
  props: {
    dashboard: Boolean,
  },
  data() {
    return {
      dropdownOpened: false,
      showUserMenu: false,
      showNotificationsMenu: false,
      sidebarOpened: false,
      showUnimplemented: false,
      isSignup: false,
      showOverlay: false,
    };
  },
  computed: {
    hideAuthMenu() {
      return (this.$route.name === 'login' || this.$route.name === 'signup');
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters.profile || {};
    },
    hasNotifications() {
      return this.$store.getters.notifications.length;
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpened = !this.dropdownOpened;
      this.showOverlay = this.dropdownOpened;

      if(this.dropdownOpened) {
        document.addEventListener('keyup', this.escapeListener);
      } else {
        document.removeEventListener('keyup', this.escapeListener);
      }
    },
    escapeListener(event) {
      if(event.keyCode === 27) {
        this.toggleDropdown();
      }
    },
    userMenuClick(e) {
      e.stopPropagation();
    },
    userMenuToggle(e) {
      e.stopPropagation();
      this.showNotificationsMenu = false;
      this.showUserMenu = !this.showUserMenu;
    },
    notificationsMenuToggle(e) {
      e.stopPropagation();
      this.showUserMenu = false;
      this.showNotificationsMenu = !this.showNotificationsMenu;
    },
    documentClick(e) {
      if(e.target !== this.$refs.userMenu) {
        this.showUserMenu = false;
      }
      if(e.target !== this.$refs.notificationsMenu) {
        this.showNotificationsMenu = false;
      }
    },
    goto(page) {
      this.$store.dispatch(SET_LOGIN_REDIRECT, this.$route.fullPath);
      this.$router.push({ name: page });
    },
  },
  async created() {
    document.addEventListener('click', this.documentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.documentClick);
  },
};
</script>

<style lang="scss">
@import 'widgets';
@import 'general';

.header {
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  position: relative;

  &.dashboard {
    background-color: $blue-light;
  }

  .header-content {
    display: flex;
    height: 90px;
    overflow: visible;

    > * {
      align-self: center;
      &:not(:first-child) {
        margin-left: 32px;
      }
    }

    .header-explore-dropdown {
      z-index: 100;
      margin-top: 6px;
      margin-right: 20px;
    }

    .dropdown-content {
      position: absolute;
      width: 100%;
      z-index: 100;
      top: 0;
      left: 0;
      padding-top: 80px;
      margin-left: 0;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 16px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .header-right-first {
      margin-left: auto;
    }
    .header-dashboard > a {
      @include h5;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      margin-left: 24px;

      .menu-item {
        margin-left: 30px;
        cursor: pointer;
      }

      .notification-menu {
        position: relative;

        .badge {
          position: absolute;
          width: 5px;
          height: 5px;
          background-color: red;
          border-radius: 50%;
          top: -5px;
          right: 0;
        }

        .notification-menu-dropdown-content {
          position: absolute;
          right: 0;
          margin-top: 20px;
        }
      }
      .user-menu {
        position: relative;

        .user-avatar {
          width: 40px;
          height: 40px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
        }

        .user-menu-dropdown-content {
          position: absolute;
          right: 0;
          margin-top: 20px;
        }
      }

      .start-project {
        @include button($main-blue, small, secondary);

        @media (max-width: $tablet-width) {
          display: none;
        }
      }
    }
    #auth-menu {
      display: flex;

      .vertical {
        display: inline-block;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-left: 1px solid $grey-light;
        margin: 0 20px;
      }
    }
    .header-sidebar-wrap {
      width: 100%;
      height: 100%;
      position: fixed;
      transition: $speed3 ease;
      display: none;
      z-index: 1010;
      right: -100%;
      top: 0;
      margin: 0;

      &.opened {
        transform: translateX(-100%);
      }
    }
    .header-sidebar {
      position: absolute;
      right: 0;
      width: 300px;
      height: 100%;
      background: $white;
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
    }

    .sidebar-toggle {
      cursor: pointer;
      display: none;

      em {
        display: block;
        width: 19px;
        height: 2px;
        background: $main-black;
        margin-top: 5px;
        transition: all $speed3;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .sidebar-close {
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 99;
    }
  }
}

.slide-enter-active, .slide-leave-active {
  transition: 1s;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(100%);
}
.auth-click {
  cursor: pointer;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 120%;
  top: -90px;
  left: 0;
  background-color: #fff;
  opacity: 0.9;
  z-index: 10;
}

@media (max-width: $tablet-width-large) {
  .menu-item {
    margin-left: 20px;
  }

  .notification-menu img {
    width: 18px;
    height: 18px;
  }

  .user-menu .user-menu-dropdown-content {
    right: 0;
  }

  .header-explore-dropdown {
    flex-grow: 1;
    text-align: center;
  }

  .overlay { top: 0; }
}

@media (max-width: $tablet-width) {
  .header .header-content {
    justify-content: space-between;

    .header-left-icon {
      display: unset;
    }
    .header-main {
      display: none;
    }
    .header-sidebar-wrap {
      display: block;
    }
    .user-menu {
      display: none;
    }
    .header-explore-dropdown {
      display: none;
    }
    .sidebar-toggle {
      display: block;
    }
    #auth-menu {
      display: none;
    }
    .header-right {
      margin-left: 0;

      > div {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .header .header-content .dropdown-content {
    background-color: transparent;
  }
}
</style>
