<template>
<div class="builder-intro-eligibility">
  <h1 class="intro-header">
    {{ $t('builder_intro.intro_eligibility.title') }}
  </h1>
  <div class="intro-eligibility-desc">
    {{ $t('builder_intro.intro_eligibility.desc') }}
  </div>
  <div class="intro-eligibility-checks">
    <Checkbox
      v-for="(item, index) in checks"
      :key="index"
      :item="item"
      :onCheck="checked => onCheck(index, checked)"
    />
  </div>
  <div v-if="introError" class="type-error">
    {{ $t(introError, { name }) }}
  </div>
  <PButton
    class="builder-continue-button"
    :disabled="!eligible"
    :animate="animating"
    @click="continueClick"
  >
    {{ $t('builder_intro.intro_eligibility.button_text') }}
    <Arrow color="white" />
  </PButton>
</div>
</template>

<script>
import Checkbox from '@/components/widget/Checkbox.vue';
import Arrow from '@/components/svg/Arrow.vue';
import PButton from '@/components/widget/PButton';

export default {
  name: 'builder-intro-eligibility',
  components: {
    Arrow,
    Checkbox,
    PButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    categoryId: {
      type: Number,
      default: null,
    },
    introError: {
      type: String,
      default: null,
    },
    eligible: Boolean,
  },
  data() {
    return {
      checks: this.$t('builder_intro.intro_eligibility.checks'),
      animating: false,
    };
  },
  watch: {
    introError() {
      this.animating = false;
    },
  },
  methods: {
    continueClick() {
      if(this.eligible) {
        this.animating = true;
        this.$emit('complete');
      }
    },
    onCheck(index, checked) {
      this.$set(this.checks[index], 'checked', checked);
      this.emitEligible();
    },
    emitEligible() {
      const eligible = this.checks.every(item => item.checked);

      if(eligible !== this.eligible) {
        this.$emit('update:eligible', eligible);
      }
    },
  },
  mounted() {
    this.emitEligible();
  },
};
</script>

<style lang="scss">
@import 'general';

.builder-intro-eligibility {
  text-align: center;
  h1 {
    margin-top: 80px;
  }
  .intro-eligibility-desc {
    @include p_large;
    margin-top: 24px;
  }
  .intro-eligibility-checks {
    width: 645px;
    margin: 40px auto 24px;

    .p-checkbox {
      width: 100%;
      border: 1px solid $border-light;
      margin-top: 16px;
      padding: 16px;

      .checkmark { margin-right: 16px; }
      .checkbox-text {
        display: inline-block;
        width: 100%;
        text-align: left;
      }
    }
  }
  .builder-continue-button {
    width: 220px;
    margin: 8px 0 56px;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      position: unset;
    }
  }
}
</style>
