<template>
<Modal
  :submitText="$t(`auth.email_confirm.${status}.button`)"
  class="confirm-status-modal"
  @submit="emailConfirmedContinue"
  @cancel="$emit('close-modal', { success: false, status })"
>
  <template #image>
    <div class="status-image">
      <!-- TODO: change image according to status -->
      <img :src="require('@/static/img/art_icons/congrats_large.png')">
    </div>
  </template>
  <template #content>
    <div class="status-message">
      <h1>{{ $t(`auth.email_confirm.${status}.title`) }}</h1>
      <p class="p_large">
        {{ $t(`auth.email_confirm.${status}.message`) }}
      </p>
      <TitledInput
        v-if="!success"
        v-model="email"
        name="email"
        class="form-input"
        type="email"
        :placeholder="$t('auth.placeholders.email')"
        :errorMessage="errorEmail"
        @input="errorEmail = null"
      />
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import { EmailConfirmStatus } from '@/utils/constants';
import { validateEmail } from '@/utils/stringUtils';
import { resendConfirmation } from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';

export default {
  name: 'confirm-status-modal',
  components: {
    Modal,
    TitledInput,
  },
  props: {
    status: {
      type: String,
      validator(value) {
        return Object.values(EmailConfirmStatus).indexOf(value) >= 0;
      },
      default: EmailConfirmStatus.SUCCESS,
    },
  },
  data() {
    return {
      statusTypes: EmailConfirmStatus,
      showEmailInput: false,
      email: null,
      errorEmail: null,
    };
  },
  computed: {
    success() {
      const { SUCCESS, SUCCESS_NO_EMAIL } = this.statusTypes;
      return (this.status === SUCCESS) || (this.status === SUCCESS_NO_EMAIL);
    },
  },
  methods: {
    async emailConfirmedContinue() {
      const { NOT_FOUND, EXPIRED, INVALID_CODE } = this.statusTypes;
      if(this.status === NOT_FOUND || this.status === EXPIRED || this.status === INVALID_CODE) {
        if(!this.email || this.email === '') {
          this.errorEmail = this.$t('errors.EMAIL_MISSING');
        } else if(!validateEmail(this.email)) {
          this.errorEmail = this.$t('errors.INVALID_VALUE.email');
        } else {
          try {
            await resendConfirmation(this.email);
            this.email = null;
          } catch(error) {
            this.errorEmail = this.$t(firstErrorKey(error.errors));
          }
        }
      }
      if(!this.errorEmail) {
        this.$emit('close-modal', { success: true, status: this.status });
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.confirm-status-modal {
  text-align: center;
  .submit-button {
    @include button($main-blue, large);
    max-width: 412px;
  }
  .status-image {
    margin-bottom: 24px;
    img {
      width: 190px;
    }
  }
}
</style>
