<template>
<form id="personal-info-form" class="form-wrap" @submit="onSubmit">
  <TitledInput
    :value="value.firstName"
    :title="$t('form.kyc.labels.firstName')"
    :placeholder="$t('form.kyc.placeholders.firstName')"
    :errorMessage="errors.firstName"
    @input="handleFormInput('firstName', $event)"
    @focusout="validateField('firstName')"
  />
  <TitledInput
    :value="value.lastName"
    :title="$t('form.kyc.labels.lastName')"
    :placeholder="$t('form.kyc.placeholders.lastName')"
    :errorMessage="errors.lastName"
    @input="handleFormInput('lastName', $event)"
    @focusout="validateField('lastName')"
  />
  <button class="submit-btn" :disabled="!isValid">
    {{ $t('settings.verification.kyc_modal.buttons.continue') }}
  </button>
</form>
</template>

<script>
import TitledInput from '@/components/widget/TitledInput.vue';
import formMixin from '@/mixins/Form';

export default {
  name: 'address-form',
  components: {
    TitledInput,
  },
  mixins: [formMixin],
  data: () => ({
    rules: {
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
    },
  }),
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.$emit('submit');
    },
  },
};
</script>
