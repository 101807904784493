<template>
<form class="login-form login-modal-form" @submit="handleSubmit">
  <div class="login-form-title">
    {{ $t('auth.login') }}
  </div>
  <div class="login-subtitle">
    {{ $t('auth.dont_have_account') }}
    <span class="signup-toggle" @click="gotoSignup">{{ $t('auth.create_one') }}</span>
  </div>
  <SocialButton socialType="facebook" @click="handleSocial" />
  <SocialButton socialType="google" @click="handleSocial" />
  <div v-if="socialError" class="error-message">
    {{ socialError }}
  </div>
  <div class="signup-or-wrap">
    <div class="signup-or-border" />
    <div class="signup-or">
      {{ $t('auth.or') }}
    </div>
    <div class="signup-or-border" />
  </div>
  <TitledInput
    v-model="email"
    name="email"
    class="form-input"
    type="email"
    :placeholder="$t('auth.placeholders.email')"
    :errorMessage="errorEmail"
    @input="clearErrors"
  />
  <TitledInput
    v-model="password"
    name="password"
    class="form-input"
    type="password"
    :placeholder="$t('auth.placeholders.password')"
    :errorMessage="errorPassword"
    @input="clearErrors"
  />
  <div v-if="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
  <button
    type="submit"
    class="login-modal-button"
    :disabled="disabledLoginButton"
  >
    <div>{{ $t('login') }}</div>
    <Arrow color="white" />
  </button>
  <div class="forgot-password-wrap">
    <div class="forgot-password">
      <router-link :to="{ path: '/forgot-password', query: { email } }">
        {{ $t('auth.forgot_password') }}
      </router-link>
    </div>
  </div>
</form>
</template>

<script>
import SocialButton from '@/components/widget/SocialButton.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import Arrow from '@/components/svg/Arrow.vue';
import { validateEmail } from '@/utils/stringUtils';
import { firstErrorKey } from '@/utils/apiError';

export default {
  name: 'login',
  components: {
    SocialButton,
    TitledInput,
    Arrow,
  },
  props: {
    loginUser: {
      type: Function,
      default: () => {},
    },
    socialLoginSignup: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: null,
      errorEmail: null,
      errorPassword: null,
      errorConfirm: null,
      socialError: null,
    };
  },
  computed: {
    disabledLoginButton() {
      return (this.errorMessage && this.errorMessage !== this.$t('errors.NETWORK_FAILURE'))
        || this.errorEmail || this.errorConfirm || this.errorPassword;
    },
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    gotoSignup() {
      this.email = '';
      this.password = '';
      this.clearErrors();
      this.$emit('goto-signup');
    },
    clearErrors() {
      this.errorMessage = null;
      this.errorEmail = null;
      this.errorPassword = null;
      this.errorConfirm = null;
    },
    async handleSubmit(e) {
      e.preventDefault();
      const { email, password } = this;
      this.clearErrors();
      if(!email || email === '') {
        this.errorEmail = this.$t('errors.EMAIL_MISSING');
      } else if(!validateEmail(email)) {
        this.errorEmail = this.$t('errors.INVALID_VALUE.email');
      } else if(!password) {
        this.errorPassword = this.$t('errors.PASSWORD_MISSING');
      } else {
        const credentials = {
          username: email,
          password,
        };
        try {
          await this.loginUser(credentials);
        } catch(err) {
          if(err.status === 401) {
            this.errorMessage = this.$t('errors.UNAUTHORIZED');
          } else {
            this.errorMessage = this.$t(firstErrorKey(err.errors));
          }
        }
      }
    },
    async handleSocial(type) {
      this.socialError = null;
      const success = this.socialLoginSignup(type);
      if(!success) {
        this.socialError = `${type} authorization failed.`;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.modal-auth-form-wrap .login-form {
  .forgot-password-wrap {
    @include p_info;
    display: flex;
    .forgot-password {
      margin-left: auto;
    }
  }
}
</style>
