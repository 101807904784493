<template>
<div v-if="profile" class="account">
  <Section :info="$t('settings.account.email')">
    <div v-if="showCurrentEmail" class="settings-email">
      <span v-if="profile.email">
        {{ profile.email }}
      </span>
      <div
        v-for="method in socialLoginMethods"
        :key="method"
        class="subtitle"
      >
        {{ $t('user.private.signin_method', { method }) | capitalize }}
      </div>
    </div>
    <div v-if="hasLocalLogin">
      <button
        v-if="!showEditEmail"
        class="settings-button"
        @click="showEditEmail = !showEditEmail"
      >
        {{ $t('settings.account.email.button') }}
      </button>
      <button
        v-if="!profile.email_confirmed && !showEditEmail"
        :disabled="resendDisabled"
        class="email-button"
        @click="sendConfirmationEmail"
      >
        {{ $t('settings.verification.email.button') }}
      </button>
      <EditEmail
        v-if="showEditEmail"
        @close="showEditEmail = false"
      />
    </div>
    <div v-else>
      <button
        v-if="!showConvertAccount"
        class="settings-button"
        @click="showConvertAccount = !showConvertAccount"
      >
        {{ $t('user.private.edit.buttons.use_email_password') }}
      </button>
      <ConvertToEmailPassword
        v-if="showConvertAccount"
        @close="showConvertAccount = false"
      />
    </div>
  </Section>
  <Section :info="$t('settings.account.password')">
    <button
      v-if="!showEditPassword"
      class="settings-button"
      @click="showEditPassword = !showEditPassword"
    >
      {{ $t('settings.account.password.change') }}
    </button>
    <EditPassword
      v-if="showEditPassword"
      @close="showEditPassword = false"
    />
  </Section>
  <Section v-if="hasLocalLogin" :info="$t('settings.account.two_factor')">
    <button
      class="settings-button"
      @click="showTwoFactor"
    >
      {{
        twoFactorEnabled
          ? $t('settings.account.two_factor.disable')
          : $t('settings.account.two_factor.cta')
      }}
    </button>
  </Section>
  <Section v-if="socials && socials.length" :info="$t('settings.account.social.desc')">
    <div
      v-for="(social, index) in socials"
      :key="index"
      class="settings-social-item"
    >
      <div class="settings-social-details">
        <div class="subtitle">
          {{ social.type }}
        </div>
        <h5>
          {{
            social.connected
              ? `${social.connections} ${$t('settings.account.social.friends')}`
              : $t('settings.account.social.connectivity')
          }}
        </h5>
      </div>
      <button class="settings-account-cta" :class="{ 'connected' : social.connected}">
        {{
          social.connected
            ? $t('settings.account.social.disconnect')
            : $t('settings.account.social.connect')
        }}
      </button>
    </div>
  </Section>

  <div class="settings-account-delete">
    <span @click="showConfirmDelete = true">
      {{ $t('settings.account.delete') }}
    </span>
  </div>
  <TwoFactorEnable
    v-if="showTwoFactorEnable"
    @close="showTwoFactorEnable = false"
  />
  <TwoFactorDisable
    v-if="showTwoFactorDisable"
    @close="showTwoFactorDisable = false"
  />
  <Modal
    v-if="showConfirmDelete"
    id="delete-account"
    :title="$t('settings.account.delete_confirm.title')"
    :submitable="false"
    @submit="accountDeleteConfirmed"
    @cancel="hideAccountDelete"
  >
    <template #content>
      <div class="content-paragraph">
        {{ $t('settings.account.delete_confirm.text') }}
      </div>
      <TitledInput
        v-model="deleteConfirmText"
        name="delete-confirm"
        class="form-input"
        :maxLength="10"
        @input="deleteConfirmError = null"
      />
      <TitledInput
        v-model="deleteConfirmPassword"
        name="delete-confirm-password"
        class="form-input"
        type="password"
        :placeholder="$t('auth.placeholders.password')"
        @input="deleteConfirmError = null"
      />
      <ErrorMessage :errorMessage="deleteConfirmError" />
      <div class="delete-buttons">
        <PButton
          class="delete-button"
          @click="accountDeleteConfirmed"
        >
          {{ $t('confirm') }}
        </PButton>
        <PButton
          class="delete-button"
          @click="hideAccountDelete"
        >
          {{ $t('cancel') }}
        </PButton>
      </div>
    </template>
  </Modal>
</div>
</template>
<script>
import Section from '@/components/widget/Section';
import EditEmail from '@/components/user_private/EditEmail';
import EditPassword from '@/components/user_private/EditPassword';
import ConvertToEmailPassword from '@/components/user_private/ConvertToEmailPassword';
import ErrorMessage from '@/components/widget/ErrorMessage';
import Modal from '@/components/widget/Modal';
import TitledInput from '@/components/widget/TitledInput';
import PButton from '@/components/widget/PButton';
import TwoFactorEnable from '@/components/user_private/TwoFactorEnable';
import TwoFactorDisable from '@/components/user_private/TwoFactorDisable';
import { resendConfirmation, deleteAccount } from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';
import { LoginMethod } from '@/utils/constants';

export default {
  name: 'account',
  components: {
    ConvertToEmailPassword,
    EditEmail,
    EditPassword,
    ErrorMessage,
    Modal,
    PButton,
    TitledInput,
    TwoFactorEnable,
    TwoFactorDisable,
    Section,
  },
  data() {
    return {
      resendDisabled: false,
      resendDisabledTimeout: null,
      saveTimer: null,
      socials: [],
      showConvertAccount: false,
      showEditEmail: false,
      showEditPassword: false,
      showTwoFactorEnable: false,
      showTwoFactorDisable: false,
      showConfirmDelete: false,
      deleteConfirmText: '',
      deleteConfirmPassword: '',
      deleteConfirmError: null,
    };
  },
  computed: {
    showCurrentEmail() {
      return (!this.showEditEmail && !this.showConvertAccount);
    },
    hasLocalLogin() {
      return this.$store.getters.hasLocalLogin;
    },
    socialLoginMethods() {
      return this.profile.login_methods.filter(method => method !== LoginMethod.LOCAL);
    },
    profile() {
      return this.$store.getters.profile || {};
    },
    twoFactorEnabled() {
      return this.$store.getters.twoFactorEnabled;
    },
  },
  methods: {
    async sendConfirmationEmail() {
      resendConfirmation(this.profile.email);
      this.resendDisabled = true;
      // Add a listener to allow the user to reclick after a time
      this.saveTimer = setTimeout(() => { this.resendDisabled = false }, 10000);
    },
    showTwoFactor() {
      if(this.twoFactorEnabled) {
        this.showTwoFactorDisable = true;
      } else {
        this.showTwoFactorEnable = true;
      }
    },
    async accountDeleteConfirmed() {
      if(this.deleteConfirmPassword.length < 8) {
        this.deleteConfirmError = this.$t('settings.account.delete_confirm.error_password');
      } else if(this.deleteConfirmText !== 'DELETE') {
        this.deleteConfirmError = this.$t('settings.account.delete_confirm.error');
      } else {
        try {
          await deleteAccount(this.deleteConfirmPassword);
          this.$router.push({ name: 'login' });
        } catch(e) {
          this.deleteConfirmError = this.$t(fieldErrorKey(e.errors));
        }
      }
    },
    hideAccountDelete() {
      this.deleteConfirmError = null;
      this.showConfirmDelete = false;
    },
  },
  beforeDestroy() {
    if(this.saveTimer) {
      clearTimeout(this.saveTimer);
    }
  },
};
</script>

<style lang="scss">
@import 'widgets';

.account {
  .settings-social-item {
    border: 1px solid $grey-light;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    .subtitle {
      font-weight: 700;
      text-align: left;
    }
    h5 {
      color: $grey;
    }
    button {
      margin-left: auto;
      width: 98px;
    }
  }
  .settings-account-delete {
    @include button_small_text;
    padding: 56px 0;
    color: $main-orange;
    border-top: 1px solid $border-light;
    > span {
      cursor: pointer;
    }
  }
  .delete-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  .delete-button {
    @include button($main-black, full);
    width: 45%;
  }
  .settings-email {
    text-align: right;
    padding-right: 24px;
    line-height: 38px;

    .subtitle {
      color: $grey;
    }
  }
  .settings-button, .email-button {
    display: block;
  }
  .settings-button .connected {
    @include button($main-orange, small, secondary);
  }
  .email-button {
    @include button($main-orange, small, secondary);
    margin-top: 24px;
  }
}
@media (max-width: $tablet-width) {
  .account {
    .section-right {
      flex-wrap: wrap;
    }
    .settings-email {
      padding: 0;
      width: 215px;
      text-align: center;
    }
  }
}
@media (max-width: $mobile-width) {
  .account .settings-email {
    text-align: center;
  }
}
</style>
