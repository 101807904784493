<template>
<AuthLayout>
  <template #content>
    <form class="login-form login-modal-form forgot-password-contain" @submit="handleSubmit">
      <div class="login-form-title">
        {{ $t('auth.forgot_password') }}
      </div>
      <div class="forgot-password-subtitle">
        {{ message }}
      </div>
      <div v-if="!success">
        <TitledInput
          v-model="email"
          name="email"
          class="form-input"
          :placeholder="$t('auth.placeholders.email')"
          :maxLength="40"
          @input="clearErrors"
        />
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
        <button
          type="submit"
          class="login-modal-button"
          :disabled="errorMessage"
        >
          <div>{{ $t('auth.button_text.send') }}</div>
          <Arrow color="white" />
        </button>
      </div>
      <div
        class="back-button"
        @click="$router.push({ name: 'login' })"
      >
        {{ $t('auth.button_text.back_to_login') }}
      </div>
    </form>
  </template>
</AuthLayout>
</template>

<script>
import AuthLayout from '@/components/auth/AuthLayout.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import Arrow from '@/components/svg/Arrow.vue';
import { validateEmail } from '@/utils/stringUtils';
import { resetPassword } from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';

export default {
  name: 'forgot-password',
  components: {
    AuthLayout,
    TitledInput,
    Arrow,
  },
  data() {
    return {
      errorMessage: null,
      email: this.$route.query.email,
      success: false,
    };
  },
  computed: {
    message() {
      return this.success ? this.$t('auth.reset_password_email_sent') : this.$t('auth.enter_email');
    },
  },
  methods: {
    clearErrors() {
      this.errorMessage = null;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.clearErrors();
      const { email } = this;
      if(!email || email === '') {
        this.errorMessage = this.$t('errors.EMAIL_MISSING');
      } else if(!validateEmail(email)) {
        this.errorMessage = this.$t('errors.INVALID_VALUE.email');
      } else {
        try {
          await resetPassword(email);
          this.success = true;
        } catch(error) {
          this.errorMessage = this.$t(fieldErrorKey(error.errors));
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.forgot-password-contain {
  .forgot-password-subtitle {
    @include p_large;
    text-align: center;
    margin: 24px 0 16px;
    max-width: none;
  }
  .back-button {
    @include h5;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
  }
}
</style>

