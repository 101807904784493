import { getExchangeRate } from '@/utils/api';
import { VuexStatus } from '@/utils/constants';
import {
  EXCHANGE_RATE_REQUEST,
  EXCHANGE_RATE_SUCCESS,
  EXCHANGE_RATE_FAILURE,
} from '../actions';

const getInitialState = () => ({
  plgToUsd: null,
  status: VuexStatus.NONE,
  errors: null,
});

const state = getInitialState();

const getters = {
  plgToUsd: tickerState => tickerState.plgToUsd,
  isRateLoaded: ({ status }) => (
    (status === VuexStatus.SUCCESS) || (status === VuexStatus.ERROR)
  ),
  rateError: tickerState => tickerState.errors,
};

const actions = {
  [EXCHANGE_RATE_REQUEST]: async ({ commit }) => {
    commit(EXCHANGE_RATE_REQUEST);
    try {
      const res = await getExchangeRate();
      commit(EXCHANGE_RATE_SUCCESS, res.data.plg_to_usd);
    } catch(err) {
      commit(EXCHANGE_RATE_FAILURE, err.errors);
    }
  },
};

const mutations = {
  [EXCHANGE_RATE_REQUEST]: (tickerState) => {
    tickerState.errors = null;
    tickerState.status = VuexStatus.LOADING;
  },
  [EXCHANGE_RATE_SUCCESS]: (tickerState, exchangeRate) => {
    tickerState.status = VuexStatus.SUCCESS;
    tickerState.errors = null;
    tickerState.plgToUsd = exchangeRate;
  },
  [EXCHANGE_RATE_FAILURE]: (tickerState, errors) => {
    tickerState.errors = errors;
    tickerState.plgToUsd = null;
    tickerState.status = VuexStatus.ERROR;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
