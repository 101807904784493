<template>
<NotFound />
</template>

<script>
import NotFound from '@/components/nav/NotFound';

export default {
  name: 'not-found-page',
  components: {
    NotFound,
  },
};
</script>
