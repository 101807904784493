// Various utilities not specific to Pledgecamp

export function debounce(func, wait) {
  let timeout;
  return function(...args) { // eslint-disable-line
    const context = this;
    const callNow = !timeout;
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
    }, wait);
    if(callNow) {
      func.apply(context, args);
    }
  };
}

export function objectIdSort(objects, idOrder) {
  if(idOrder && (objects.length === idOrder.length)) {
    objects.sort((a, b) => idOrder.indexOf(a.id) - idOrder.indexOf(b.id));
  }
  return objects;
}

export function arraySum(array, accessFn) {
  const fn = accessFn || (x => x);
  return (array || []).reduce((a, b) => a + fn(b), 0);
}
