<template>
<div class="content">
  <AuthModal />
</div>
</template>

<script>
import AuthModal from '@/components/auth/AuthModal.vue';

export default {
  name: 'auth-view',
  components: {
    AuthModal,
  },
};
</script>

<style lang="scss">
@import 'widgets';

.auth-wrap {
  width: 100%;
  margin: 0 auto;
}
</style>
