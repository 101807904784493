<template>
<div class="settings-subscriptions">
  <Section :info="$t('settings.subscriptions')">
    <Checkbox
      class="select-all"
      :item="{ label: copy.label, checked: selectAllClass !== '' }"
      :onCheck="toggleAll"
      :checkedClass="selectAllClass"
    />
    <div
      v-for="section in copy.options"
      :key="section.title"
      class="checkbox-sections"
    >
      <h4 v-if="section.title" class="checkbox-section-heading">
        {{ section.title }}
      </h4>
      <Checkbox
        v-for="item in section.children"
        :id="item.key.toLowerCase()"
        :key="item.key"
        :item="{ ...item, checked: selectedKeys.includes(item.key) }"
        :onCheck="checked => optionChecked(item, checked)"
      />
    </div>
    <PButton
      class="save-button"
      :animate="saving"
      @click="save"
    >
      {{ $t('save') }}
    </PButton>
  </Section>
</div>
</template>

<script>
import Section from '@/components/widget/Section.vue';
import Checkbox from '@/components/widget/Checkbox.vue';
import PButton from '@/components/widget/PButton.vue';

import { USER_UPDATE } from '@/store/actions';

export default {
  name: 'settings-subscriptions',
  components: {
    Section,
    Checkbox,
    PButton,
  },
  data() {
    return {
      // Currently selected subscription keys, initially populated from Vuex
      selectedKeys: [],
      saving: false,
    };
  },
  computed: {
    optionsFromProfile() {
      return this.$store.getters.profile ? this.$store.getters.profile.email_subscriptions : [];
    },
    allKeys() {
      return this.copy.options
        .map(s => s.children)
        .flat()
        .map(o => o.key);
    },
    copy() {
      return this.$t('settings.subscriptions.checkbox_model');
    },
    selectAllClass() {
      const keyCount = this.selectedKeys.length;
      if(keyCount === this.allKeys.length) {
        return 'checked';
      }
      if(keyCount > 0) {
        return 'partial';
      }
      return '';
    },
  },
  watch: {
    // Populate currently selected keys list from Vuex
    optionsFromProfile(options) {
      this.selectedKeys = JSON.parse(JSON.stringify(options || []));
    },
  },
  methods: {
    toggleAll(checked) {
      this.selectedKeys = checked ? [...this.allKeys] : [];
    },
    optionChecked(item, checked) {
      if(checked) {
        this.selectedKeys.push(item.key);
      } else {
        this.selectedKeys.splice(this.selectedKeys.indexOf(item.key), 1);
      }
    },
    async save() {
      this.saving = true;
      try {
        await this.$store.dispatch(USER_UPDATE, {
          email_subscriptions: this.selectedKeys,
        });
      } catch(error) {
        console.error(error);
      }
      this.saving = false;
    },
  },
  mounted() {
    this.selectedKeys = JSON.parse(JSON.stringify(this.optionsFromProfile));
  },
};
</script>

<style lang="scss">
@import 'widgets';

.settings-subscriptions {
  .section-wrap .section-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    min-width: unset;
    margin-left: 0;
  }

  .p-checkbox {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .select-all {
    justify-content: flex-start;
    margin-right: 0;
    .checkbox-text { margin-right: 16px; }
  }

  .checkbox-sections {
    margin-bottom: 40px;

    .checkbox-section-heading {
      @include subheading;
      color: $black-light;
      text-transform: uppercase;
      margin: 8px 0;
    }

    .p-checkbox { width: 100%; }
  }
  .save-button {
    width: 180px;
    align-self: flex-end;
  }
}

@media (max-width: $tablet-width) {
  .settings-subscriptions .section-wrap {
    flex-direction: column;
    .section-left {
      max-width: unset;
    }
    .section-right {
      margin-top: 48px;
      justify-content: space-between;
      max-width: unset;
    }
  }
}
</style>
