<template>
<AuthLayout
  @close-modal="handleClose"
>
  <template #content>
    <Login
      v-if="isLogin && !incompleteStatus"
      :loginUser="loginUser"
      :socialLoginSignup="socialLoginSignup"
      @goto-signup="toggleAuthView('signup')"
      @close-modal="handleClose"
    />
    <Signup
      v-if="!isLogin && !incompleteStatus"
      :loginUser="loginUser"
      :socialLoginSignup="socialLoginSignup"
      @close-modal="handleClose"
      @goto-login="toggleAuthView('login')"
    />
    <CompleteInfo
      v-if="incompleteStatus === 'missing_info'"
      :key="missingInfo"
      :missingInfo="missingInfo"
      :authInfoComplete="authInfoComplete"
      :credentials="credentials"
      @close-modal="handleClose"
    />
    <Login2FA
      v-if="incompleteStatus === 'totp_not_confirmed'"
      :credentials="credentials"
      :authorize2fa="authorize2fa"
      :reset2fa="reset2fa"
    />
  </template>
</AuthLayout>
</template>

<script>
import Signup from '@/components/auth/Signup.vue';
import Login from '@/components/auth/Login.vue';
import Login2FA from '@/components/auth/Login2FA.vue';
import CompleteInfo from '@/components/auth/CompleteInfo.vue';
import AuthLayout from '@/components/auth/AuthLayout.vue';
import Social from '@/mixins/Social';
import { authorizeTotp, resetUserTotp } from '@/utils/api';
import {
  USER_LOGIN,
  USER_REQUEST,
  SET_LOGIN_REDIRECT,
  SET_USER_SIGNUP,
} from '@/store/actions';

export default {
  name: 'login-modal',
  components: {
    Signup,
    Login,
    Login2FA,
    CompleteInfo,
    AuthLayout,
  },
  mixins: [Social],
  props: {
    isSignup: Boolean,
  },
  data() {
    return {
      isLogin: true,
      credentials: {},
      incompleteStatus: '',
      missingInfo: [],
    };
  },
  computed: {
    authenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close-modal');
      document.body.classList.remove('noscroll');
    },
    toggleAuthView(routeName) {
      this.$router.push({ name: routeName });
      this.isLogin = !this.isLogin;
    },
    async loginUser(credentials, signup = false) {
      this.credentials = credentials;
      try {
        const { totp_required: totp } = await this.$store.dispatch(USER_LOGIN, credentials);
        if(totp) {
          this.incompleteStatus = 'totp_not_confirmed';
        } else {
          await this.$store.dispatch(SET_USER_SIGNUP, signup);
          this.redirectUser();
        }
      } catch(error) {
        if(error.status === 400 && error.errors) {
          // 400 ： missing_user_email, missing_user_name, email_not_confirmed
          // shows CompleteInfo widget
          this.incompleteStatus = 'missing_info';
          this.missingInfo = error.errors;
        } else {
          // error handling inside Login widget
          throw error;
        }
      }
    },
    async authorize2fa(data) {
      await authorizeTotp(data);
      await this.$store.dispatch(USER_REQUEST, true);
      this.redirectUser();
    },
    async reset2fa(key) {
      await resetUserTotp(key);
      this.incompleteStatus = null;
      this.credentials = {};
    },
    async authInfoComplete() {
      await this.$store.dispatch(USER_REQUEST, true);
      if(this.$store.getters.userError) {
        this.incompleteStatus = 'missing_info';
        this.missingInfo = this.$store.getters.userError.errors || [];
      } else {
        this.redirectUser();
      }
    },
    redirectUser() {
      const { loginRedirect } = this.$store.getters;
      this.$store.dispatch(SET_LOGIN_REDIRECT, null);
      if(loginRedirect) {
        this.$router.push({ path: loginRedirect });
      } else {
        this.$router.push({ name: 'user' });
      }
    },
  },
  mounted() {
    const { name } = this.$route;
    this.isLogin = name === 'login';
  },
};
</script>

<style lang="scss">
@import 'widgets';

.modal-login-outer, .auth-wrap {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.modal-login-outer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  transition: opacity $speed3 ease;
  z-index: 2000;
  overflow-y: auto;
}
.modal-auth-inner {
  margin: 0 auto;
  padding: 20px 30px 0;
  background-color: $white;
  transition: all $speed3 ease;
  &.auth-inner {
    transition: unset;
    padding-top: 40px;
  }
  .modal-auth-main {
    position: relative;
    margin: 0 auto;

    .images {
      position: relative;
      margin-top: 100px;
      .images-left {
        float: left;
      }
      .images-right {
        float: right;
      }
    }
  }
  @media (max-width: $tablet-width-large) {
    min-height: 750px;
    .modal-auth-main {
      .images {
        margin-top: 110px;
        .images-left {
          margin-left: -30px;
        }
        .images-right {
          margin-right: -30px;
        }
      }
    }
  }
  @media (max-width: $mobile-width) {
    padding: 20px 20px 0;
    .modal-auth-main {
      .images {
        display: none;
      }
    }
  }
}

.image-item {
  position: relative;
  &.socks {
    height: 100px;
    bottom: 200px;
    left: -75px;
  }
  &.gin {
    height: 180px;
  }
  &.singer {
    height: 130px;
    left: -35px;
  }
  &.phone {
    height: 180px;
    right: -76px;
  }
  &.gadget {
    height: 100px;
    bottom: 200px;
  }
  &.bottles {
    height: 110px;
    right: -35px;
  }
  @media (max-width: $tablet-width-large) {
    &.socks {
      left: -115px;
    }
    &.gadget {
      right: 30px;
    }
  }
  @media (max-width: $tablet-width) {
    &.socks, &.gadget {
      display: none;
    }
    &.singer {
      left: 16px;
    }
    &.bottles {
      right: 36px;
    }
    &.phone {
      right: 20px;
    }
  }
  @media (max-width: 649px) {
    &.bottles {
      display: none;
    }
  }
}

.modal-login-header {
  width: 100%;
  height: 60px;
  margin: 0 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login-modal-close {
  @include subtitle;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  & > div {
    padding-top: 2px;
  }
  .close-img {
    margin-left: 10px;
    width: 16px;
    height: 16px;
  }
}

.modal-auth-form-wrap {
  width: 100%;
  height: 465px;
  background-image: none;
  position: relative;

  .login-modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    > * {
      max-width: 412px;
      width: 100%;
    }

    .form-input {
      margin-bottom: 8px;
    }
    .login-form-title {
      @include h1;
      text-align: center;
      max-width: 512px;
    }
    .info-title {
      margin-top: 30px;
      padding: 0 30px;
    }
    .login-subtitle {
      @include button_large_text;
      margin: 12px 0 40px;
      text-align: center;
    }
    .signup-or-wrap {
      display: flex;
      align-items: center;
      margin: 24px 0 12px;
      .signup-or {
        padding: 0 8px;
        @include h4;
      }
      .signup-or-border {
        height: 1px;
        background-color: $border-light;
        flex-grow: 2;
        margin-bottom: 3px;
      }
    }
    .error-message {
      @include error;
      padding-left: 16px;
    }
    .login-modal-button {
      @include button($main-black, full);
      justify-content: space-between;
      padding: 7px 12px;
      margin: 20px 0;
      &.signup-invalid {
        background-color: $grey-light;
      }
    }
    .save-button {
      justify-content: center;
      background-color: $main-blue;
    }
    .eyeball {
      position: relative;
      .pic {
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 10px;
        margin-top: -36px;
        height: 12px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: $tablet-width) {
    .login-modal-form {
      .login-form-title {
        @include h2;
      }
      .login-subtitle {
        @include button_small_text;
        margin: 5px 0 24px;
      }
    }
  }
}
.signup-invalid {
  border: 1px solid red;
}
.signup-toggle {
  cursor: pointer;
  color: $main-blue;
}
</style>
