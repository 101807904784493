<template>
<Modal
  class="kyc-modal"
  :cancelable="true"
  :submitable="false"
  @cancel="$emit('close')"
>
  <template #content>
    <h3 class="kyc-modal-title">
      {{ $t('settings.verification.kyc_modal.title') }}
    </h3>
    <div class="kyc-modal-content">
      <p class="p_small">
        {{ $t(`settings.verification.kyc_modal.content.${messageKey}`) }}
      </p>
      <div v-if="startingKYC" class="content-body">
        <KYCForm
          v-model="kycInfo"
          formName="kyc"
          @submit="initKYC"
        />
        <div class="powered-by">
          {{ $t('settings.verification.kyc_modal.powered_by') }}
          <img class="veriff-icon" :src="require('@/static/img/veriff.png')">
        </div>
      </div>
      <div v-if="removingKYC" class="content-body">
        <button class="remove_confirm" @click="removeKYC">
          {{ $t('settings.verification.kyc_modal.buttons.confirm_remove') }}
        </button>
        <button class="remove_cancel" @click="$emit('close')">
          {{ $t('settings.verification.kyc_modal.buttons.confirm_cancel') }}
        </button>
      </div>
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import KYCForm from '@/components/forms/KYCForm.vue';
import { KYCStatus } from '@/utils/constants';
import { startKYC } from '@/utils/api';

export default {
  name: 'kyc-modal',
  components: {
    Modal,
    KYCForm,
  },
  props: {
    status: {
      type: String,
      default: KYCStatus.KYC_NOT_DONE,
    },
    messageType: {
      type: Number,
      default: 0,
    },
    kycUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kycInfo: {
        firstName: '',
        lastName: '',
      },
      error: '',
    };
  },
  computed: {
    startingKYC() {
      return !this.error && this.status === KYCStatus.KYC_NOT_DONE;
    },
    removingKYC() {
      return this.status === KYCStatus.KYC_COMPLETE;
    },
    messageKey() {
      return this.error || this.status;
    },
  },
  methods: {
    async initKYC() {
      try {
        const { kyc_url: kycUrl } = await startKYC();
        if(!kycUrl) {
          throw new Error('URL_FAIL');
        }
      } catch(error) {
        // TODO handle other errors
        this.error = 'URL_FAIL';
      }
    },
    removeKYC() {
      // TODO remove KYC
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.kyc-modal {
  text-align: center;

  .modal-inner {
    max-width: 568px;
  }

  .kyc-modal-content {
    max-width: 568px;

    .content-body { margin-top: 56px; }
  }

  .submit-btn {
    @include button($main-black, full, primary);
    margin: 24px 0;
  }

  .remove_confirm {
    @include button($main-red, small, primary);
    margin-right: 50px;
  }

  .remove_cancel {
    @include button($main-black, small, secondary);
    background-color: $white;
    text-transform: uppercase;
  }

  .powered-by {
    @include subtitle;

    color: $grey50;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;

    img { margin-left: 13px; }
  }
}
</style>
