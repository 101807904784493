<template>
<div class="validate">
  <div class="validate-item">
    <div class="icon">
      <img
        v-if="validLength"
        class="check"
        :src="require('@/static/img/icons/ic_check.png')"
      >
      <img
        v-if="!validLength"
        class="close"
        :src="require('@/static/img/icons/ic_close.png')"
      >
    </div>
    <div>{{ $t('auth.checks.chars') }}</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'password-validate',
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      validLength: false,
    };
  },
  computed: {
    passwordValid() {
      return this.validLength;
    },
  },
  watch: {
    password(pass) {
      this.handlePasswordInput(pass);
    },
  },
  methods: {
    handlePasswordInput(pass) {
      this.validLength = pass.length >= 8;
      this.$emit('password-validation', this.passwordValid);
    },
  },
  mounted() {
    this.handlePasswordInput(this.password);
  },
};
</script>

<style lang="scss" scoped>
@import 'widgets';

.validate {
  @include p_info;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 15px;
  .validate-item {
    margin-right: 10px;
    height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      height: 15px;
      width: 15px;
    }
  }
  .check {
    padding-bottom: 2px;
    width: 12px;
    filter: invert(19%) sepia(96%) saturate(2869%) hue-rotate(234deg) brightness(91%) contrast(103%);
  }
  .close {
    padding-bottom: 1px;
    width: 10px;
    filter: invert(11%) sepia(99%) saturate(6577%) hue-rotate(2deg) brightness(99%) contrast(112%);
  }
}
</style>
