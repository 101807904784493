import {
  SET_REFERRAL,
  CLEAR_REFERRAL,
  SET_REFERRAL_SEARCH,
  SET_REFERRAL_INTERNAL,
} from '../actions';

const state = {
  referral: {},
};

const getters = {
  referral: referralState => referralState.referral,
};

const actions = {
  [SET_REFERRAL]: ({ commit }, referralData) => {
    commit(SET_REFERRAL, referralData);
  },
  [CLEAR_REFERRAL]: ({ commit }) => {
    commit(CLEAR_REFERRAL);
  },
  [SET_REFERRAL_SEARCH]: ({ commit }, query) => {
    commit(SET_REFERRAL, {
      internal_referral_type: 'SEARCH',
      internal_referral_value: query,
    });
  },
  [SET_REFERRAL_INTERNAL]: ({ commit }, page) => {
    commit(SET_REFERRAL, {
      internal_referral_type: 'INTERNAL',
      internal_referral_value: page,
    });
  },
};

const mutations = {
  [SET_REFERRAL]: (referralState, referralData) => {
    referralState.referral = {
      ...referralState.referral,
      ...referralData,
    };
  },
  [CLEAR_REFERRAL]: (referralState) => {
    referralState.referral = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
