
export const keepAliveInMinutes = 5; // in minutes

const {
  VUE_APP_API_HOST,
  VUE_APP_FRONTEND_PORT,
  VUE_APP_FRONTEND_DOMAIN,
  VUE_APP_SECURE_PROTOCOL,
} = process.env;

const protocol = (VUE_APP_SECURE_PROTOCOL === '1') ? 'https' : 'http';
const port = VUE_APP_FRONTEND_PORT ? `:${VUE_APP_FRONTEND_PORT}` : '';

export const siteUrl = `${protocol}://${VUE_APP_FRONTEND_DOMAIN}${port}` || 'https://pledgecamp.com';
export const apiBaseUrl = (`${VUE_APP_API_HOST}/api/` || 'http://localhost:5000/api/');

export const facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
export const twitterShareUrl = 'https://twitter.com/intent/tweet';
export const linkedinShareUrl = 'https://www.linkedin.com/shareArticle?mini=true';

export const isProduction = process.env.NODE_ENV === 'production';
export const loggingEnabled = !!process.env.VUE_APP_LOGGING;
