<template>
<div class="projects-section-wrap container">
  <FeaturedItem
    v-if="showFeatured"
    :project="signatureProject"
    :mirrored="mirrored"
    :showPending="true"
  />
  <div v-else class="container">
    <slot v-if="hideSlider" :projects="projects" />
    <ProjectSlider
      v-else
      :projects="projects"
      :funded="$attrs.id === 'funded'"
      :title="title"
      :subTitle="subTitle"
      :loading="!projects"
      @view-all="$emit('view-all')"
    />
  </div>
</div>
</template>

<script>
import FeaturedItem from '@/components/home/FeaturedItem.vue';
import ProjectSlider from '@/components/home/ProjectSlider.vue';

export default {
  name: 'project-showcase',
  components: {
    FeaturedItem,
    ProjectSlider,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    hideSlider: Boolean,
    mirrored: Boolean,
    showFeatured: Boolean,
  },
  computed: {
    signatureProject() {
      return this.projects ? this.projects[0] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.projects-section-wrap {
  display: flex;
  justify-content: center;
  overflow: visible;
  .container {
    z-index: 2;
  }
}

@media (max-width: $mobile-width) {
  .projects-section-wrap {
    padding: 0;
  }
}
</style>
