import { render, staticRenderFns } from "./FeaturedItem.vue?vue&type=template&id=5b098dee&"
import script from "./FeaturedItem.vue?vue&type=script&lang=js&"
export * from "./FeaturedItem.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports