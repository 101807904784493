<template>
<div class="notifications-menu-wrap">
  <div class="notifications-header">
    <div class="header-title">
      {{ $t('header.notificationsMenu.notifications') }}
    </div>
    <div
      v-if="notifications.length"
      class="mark-read"
      @click="markAllRead"
    >
      {{ $t('header.notificationsMenu.mark_read') }}
    </div>
  </div>
  <div v-if="loading" class="spinner">
    <Spinner :size="40" />
  </div>
  <div v-else-if="notifications.length" class="notifications-list">
    <NotificationsItem
      v-for="(notification, index) in notifications"
      :key="index"
      :notification="notification"
      @mark-read="markRead"
    />
  </div>
  <div v-else class="empty">
    {{ $t('header.notificationsMenu.empty') }}
  </div>
  <div
    v-if="notifications.length"
    class="view-all"
    @click="viewAll"
  >
    {{ $t('view_all') }}
  </div>
</div>
</template>

<script>
import NotificationsItem from '@/components/notifications/NotificationsItem.vue';
import Spinner from '@/components/widget/Spinner';
import { USER_NOTIFICATIONS } from '@/store/actions';
import { markAllNotificationsRead } from '@/utils/api';

export default {
  name: 'user-menu',
  components: {
    NotificationsItem,
    Spinner,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    notifications() {
      return this.$store.getters.notifications;
    },
    loading() {
      return this.$store.getters.notificationsLoading;
    },
  },
  methods: {
    markRead() {
      // TODO -- API integration
    },
    async markAllRead(e) {
      e.stopPropagation();
      try {
        await markAllNotificationsRead();
        this.$store.dispatch(USER_NOTIFICATIONS);
      } catch(err) {
        console.log('Error marking all read.');
      }
    },
    viewAll() {
      this.$router.replace({ path: '/notifications' });
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.notifications-menu-wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1px;
  background-color: $white;
  box-shadow: 0 16px 56px rgba(0, 0, 0, 0.15);
  @include h5;
  min-width: 355px;
  width: 483px;
  padding: 0 1px;

  .notifications-header {
    display: flex;
    justify-content: space-between;
    padding: 25px 18px 12px;
    box-shadow: 0 1.5px 0 $border-light;
    .header-title {
      @include h4;
    }
    .mark-read {
      @include subheading;
      color: $main-blue;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .view-all {
    @include button($main-black, large);
  }
  .spinner {
    padding: 25px 0;
    box-shadow: 0 1.5px 0 $border-light;
  }
  .empty {
    padding: 25px 0;
    margin: 0 auto;
    @include h5;
    font-size: 16px;
  }

  @media (max-width: $tablet-width) {
    margin-right: -40px;
  }
  @media (max-width: 540px) {
    width: 100%;
  }
  @media (max-width: $mobile-width) {
    margin-right: -60px;
  }
  @media (max-width: 370px) {
    min-width: 300px;
  }
}
</style>
