<template>
<div class="home">
  <!--
  <AgreementModal
    v-if="!agreementHidden"
    @accept="hideAgreement"
  />
  -->

  <HomeIntro id="home" />

  <ProjectShowcase
    id="featured"
    :showFeatured="true"
    :projects="featuredProjects"
  />

  <CreatorBacker id="creator-backer" />

  <ProjectShowcase
    id="trending"
    :showFeatured="true"
    :title="$t('project_filters.trending')"
    :projects="trendingProjects"
    :mirrored="true"
    @view-all="navigateToProjectList('trending')"
  />

  <ArticleSection id="articles" />

  <div class="recent-project-art art-wrap">
    <img :src="require('@/static/img/art/circle.png')">
  </div>
  <ProjectShowcase
    id="recent"
    :showFeatured="true"
    :projects="recentProjects"
    :title="$t('project_filters.recently_launched')"
    @view-all="navigateToProjectList('recently-launched')"
  />

  <StorefrontSection id="storefront" />

  <div class="storefront-art art-wrap">
    <img :src="require('@/static/img/art/squiggle.png')">
  </div>
  <ProjectShowcase
    id="funded"
    :projects="fundedProjects"
    :title="$t('project_filters.past_successes')"
    :subTitle="$t('home.past_successes_desc')"
    :mirrored="true"
    @view-all="navigateToProjectList('past-successes')"
  />
  <ExploreSection id="explore" />
  <SubscribeSection id="subscribe" />
</div>
</template>

<script>
import HomeIntro from '@/components/home/HomeIntro.vue';
// import AgreementModal from '@/components/home/AgreementModal.vue';
// import FeaturedSection from '@/components/home/FeaturedSection.vue';
import CreatorBacker from '@/components/home/CreatorBacker.vue';
import StorefrontSection from '@/components/home/StorefrontSection.vue';
import ArticleSection from '@/components/home/ArticleSection.vue';
import ExploreSection from '@/components/home/ExploreSection.vue';
import SubscribeSection from '@/components/home/SubscribeSection.vue';
import ProjectShowcase from '@/components/home/ProjectShowcase.vue';
import * as api from '@/utils/api';

export default {
  name: 'home',
  components: {
    // AgreementModal,
    // FeaturedSection,
    ArticleSection,
    CreatorBacker,
    ExploreSection,
    HomeIntro,
    ProjectShowcase,
    StorefrontSection,
    SubscribeSection,
  },
  data() {
    return {
      maskHidden: false,
      agreementHidden: false,
      featuredProjects: [],
      trendingProjects: [],
      fundedProjects: [],
      recentProjects: [],
    };
  },
  computed: {
    fundedSingle() {
      return this.fundedProjects && this.fundedProjects.length === 1;
    },
  },
  methods: {
    hideAgreement() {
      this.agreementHidden = true;
      localStorage.setItem('agreementHidden', true);
    },
    hideMask() {
      this.maskHidden = true;
      localStorage.setItem('maskHidden', true);
    },
    navigateToProjectList(filter) {
      this.$router.push({ name: 'project-list', params: { filter } });
    },
  },
  async created() {
    this.agreementHidden = localStorage.getItem('agreementHidden');
    this.maskHidden = localStorage.getItem('maskHidden');
    let { projects } = await api.getFeaturedProjects();
    this.featuredProjects = projects;

    ({ projects } = await api.getProjects({ filter: 'trending' }));
    this.trendingProjects = projects;

    ({ projects } = await api.getProjects({ filter: 'recently_launched' }));
    this.recentProjects = projects;

    ({ projects } = await api.getProjects({ filter: 'past_successes' }));
    this.fundedProjects = projects;
  },
};
</script>

<style lang="scss">
.home {
  flex-grow: 1;

  .success-single {
    margin: 0 0 88px;
    .header-title {
      margin-bottom: 24px;
    }
  }

  #featured {
    margin-top: 64px;
  }

  .art-wrap {
    position: absolute;
    width: 100%;
    z-index: 1;

    &.recent-project-art img {
      top: 98px;
      position: absolute;
      right: 0;
      width: 114px;
    }

    &.storefront-art img {
      position: absolute;
      top: 153px;
      left: -31px;
      height: 104px;
    }
  }

  .section {
    padding-top: 72px;
    padding-bottom: 72px;
    margin-top: 72px;
    margin-bottom: 72px;
  }

  @media (max-width: $tablet-width) {
    #featured {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: $mobile-width) {
    .section {
      padding-top: 40px;
      padding-bottom: 40px;
      margin-bottom: 24px;
    }
    .success-single {
      margin-bottom: 40px;
    }

    .recent-project-art,
    .storefront-art {
      display: none;
    }
  }
}
</style>
