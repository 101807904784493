<template>
<div class="error-message-wrap">
  <transition name="animate-height" appear>
    <div v-if="errorMessage" class="error-message">
      <div class="error">
        <Info color="#EC4A29" class="error-icon" />
        <span>{{ errorMessage }}</span>
      </div>
      <div v-if="errorSubMessage" class="error-submessage">
        <span>{{ errorSubMessage }}</span>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import Info from '@/components/svg/Info';

export default {
  name: 'error-message',
  components: {
    Info,
  },
  props: {
    errorMessage: {
      type: String,
      default: null,
    },
    errorSubMessage: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.error-message-wrap {
  width: 100%;

  .error {
    @include error;
  }
  .error-icon {
    flex-shrink: 0;
  }
  .error-message {
    max-height: 100px;
    opacity: 1;
    transition: all $speed3;
  }

  .error-submessage {
    padding-left: 24px;
    width: 100%;
    span {
      @include p_info;
      color: $grey;
      font-weight: 400;
    }
  }
  .animate-height-enter, .animate-height-leave-to {
    max-height: 0;
    opacity: 0;
  }
}

</style>
