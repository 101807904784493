import axios from 'axios';
import moment from 'moment';

// Fake data.
import { ProjectType } from '@/utils/constants';
import { objectIdSort } from '@/utils';
import { apiBaseUrl } from '@/utils/config';
import wallets from './fake_wallet_api.json';

export const ImageType = {
  MAIN: 'main-image',
  SOCIAL: 'social-media-image',
  CAROUSEL: 'images',
  DESCRIPTION: 'description/images',
};

export const client = axios.create({
  baseURL: apiBaseUrl,
  timeout: 10000,
  method: 'GET',
  withCredentials: true,
});

function jsonConfig(config) {
  config.headers = {
    ...config.headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return config;
}

function authConfig(config) {
  return config;
}

function request(config) {
  if(config.data) {
    jsonConfig(config);
  }
  return client.request(config);
}

function processProject(project) {
  project.start_time = project.start_time ? moment.unix(project.start_time) : null;
  project.end_time = project.end_time ? moment.unix(project.end_time) : null;
  (project.updates || []).forEach((update) => {
    update.date = moment(update.date);
  });
  project.milestones = (project.milestones || []).sort((a, b) => a.date - b.date);
  (project.milestones || []).forEach((milestone) => {
    milestone.date = moment(milestone.date * 1000);
  });
  project.crowdfunding = (project.funding_type === ProjectType.CROWDFUNDING);
  const { rewards, rewards_order: order } = project;
  project.rewards = objectIdSort(rewards, order);
  return project;
}

function processProjectsData(data) {
  (data.projects || []).forEach((project) => {
    processProject(project);
  });
  return data;
}

function processRewardsData({ pledge }) {
  const sortedProducts = pledge.products.sort((a, b) => a.reward.id - b.reward.id);
  return { ...pledge, products: sortedProducts };
}

function processPledges({ pledges }) {
  const data = pledges.map(pledge => (
    processRewardsData({ pledge })
  ));
  return data;
}

export function userLogin(auth) {
  const config = {
    url: 'auth/authorize/',
    method: 'POST',
    auth,
  };
  return request(config);
}

export function userLogout() {
  const config = authConfig({
    url: 'auth/authorize/',
    method: 'DELETE',
  });
  return request(config);
}

export function resendConfirmation(email) {
  const config = {
    url: '/users/me/email-resendconfirmation/',
    method: 'POST',
    data: { email },
  };
  return request(config);
}

export function deleteAccount(password) {
  const config = {
    url: '/users/me/',
    method: 'DELETE',
    data: { password },
  };
  return request(config);
}

export function userRegister(user) {
  const config = {
    url: 'users/',
    method: 'POST',
    data: user,
  };
  return request(config);
}

export function resetPassword(email) {
  const config = {
    url: '/auth/password-reset/',
    method: 'POST',
    data: { email },
  };
  return request(config);
}

export function resetPasswordConfirmation(code, newPassword) {
  const config = {
    url: '/auth/password-reset/confirm/',
    method: 'POST',
    data: { code, new_password: newPassword },
  };
  return request(config);
}

export function updateEmail({ email, password }) {
  return request({
    url: 'users/me/email/',
    method: 'PATCH',
    data: { email, password },
  });
}

export function confirmEmail(code) {
  return request({
    url: 'auth/email-confirm/',
    method: 'POST',
    data: { code },
  });
}

export function requestSocialAuth(socialType) {
  const config = {
    url: `/auth/sso/${socialType}/authorize/`,
    method: 'GET',
    withCredentials: true,
  };
  return request(config);
}

export function socialLogin(type) {
  const config = {
    url: `/auth/sso/${type.toLowerCase()}/login/`,
    method: 'GET',
    withCredentials: true,
  };
  return request(config);
}

export function authorizeTotp({ auth, code }) {
  return request({
    url: '/auth/authorize/totp/',
    method: 'POST',
    auth,
    data: { code },
  });
}

export function enableUserTotp() {
  return request({
    url: '/auth/totp/enable/',
    method: 'POST',
  });
}

export function confirmEnableUserTotp(data) {
  return request({
    url: '/auth/totp/confirm/',
    method: 'POST',
    data,
  });
}

export function disableUserTotp(data) {
  return request({
    url: '/auth/totp/disable/',
    method: 'POST',
    data,
  });
}

export function resetUserTotp(key) {
  return request({
    url: '/auth/totp/reset/',
    method: 'POST',
    data: { totp_recovery_key: key },
  });
}

export function listSubscribe(email) {
  return request({
    url: '/mailing-list/subscribe',
    method: 'POST',
    data: { email },
    timeout: 25000,
  });
}

export function getUserProfile(userId) {
  return request({
    url: `/users/${userId}/`,
    method: 'GET',
  });
}

export function getProfile() {
  const config = authConfig({
    url: 'users/me/',
  });
  return request(config);
}

export function updateProfile(profileData) {
  const config = authConfig({
    url: 'users/me/',
    data: profileData,
    method: 'PATCH',
  });
  return request(config);
}

export function updatePassword({ password, newPassword }) {
  const config = authConfig({
    url: 'users/me/password/',
    data: { password, new_password: newPassword },
    method: 'PATCH',
  });

  return request(config);
}

export function getCategories() {
  return request({ url: 'categories/' });
}

export async function getProjects({ filter, sort, categoryId, page, perPage }) {
  const { data } = await request({
    url: `projects/filter/${filter}/`,
    params: {
      category_id: categoryId,
      page,
      sort,
      per_page: perPage,
    },
  });
  return processProjectsData(data);
}

export async function getProjectByName(name) {
  const { data } = await request({
    url: `projects/${name}/`,
  });
  return processProjectsData(data);
}

export function getProjectExists(name) {
  return request({
    url: `projects/${name}/exists/`,
  });
}

export async function getFeaturedProjects() {
  const { data } = await request({
    url: 'projects/filter/featured/',
  });
  return processProjectsData(data);
}

async function getUserProjects({ userId, page, perPage }, projectType) {
  const config = authConfig({
    url: `/projects/filter/${projectType}/${userId}/`,
    method: 'GET',
    params: { page, per_page: perPage },
  });
  const { data } = await request(config);
  return processProjectsData(data);
}

export async function getCreatedProjects(data) {
  return getUserProjects(data, 'user-created');
}

export async function getPledgedProjects(data) {
  return getUserProjects(data, 'user-pledged');
}

export async function getPledges(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/me/`,
  });
  const { data } = await request(config);
  return processPledges(data);
}

export function getProjectPledge(projectId, pledgeId) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/${pledgeId}/`,
    method: 'GET',
  });
  return request(config);
}

export function getProjectPledges(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/`,
    method: 'POST',
    data,
  });
  return request(config);
}

export async function getProjectPledgeDestinations(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/destinations/`,
    method: 'GET',
  });
  const { data: { destinations } } = await request(config);
  return destinations;
}

export function setPledgesShippingStatus(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/shipping/`,
    method: 'PATCH',
    data,
  });
  return request(config);
}

export function getPledgeSegments(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/segments/`,
    method: 'GET',
  });
  return request(config);
}

export function createPledgeSegment(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/segments/`,
    method: 'POST',
    data,
  });
  return request(config);
}

export function deletePledgeSegment(projectId, segmentId) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/segments/${segmentId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function exportPledges(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/export/`,
    method: 'POST',
    data,
  });
  return request(config);
}

export async function setPledgeItem({ projectId, variantId, quantity }) {
  try {
    const config = authConfig({
      url: `/projects/${projectId}/pledges/me/item/${variantId}/`,
      method: 'PATCH',
      data: { quantity },
    });
    const { data } = await request(config);
    return processRewardsData(data);
  } catch(err) {
    throw new Error(err);
  }
}

export async function setCheckedOutPledgeItem({ projectId, variantId, quantity }) {
  try {
    const config = authConfig({
      url: `/projects/${projectId}/pledges/me/checkedout/variant/${variantId}/`,
      method: 'PATCH',
      data: { quantity },
    });
    const { data } = await request(config);
    return processRewardsData(data);
  } catch(err) {
    throw new Error(err);
  }
}

export function pledgeUpdateShipping(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/me/shipping/`,
    method: 'PATCH',
    data,
  });
  return request(config);
}

export function pledgeCheckout(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/me/checkout/`,
    method: 'POST',
    data,
  });
  return request(config);
}

export function pledgeCancel(projectId, pledgeId) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/me/cancel/${pledgeId}/`,
    method: 'POST',
  });
  return request(config);
}

export function pledgeApplyCoupon(projectId, code) {
  const config = authConfig({
    url: `projects/${projectId}/pledges/me/coupon/`,
    method: 'PATCH',
    data: { code },
  });
  return request(config);
}

export function milestoneVote(projectId, refund) {
  const config = authConfig({
    url: `projects/${projectId}/vote/`,
    method: 'PATCH',
    data: { refund },
  });
  return request(config);
}

export async function subscribeProject(projectId, subscribe) {
  const config = authConfig({
    url: `projects/${projectId}/subscribe/`,
    method: 'POST',
    data: { subscribe },
  });
  const { data: { subscribed } } = await request(config);
  return subscribed;
}

export async function getProjectDetail(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/`,
    method: 'GET',
  });
  const { data: { project } } = await request(config);
  return processProject(project);
}

export async function getProjectPreview(code) {

  const config = authConfig({
    url: `projects/preview/${code}/`,
    method: 'GET',
  });

  const { data: { project } } = await request(config);
  return processProject(project);
}

export async function getCreatorProjectDetail(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/private/`,
  });
  try {
    const { data: { project } } = await request(config);
    return processProject(project);
  } catch(e) {
    return null;
  }
}

export function getQuestions(filter) {
  const f = filter ? filter.toLowerCase() : 'popular';
  return request({ url: `questions/${f}/` });
}

export function getQuestionDetail(questionId) {
  return request({ url: `question/${questionId}` });
}

export function createProject(projectData) {
  const config = authConfig({
    url: 'projects/',
    method: 'POST',
    data: projectData,
  });
  return request(config);
}

export function updateProject({ projectId, projectData }) {
  const config = authConfig({
    url: `projects/${projectId}/`,
    method: 'PATCH',
    data: projectData,
  });
  return request(config);
}

export function deleteProject({ projectId }) {
  const config = authConfig({
    url: `projects/${projectId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function flagProject(projectId, projectData) {
  const config = authConfig({
    url: `/projects/${projectId}/flags/`,
    method: 'POST',
    data: projectData,
  });
  return request(config);
}

export function uploadProjectImage({ projectId, type, file }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('locale', 'en');
  return request(authConfig({
    url: `projects/${projectId}/${type}/`,
    method: 'POST',
    data: formData,
    timeout: 30000,
  }));
}

export function deleteProjectImage({ projectId, imageId }) {
  const config = authConfig({
    url: `projects/${projectId}/images/${imageId}/`,
    method: 'DELETE',
    data: { locale: 'en' },
  });
  return request(config);
}

export function updateProjectBankInfo({ projectId, data }) {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  const config = authConfig({
    url: `projects/${projectId}/bank-account/`,
    method: 'PATCH',
    data: formData,
  });
  return request(config);
}

export async function getRewardAnalytics(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/insights/reward/`,
    method: 'GET',
  });
  const { data } = await request(config);
  return data.reward_analytics;
}

export function createReward({ projectId, reward }) {
  const config = authConfig({
    url: `projects/${projectId}/rewards/`,
    method: 'POST',
    data: reward,
  });
  return request(config);
}

export function updateReward({ projectId, rewardId, reward }) {
  const config = authConfig({
    url: `projects/${projectId}/rewards/${rewardId}/`,
    method: 'PATCH',
    data: reward,
  });
  return request(config);
}

export function deleteReward({ projectId, rewardId }) {
  const config = authConfig({
    url: `projects/${projectId}/rewards/${rewardId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function createRewardVariant({ projectId, rewardId, variant }) {
  const config = authConfig({
    url: `projects/${projectId}/rewards/${rewardId}/variants/`,
    method: 'POST',
    data: variant,
  });
  return request(config);
}

export function updateRewardVariant({ projectId, rewardId, variantId, variant }) {
  const config = authConfig({
    url: `projects/${projectId}/rewards/${rewardId}/variants/${variantId}/`,
    method: 'PATCH',
    data: variant,
  });
  return request(config);
}

export function deleteRewardVariant({ projectId, rewardId, variantId }) {
  const config = authConfig({
    url: `projects/${projectId}/rewards/${rewardId}/variants/${variantId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function getCollaborators(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/collaborators/`,
    method: 'GET',
  });
  return request(config);
}

export function getCollaboratorInvites(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/invites/`,
    method: 'GET',
  });
  return request(config);
}

export function inviteCollaborator(projectId, email, role) {
  const config = authConfig({
    url: `projects/${projectId}/invites/`,
    method: 'POST',
    data: {
      email,
      role,
    },
  });
  return request(config);
}

export function updateVariantImage({ projectId, rewardId, variantId, file }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('locale', 'en');
  const config = authConfig({
    url: `projects/${projectId}/rewards/${rewardId}/variants/${variantId}/image/`,
    data: formData,
    method: 'PATCH',
  });
  return request(config);
}

export function createMilestone({ projectId, milestone }) {
  const config = authConfig({
    url: `projects/${projectId}/milestones/`,
    method: 'POST',
    data: milestone,
  });
  return request(config);
}

export function updateMilestone({ projectId, milestoneId, milestone }) {
  const config = authConfig({
    url: `projects/${projectId}/milestones/${milestoneId}/`,
    method: 'PATCH',
    data: milestone,
  });
  return request(config);
}

export function deleteMilestone({ projectId, milestoneId }) {
  const config = authConfig({
    url: `projects/${projectId}/milestones/${milestoneId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function getUserWallets() {
  // @TODO: api call for wallet data.
  // const { data: { wallets } } = await request({ url: '/wallet/' });
  return wallets;
}

export function getExchangeRate() {
  return request({ url: '/ticker/plg_to_usd/' });
}

export async function search({ query, page, perPage }) {
  const { data } = await request({
    url: '/projects/search/',
    method: 'GET',
    params: { query, page, per_page: perPage },
  });
  return processProjectsData(data);
}

export function getProjectFAQ(projectId) {
  const config = authConfig({
    url: `projects/${projectId}/faq/`,
  });
  return request(config);
}

export function addFAQItem({ projectId, question, answer }) {
  const config = authConfig({
    url: `projects/${projectId}/faq/`,
    method: 'POST',
    data: { question, answer },
  });
  return request(config);
}

export function updateFAQItem({ projectId, faqItemId, question, answer }) {
  const config = authConfig({
    url: `projects/${projectId}/faq/${faqItemId}/`,
    method: 'PATCH',
    data: { question, answer },
  });
  return request(config);
}

export function deleteFAQItem({ projectId, faqItemId }) {
  const config = authConfig({
    url: `projects/${projectId}/faq/${faqItemId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function addBonus(projectId, data) {
  const config = authConfig({
    url: `projects/${projectId}/bonus/`,
    method: 'POST',
    data,
  });
  return request(config);
}

export function payBonus(projectId, bonusId) {
  const config = authConfig({
    url: `projects/${projectId}/bonus/${bonusId}/`,
    method: 'POST',
  });
  return request(config);
}

export function updateBonus(projectId, bonusId, data) {
  const config = authConfig({
    url: `projects/${projectId}/bonus/${bonusId}/`,
    method: 'PATCH',
    data,
  });
  return request(config);
}

export function updateProfileImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  const config = authConfig({
    url: '/users/me/image/',
    data: formData,
    method: 'PATCH',
  });
  return request(config);
}

export function getUserNotifications({ page, perPage, types, resource, resourceIds }) {
  const config = authConfig({
    url: '/users/me/notifications/',
    method: 'POST',
    data: {
      page,
      per_page: perPage,
      object_types: types,
      resource,
      resource_ids: resourceIds,
    },
  });
  return request(config);
}

export function markNotificationRead(notificationId) {
  const config = authConfig({
    url: `/users/me/notifications/${notificationId}/read/`,
    method: 'PATCH',
  });
  return request(config);
}

export function markAllNotificationsRead() {
  const config = authConfig({
    url: '/users/me/notifications/read-all/',
    method: 'PATCH',
  });
  return request(config);
}

export function getUserPaymentMethods() {
  const config = authConfig({
    url: '/users/me/payment-methods/',
    method: 'GET',
  });
  return request(config);
}

export function setUserDefaultPaymentMethod({ id }) {
  const config = authConfig({
    url: '/users/me/payment-methods/default/',
    method: 'PATCH',
    data: { id },
  });
  return request(config);
}

export function createUserPaymentMethod(paymentData) {
  const config = authConfig({
    url: '/users/me/payment-methods/',
    method: 'POST',
    data: paymentData,
  });
  return request(config);
}

export function deletePaymentMethod({ id }) {
  const config = authConfig({
    url: `/users/me/payment-methods/${id}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function getCommentReplies({ commentId, sort }) {
  const config = authConfig({
    url: `/comments/${commentId}/replies/`,
    method: 'GET',
    params: { sort },
  });
  return request(config);
}

export function getProjectCommentPermission({ projectId }) {
  const config = authConfig({
    url: `/projects/${projectId}/comments/user-permission/`,
    method: 'GET',
  });
  return request(config);
}

export function addProjectComment({ projectId, parentId, text }) {
  const config = authConfig({
    url: `/projects/${projectId}/comments/${parentId}/`,
    method: 'POST',
    data: { text },
  });
  return request(config);
}

export function voteComment({ projectId, commentId, vote }) {
  const config = authConfig({
    url: `/projects/${projectId}/comments/${commentId}/vote/`,
    method: 'PATCH',
    data: { vote },
  });
  return request(config);
}

export function editComment({ commentId, text }) {
  const config = authConfig({
    url: `/comments/${commentId}/`,
    method: 'PATCH',
    data: { text },
  });
  return request(config);
}

export function deleteComment({ commentId }) {
  const config = authConfig({
    url: `/comments/${commentId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function getReferralLinks(projectId) {
  const config = authConfig({
    url: `/projects/${projectId}/insights/referral-links/`,
    method: 'GET',
  });
  return request(config);
}

export function createReferralLink({ projectId, name }) {
  const config = authConfig({
    url: `/projects/${projectId}/insights/referral-links/`,
    method: 'POST',
    data: { name },
  });
  return request(config);
}

export function updateReferralLink({ projectId, linkId, name }) {
  const config = authConfig({
    url: `/projects/${projectId}/insights/referral-links/${linkId}/`,
    method: 'PATCH',
    data: { name },
  });
  return request(config);
}

export function deleteReferralLink({ projectId, linkId }) {
  const config = authConfig({
    url: `/projects/${projectId}/insights/referral-links/${linkId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function getProjectUpdate({ projectId, updateId }) {
  const config = authConfig({
    url: `/projects/${projectId}/updates/${updateId}/`,
    method: 'GET',
  });
  return request(config);
}

export function createProjectUpdate(info) {
  const {
    projectId,
    name,
    description,
  } = info;
  const config = authConfig({
    url: `/projects/${projectId}/updates/`,
    method: 'POST',
    data: { name, description, public: info.public },
  });
  return request(config);
}

export function updateProjectUpdate(info) {
  const {
    updateId,
    projectId,
    name,
    description,
    draft,
  } = info;

  const config = authConfig({
    url: `/projects/${projectId}/updates/${updateId}/`,
    method: 'PATCH',
    data: { name, description, public: info.public, draft },
  });
  return request(config);
}

export function deleteProjectUpdate({ projectId, updateId }) {
  const config = authConfig({
    url: `/projects/${projectId}/updates/${updateId}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function getPaymentMethods() {
  const config = authConfig({
    url: '/users/me/payment-methods/',
    method: 'GET',
  });
  return request(config);
}

function createAddress(type, address) {
  const config = authConfig({
    url: `/users/me/${type}/`,
    method: 'POST',
    data: address,
  });
  return request(config);
}

export function createShippingAddress(address) {
  return createAddress('shipping-addresses', address);
}

export function updateShippingAddress(id, address) {
  const config = authConfig({
    url: `/users/me/shipping-addresses/${id}/`,
    method: 'PATCH',
    data: address,
  });
  return request(config);
}

function removeAddress(type, id) {
  const config = authConfig({
    url: `/users/me/${type}/${id}/`,
    method: 'DELETE',
  });
  return request(config);
}

export function removeShippingAddress(id) {
  return removeAddress('shipping-addresses', id);
}

export function createPaymentMethod(method) {
  const config = authConfig({
    url: '/users/me/payment-methods/',
    method: 'POST',
    data: method,
  });
  return request(config);
}

export function updatePledgePayment({ projectId, pledgeId, paymentId }) {
  const config = authConfig({
    url: `/projects/${projectId}/pledges/me/${pledgeId}/payments/`,
    method: 'PATCH',
    data: { id: paymentId },
  });
  return request(config);
}

export function startKYC() {
  const config = authConfig({
    url: '/users/me/kyc/',
    method: 'GET',
  });
  return request(config);
}

export function getFundingAnalytics(projectId) {
  const config = authConfig({
    url: `/projects/${projectId}/insights/funding/`,
    method: 'GET',
  });
  return request(config);
}

export default request;
