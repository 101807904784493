<template>
<div class="settings-verification">
  <Section
    :info="$t('settings.verification.top')"
    sectionClassName="page-header-section"
  />
  <Section :info="$t('settings.verification.email')">
    <div v-if="profile.email" class="settings-email">
      {{ profile.email }}
    </div>
    <button v-if="!profile.email_confirmed" class="settings-button">
      {{ $t('settings.verification.email.button') }}
    </button>
  </Section>
  <Section :info="$t('settings.verification.kyc')">
    <span
      v-if="isVerified"
      class="settings-kyc-verified"
    >
      {{ $t('settings.verification.kyc.verified') }}
    </span>
    <button
      :class="['settings-button', { [kycButton.color]: kycButton.color }]"
      :disabled="shouldButtonDisabled"
      @click="showKYCModal = true"
    >
      {{ $t(`settings.verification.kyc.buttons.${kycButton.message}`) }}
    </button>
  </Section>
  <Section :info="$t('settings.verification.address_verification')">
    <button class="settings-button">
      {{ $t('settings.verification.address_verification.button') }}
    </button>
  </Section>
  <KYCModal
    v-if="showKYCModal"
    :status="profile.kyc_status"
    @close="showKYCModal = false"
  />
</div>
</template>

<script>
import Section from '@/components/widget/Section.vue';
import KYCModal from '@/components/user_private/KYCModal.vue';
import { USER_REQUEST } from '@/store/actions';
import { KYCStatus } from '@/utils/constants';

export default {
  name: 'settings-verification',
  components: {
    Section,
    KYCModal,
  },
  data() {
    return {
      personalInfo: {},
      showKYCModal: false,
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile || {};
    },
    isVerified() {
      return this.profile.kyc_status === KYCStatus.KYC_COMPLETE;
    },
    shouldButtonDisabled() {
      const disabledStatus = [KYCStatus.KYC_PENDING, KYCStatus.KYC_FAILED];
      return disabledStatus.includes(this.profile.kyc_status);
    },
    kycButton() {
      const status = this.profile.kyc_status;
      switch(status) {
      case KYCStatus.KYC_PENDING:
        return { message: status, color: 'grey' };

      case KYCStatus.KYC_COMPLETE:
      case KYCStatus.KYC_FAILED:
        return { message: status, color: 'red' };

      default:
        return { message: KYCStatus.KYC_NOT_DONE, color: '' };
      }
    },
  },
  mounted() {
    this.$store.dispatch(USER_REQUEST);
  },
};
</script>

<style lang="scss">
@import 'widgets';

.settings-verification {
  .page-header-section h4 {
    @include h3;
    padding-bottom: 22px;
  }

  .settings-kyc-verified,
  .settings-email {
    padding-right: 24px;
    line-height: 38px;
  }

  .settings-kyc-verified {
    @include h5;
    color: $main-green;
  }

  .contact-form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .form-wrap { width: 100%; }
    .submit {
      @include button($main-black, small);
      width: 261px;
      padding: 0;
    }
  }
}
@media (max-width: $tablet-width) {
  .settings-verification {
    .section-right {
      flex-wrap: wrap;
    }
    .settings-email {
      padding: 0;
    }
  }
}
@media (max-width: $mobile-width) {
  .settings-verification .settings-email {
    text-align: center;
  }
}
</style>
