<template>
<router-link
  class="avatar"
  :class="{ 'link-enabled': (linkEnabled && link) }"
  :to="link || {}"
  :event="(linkEnabled && link) ? 'click' : ''"
>
  <div class="avatar-img-wrap">
    <div
      class="avatar-img"
      :style="{ 'background-image': `url(${avatar})` }"
    />
    <div v-if="tags.length" class="avatar-tags">
      <div
        v-for="(t, index) in tags"
        :key="index"
        class="avatar-tags-item"
      >
        {{ t }}
      </div>
    </div>
    <div v-else-if="tag" class="avatar-tags">
      <div class="avatar-tags-item">
        {{ tag }}
      </div>
    </div>
    <div v-else-if="isCreator" class="avatar-tags">
      <div class="avatar-tags-item">
        {{ $t('creator') }}
      </div>
    </div>
  </div>
  <span v-if="user && showName" class="avatar-name">{{ user.name }}</span>
  <slot />
</router-link>
</template>

<script>
export default {
  name: 'avatar',
  props: {
    user: {
      type: Object,
      default: null,
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
    tag: {
      type: String,
      default: null,
    },
    isCreator: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    linkEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    avatar() {
      return this.$options.filters.avatar(this.user);
    },
    link() {
      if(this.linkEnabled && this.user && this.user.id) {
        return { name: 'user-public', params: { id: this.user.id } };
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.avatar {
  display: inline-flex;
  align-content: center;
  flex-direction: row;

  .avatar-img-wrap {
    .avatar-img {
      background-position: center;
      background-size: cover;
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
    .avatar-tags {
      margin-top: 8px;
      .avatar-tags--item {
        padding: 4px 5px;
        background: #37a299;
        color: white;
        font-family: $font-normal;
        font-weight: 700;
        line-height: 12px;
        font-size: 10px;
        text-align: center;
        margin-bottom: 5px;
      }
    }
  }

  .avatar-name {
    padding-top: 3px;
    margin-left: 8px;
    @include h5;
  }
  &.link-enabled:hover .avatar-name {
    color: $main-blue;
  }
}
</style>

