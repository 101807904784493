<template>
<div class="header-preview">
  <div class="header-preview-spacer" />
  <div class="header-preview-wrap">
    <div class="container header-preview-bar">
      <div class="preview-side">
        <router-link v-if="previewCode" :to="returnPath">
          <Arrow :clickable="true" :back="true" color="#fff" />
          {{ $t('project.preview.back_to_edit') }}
        </router-link>
      </div>
      <div class="previewing-text">
        <Lock color="#fff" />
        {{ $t('project.preview.previewing') }}
      </div>
      <div class="preview-side">
        <CopyButton
          v-if="previewCode"
          :copyText="previewUrl"
          :text="$t('project.preview.copy_private_link')"
          styleClass="blue-button"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Arrow from '@/components/svg/Arrow.vue';
import CopyButton from '@/components/widget/CopyButton.vue';
import Lock from '@/components/svg/Lock.vue';

export default {
  name: 'preview-header',
  components: {
    Arrow,
    CopyButton,
    Lock,
  },
  computed: {
    previewCode() {
      return this.$store.getters.previewCode;
    },
    previewUrl() {
      return `${window.location.origin}/project/preview/${this.previewCode}`;
    },
    projectId() {
      return this.$store.state.project.id;
    },
    returnPath() {
      const { prevRoute } = this.$store.getters;
      if(prevRoute) {
        return prevRoute;
      }
      return { name: 'dashboard', params: { id: this.projectId } };
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.header-preview {
  width: 100%;
  z-index: 2000;

  .header-preview-spacer, .header-preview-wrap {
    height: 64px;
    width: 100%;
  }
  .header-preview-wrap {
    position: fixed;
    background-color: $main-blue;
    top: 0;
  }
  .header-preview-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: 4px;
  }

  .previewing-text {
    @include h4;
    color: $white;
    svg {
      position: relative;
      margin-right: 6px;
      top: 2px;
    }
  }
  .preview-side {
    @include h5;
    color: white;

    a {
      color: white;
    }
  }
}
</style>
