<template>
<svg
  width="16"
  height="19"
  viewBox="0 0 16 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <!-- eslint-disable -->
  <path
    :fill="color"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.0004 7.52083H13.4004V5.34375C13.4004 2.39248 10.9827 0 8.00039 0C5.01805 0 2.60039 2.39248 2.60039 5.34375V7.52083H2.00039C1.11674 7.52083 0.400391 8.22972 0.400391 9.10417V17.4167C0.400391 18.2911 1.11674 19 2.00039 19H14.0004C14.884 19 15.6004 18.2911 15.6004 17.4167V9.10417C15.6004 8.22972 14.884 7.52083 14.0004 7.52083ZM6.40039 12.2708C6.39688 11.5016 6.95263 10.8411 7.71778 10.7052C8.48294 10.5694 9.23607 10.9974 9.50305 11.7199C9.77004 12.4424 9.47361 13.2502 8.80039 13.6349V15.4375C8.80039 15.8747 8.44222 16.2292 8.00039 16.2292C7.55856 16.2292 7.20039 15.8747 7.20039 15.4375V13.6349C6.70686 13.354 6.40199 12.8342 6.40039 12.2708ZM8.00039 1.97917C6.12262 1.97917 4.60039 3.48554 4.60039 5.34375V7.125C4.60039 7.34361 4.77948 7.52083 5.00039 7.52083H11.0004C11.2213 7.52083 11.4004 7.34361 11.4004 7.125V5.34375C11.4004 3.48554 9.87816 1.97917 8.00039 1.97917Z"
  />
  <!-- eslint-enable -->
</svg>
</template>

<script>

export default {
  name: 'lock',
  props: {
    color: {
      type: String,
      default: '#2E40EA',
    },
  },
};
</script>
