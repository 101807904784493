<template>
<Modal
  :cancelable="true"
  :cancelByClickingOutside="false"
  :title="$t('settings.payment.create_payment_title')"
  :submitText="$t('settings.payment.buttons.add_card')"
  :animate="animate"
  class="payment-method-modal"
  @submit="handleSubmit"
  @cancel="toggleModal('convert')"
>
  <template #content>
    <div class="payment-method-create-modal-form-wrap">
      <div class="payment-content">
        <CreditCardForm
          ref="cardForm"
          v-model="card"
          :hasCVV="false"
        />
      </div>
      <div class="billing-content">
        <h5>{{ $t('settings.payment.billing_address') }}</h5>
        <div class="form-control">
          <AddressForm
            ref="addressForm"
            v-model="card.billing_address"
            :showPhone="true"
            :requirePhone="false"
            formName="address"
          />
        </div>
      </div>
      <div v-if="errorMessage" class="error">
        <span>{{ errorMessage }}</span>
      </div>
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import CreditCardForm from '@/components/forms/CreditCardForm.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import { createPaymentMethod } from '@/utils/api';
import { formatCardAPI } from '@/utils/objectUtils';

export default {
  name: 'payment-method-create-modal',
  components: {
    AddressForm,
    CreditCardForm,
    Modal,
  },
  props: {
    toggleModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      card: {},
      errorMessage: null,
      animate: false,
    };
  },
  methods: {
    async handleSubmit() {
      const addressValid = this.$refs.addressForm.validateAll();
      const cardValid = this.$refs.cardForm.validateAll();
      if(!(addressValid && cardValid)) {
        return;
      }
      this.animate = true;
      try {
        const card = formatCardAPI(this.card, true);
        const { data: { payment_method: method } } = await createPaymentMethod(card);
        this.$emit('payment-method-created', method);
      } catch(error) {
        console.log(error);
      }
      this.animate = false;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';
.payment-method-modal {
  .modal-top {
    height: 24px;
  }
  .modal-header {
    @include h3;
    text-align: center;
    margin: 16px 0 32px;
  }

  .payment-method-create-modal-form-wrap {
    padding-bottom: 30px;
  }

  .p-input-wrap {
    & > input {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .credit-card-form {
    .card-row2 {
      .card-number {
        width: 70%;
        padding-right: 16px;
      }
      .card-expire {
        width: 30%;
        padding-right: 0;
      }
    }
    .zip {
      width: 46%;
    }

    .country {
      width: 44%;
    }
    .state {
      width: 30%;
    }
    .postal-code {
      width: 22%;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    > * {
      width: 100% !important;
    }
  }
}

</style>
