<template>
<div class="payments">
  <div class="page-help">
    <p class="p_large">
      {{ $t('settings.payment.help') }}
    </p>
  </div>
  <div class="page-content">
    <h3 class="page-title">
      {{ $t('settings.payment.title') }}
    </h3>
    <div class="add-payment-button" @click="showAddPaymentModal = !showAddPaymentModal">
      {{ $t('settings.payment.buttons.add_card') }}
    </div>
    <div class="payments-list">
      <PaymentItem
        v-for="item in profile.payment_methods"
        :key="item.id"
        :card="item"
        @set-default="(card) => setDefaultPayment(card)"
        @remove="(card) => removePaymentItem(card)"
      />
    </div>
  </div>
  <PaymentMethodModal
    v-if="showAddPaymentModal"
    :toggleModal="toggleModal"
    @payment-method-created="paymentMethodCreated"
  />
</div>
</template>

<script>
import PaymentMethodModal from '@/components/user_private/PaymentMethodModal.vue';
import * as api from '@/utils/api';
import { USER_SUCCESS } from '@/store/actions';

import PaymentItem from './PaymentItem';

export default {
  name: 'payment',
  components: {
    PaymentMethodModal,
    PaymentItem,
  },
  data() {
    return {
      showAddPaymentModal: false,
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile || {};
    },
  },
  methods: {
    async removePaymentItem(item) {
      try {
        await api.deletePaymentMethod(item);
        const newPaymentMethods = this.profile.payment_methods.filter(e => e.id !== item.id);
        this.setPaymentMethods(newPaymentMethods);
      } catch(e) {
        console.log(e);
      }
    },
    async setDefaultPayment(item) {
      try {
        await api.setUserDefaultPaymentMethod(item);
        const newPaymentMethods = this.profile.payment_methods.map(element => ({
          ...element,
          default: element.id === item.id,
        }));
        this.setPaymentMethods(newPaymentMethods);
      } catch(e) {
        console.log(e);
      }
    },
    paymentMethodCreated(method) {
      const methods = [...this.profile.payment_methods, method];
      this.setPaymentMethods(methods);
      this.showAddPaymentModal = false;
    },
    setPaymentMethods(methods) {
      this.$store.commit(USER_SUCCESS, {
        ...this.profile,
        payment_methods: methods,
      });
    },
    toggleModal() {
      this.showAddPaymentModal = !this.showAddPaymentModal;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.payments {
  display: flex;
  flex-direction: row-reverse;

  .page-help {
    width: 40%;
    margin: 56px 0 24px 25px;
    p {
      margin: 0;
      padding: 24px;
      background-color: $blue-info;
    }
  }
  .page-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
    margin: 40px 25px 24px 0;

    .page-title {
      margin: 16px 0;
    }

    .add-payment-button {
      @include button($main-black, large);
      min-width: 155px;
      margin: 16px 0;
    }

    .payments-list {
      width: 100%;
    }
  }

  hr {
    margin-bottom: 30px;
  }

  @media (max-width: $tablet-width) {
    flex-direction: column;

    .page-help {
      width: 100%;
      margin: 24px 0 0 0;
    }
    .page-content {
      width: 100%;
      margin: 24px 0;
    }
  }

  @media (max-width: $mobile-width) {
    .add-payment-button {
      width: 100%;
    }
  }
}
</style>
