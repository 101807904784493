import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createLogger from 'vuex/dist/logger';
import { isProduction, loggingEnabled } from '@/utils/config';
import categories from './modules/categories';
import referral from './modules/referral';
import cart from './modules/cart';
import project from './modules/project';
import searchResults from './modules/searchResults';
import ticker from './modules/ticker';
import user from './modules/user';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const plugins = [createPersistedState()];
if(!isProduction && loggingEnabled) {
  plugins.push(createLogger());
}

export default new Vuex.Store({
  modules: {
    categories,
    referral,
    cart,
    project,
    searchResults,
    ticker,
    user,
  },
  plugins,
  strict: debug,
});
