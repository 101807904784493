<template>
<div class="user-private-content container">
  <About
    :profile="profile"
    :projectCount="projectCount"
    :pledgedProjectCount="pledgedProjectCount"
  />
  <hr>
  <Projects
    :profile="profile"
    :createdProjects="createdProjects"
    :pledgedProjects="pledgedProjects"
    :isPrivate="true"
  />
</div>
</template>

<script>
import Projects from '@/components/user_public/Projects.vue';
import About from '@/components/user_private/About.vue';
import { USER_REQUEST } from '@/store/actions';
import * as api from '@/utils/api';

export default {
  name: 'user-private',
  components: {
    Projects,
    About,
  },
  data() {
    return {
      campaignsCount: 0,
      backedCount: 0,
      createdProjects: [],
      createdProjectsPagination: {},
      pledgedProjects: [],
      pledgedProjectsPagination: {},
    };
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    isProfileLoaded() {
      return this.$store.getters.isProfileLoaded;
    },
    userId() {
      if(!this.profile) {
        return null;
      }
      return this.profile.id;
    },
    projectCount() {
      return this.createdProjectsPagination.total_count || 0;
    },
    pledgedProjectCount() {
      return this.pledgedProjectsPagination.total_count || 0;
    },
  },
  methods: {
    async getCreatedProjects() {
      const { userId } = this;
      if(userId) {
        const { projects, pagination } = await api.getCreatedProjects({ userId });
        this.createdProjects = projects;
        this.createdProjectsPagination = pagination;
      }
    },
    async getPledgedProjects() {
      const { userId } = this;
      if(userId) {
        const { projects, pagination } = await api.getPledgedProjects({ userId });
        this.pledgedProjects = projects;
        this.pledgedProjectsPagination = pagination;
      }
    },
  },
  async mounted() {
    if(!this.isProfileLoaded) {
      await this.$store.dispatch(USER_REQUEST);
    }
    await this.getCreatedProjects();
    await this.getPledgedProjects();
  },
};
</script>

<style lang="scss">
.user-private-content.container {
  @media (min-width: $tablet-width-large) {
    padding: 0 80px;
    hr {
      margin: 48px 0 0;
    }
  }
}
</style>
