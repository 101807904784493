<template>
<AuthLayout>
  <template #content>
    <form class="login-form login-modal-form forgot-password-contain" @submit="handleSubmit">
      <div class="login-form-title">
        {{ $t('auth.reset_password') }}
      </div>
      <div class="forgot-password-subtitle">
        {{ message }}
      </div>
      <div v-if="!success">
        <TitledInput
          v-model="password"
          name="new-password"
          class="form-input"
          type="password"
          :placeholder="$t('auth.placeholders.new_password')"
          :showError="password.length >= 1 && !passwordValid"
          :errorMessage="errorPassword"
          :maxLength="40"
          @input="clearErrors"
        />
        <TitledInput
          v-model="confirmPassword"
          name="confirm-password"
          class="form-input"
          type="password"
          :placeholder="$t('auth.placeholders.confirm_password')"
          :errorMessage="errorConfirm"
          :maxLength="40"
          @input="clearErrors"
        />
        <PasswordValidate
          v-if="password.length > 0"
          :password="password"
          @password-validation="getPasswordStatus"
        />
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
        <button
          type="submit"
          class="login-modal-button"
          :disabled="errorMessage || errorPassword || errorConfirm"
        >
          <div>{{ $t('confirm') }}</div>
          <Arrow color="white" />
        </button>
      </div>
      <div
        class="back-button"
        @click="$router.push({ name: 'login' })"
      >
        {{ $t('auth.button_text.back_to_login') }}
      </div>
    </form>
  </template>
</AuthLayout>
</template>

<script>
import AuthLayout from '@/components/auth/AuthLayout.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import Arrow from '@/components/svg/Arrow.vue';
import PasswordValidate from '@/components/widget/PasswordValidate.vue';
import { resetPasswordConfirmation } from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';

export default {
  name: 'reset-password',
  components: {
    AuthLayout,
    TitledInput,
    Arrow,
    PasswordValidate,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      errorMessage: null,
      errorPassword: null,
      errorConfirm: null,
      passwordValid: false,
      success: false,
    };
  },
  computed: {
    message() {
      return this.success ? this.$t('auth.reset_password_success') : this.$t('auth.enter_password');
    },
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.clearErrors();
      const { password, confirmPassword } = this;
      if(!password || password === '') {
        this.errorPassword = this.$t('errors.PASSWORD_MISSING');
      } else if(!confirmPassword || confirmPassword === '') {
        this.errorConfirm = this.$t('errors.PASSWORD_CONFIRM');
      } else if(password !== confirmPassword) {
        this.errorMessage = this.$t('errors.PASSWORD_MISMATCH');
      } else {
        try {
          const { code } = this.$route.query;
          await resetPasswordConfirmation(code, this.password);
          this.success = true;
        } catch(error) {
          this.errorMessage = this.$t(fieldErrorKey(error.errors));
        }
      }
    },
    clearErrors() {
      this.errorPassword = null;
      this.errorConfirm = null;
      this.errorMessage = null;
    },
    getPasswordStatus(status) {
      this.passwordValid = status;
    },
  },
};
</script>
