import { requestSocialAuth, socialLogin } from '@/utils/api';
import { SET_USER_SIGNUP, USER_REQUEST } from '@/store/actions';

export default {
  methods: {
    async socialSignup(type) {
      try {
        // If social login fails, assume it's because they're not logged in to social account.
        // Social auth window will prompt to login to social account.
        const resp = await requestSocialAuth(type);
        const { redirect_url: redirectURL, new_user: newUser } = resp.data;
        await this.$store.dispatch(SET_USER_SIGNUP, newUser);
        window.location = redirectURL;
      } catch(e2) {
        return false;
      }
      return true;
    },
    async socialLoginSignup(type) {
      try {
        const { data: { new_user: newUser } } = await socialLogin(type);
        this.socialError = null;
        await this.$store.dispatch(SET_USER_SIGNUP, newUser);
        await this.$store.dispatch(USER_REQUEST, true);
        this.redirectUser();
      } catch(e1) {
        console.log(e1);
        return this.socialSignup(type);
      }
      return true;
    },
  },
};
