import moment from 'moment';
import removeMd from 'remove-markdown';

import isEmail from 'validator/lib/isEmail';
import matches from 'validator/lib/matches';
import isPostalCode from 'validator/lib/isPostalCode';
import isCreditCard from 'validator/lib/isCreditCard';

export const validateEmail = isEmail;

export const validateCreditCard = isCreditCard;

export const validatePostalCode = str => isPostalCode(str, 'any');

export const validateWords = str => matches(str, /^[a-zA-Z0-9 ]*$/);

export const validateCardName = str => matches(str, /^[a-zA-Z. '-]*$/);

export const validateDate = (str, format) => moment(str, format, true).isValid();

export const validateId = value => (Number.isInteger(value) && (value > 0));

export const validateGAnalytics = str => (/\b[A-Z]{2,3}-[A-Z0-9]{4,10}\b/i).test(str.toString());

export const validateRange = (input, { min, max } = {}) => {
  if(!Number.isInteger(input)) {
    return false;
  }
  if(typeof min !== 'number' || typeof max !== 'number') {
    return false;
  }
  return (input >= min && input <= max);
};

export const formatWithCommas = amount => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const removeMarkdown = md => removeMd(md);

export const validateLength = (input, conditions = { minLength: 0, maxLength: 0 }) => {
  if(input) {
    const { minLength, maxLength } = conditions;
    if(maxLength > 0) {
      return input.length >= minLength && input.length <= maxLength;
    }
    return input.length >= minLength;
  }
  return false;
};

export const validatePhone = str => (
  validateLength(str || '', { minLength: 5, maxLength: 14 })
);

export const validateLink = (link) => {
  // eslint-disable-next-line
  const re = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(String(link));
};

export const validateYouTubeUrl = (url) => {
  if(!url) {
    return false;
  }
  if(url.length === 11) {
    return url;
  }
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
  const match = url.match(regExp);
  if(match && match[2].length === 11) {
    return match[2];
  }
  return false;
};
