<template>
<div
  class="p-checkbox"
  :class="{ disabled }"
  @click="handleCheck($event, !item.checked)"
>
  <input
    type="checkbox"
    :value="item.label"
    :checked="item.checked"
    :disabled="disabled"
  >
  <span class="checkmark" :class="internalCheckedClass" />
  <span
    v-if="item.label"
    class="checkbox-text"
    v-html="item.label"
  />
</div>
</template>

<script>
export default {
  name: 'checkbox',
  props: {
    // Format: `{ label: <string>, checked: <boolean> }`
    item: {
      type: Object,
      default: () => {},
    },
    // Optional override of checkbox class
    checkedClass: {
      type: String,
      default: null,
      validator: value => ['partial', 'checked', '', null].indexOf(value) !== -1,
    },
    onCheck: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    internalCheckedClass() {
      if(this.checkedClass === null) {
        return this.item.checked ? 'checked' : '';
      }
      return this.checkedClass;
    },
  },
  methods: {
    handleCheck(event, checked) {
      if(!this.disabled && (event.target.nodeName !== 'A')) {
        this.onCheck(checked);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.p-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 8px 16px 16px 0;

  &.disabled {
    cursor: not-allowed;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox-text {
    @include h5;

    line-height: unset;
    padding-top: 5px; // small hack because of font type
  }

  .checkmark {
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid $grey70;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    &.partial {
      background-color: $main-blue;
      &::after {
        content: "";
        display: block;
        width: 6px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
      }
    }
    &.checked {
      background-color: $main-blue;
      border: 1px solid transparent;

      &::after {
        content: "";
        display: block;
        width: 3px;
        height: 6px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: translate(0, -1px) rotate(45deg);
      }
    }
  }
}
</style>
