<template>
<div id="address-form" class="form-wrap">
  <TitledInput
    :value="value.line1"
    autocomplete="address-line1"
    :title="$t('form.address.labels.line1')"
    :placeholder="$t('form.address.placeholders.line1')"
    :errorMessage="errors.line1"
    @input="handleFormInput('line1', $event)"
  />
  <div class="columns">
    <TitledInput
      :class="{ line2: true, wide: showPhone }"
      :value="value.line2"
      autocomplete="address-line2"
      :title="$t('form.address.labels.line2')"
      :placeholder="$t('form.address.placeholders.line2')"
      :errorMessage="errors.line2"
      @input="handleFormInput('line2', $event)"
    />
    <TitledInput
      v-if="showPhone"
      class="phone"
      :value="value.phone_number"
      autocomplete="tel"
      :title="$t(phoneTitleKey)"
      :placeholder="$t('form.address.placeholders.phone_number')"
      :errorMessage="errors.phone_number"
      regex="[^+0-9]"
      @input="handleFormInput('phone_number', $event)"
    />
    <TitledSelect
      class="country"
      :value="country"
      type="select"
      autocomplete="country"
      :options="CountriesArray"
      label="name"
      :noResult="$t('errors.NO_COUNTRY')"
      :errorMessage="errors.country_code"
      :searchable="true"
      :title="$t('form.address.labels.country_code')"
      :placeholder="$t('form.address.placeholders.country_code')"
      @input="handleFormInput('country_code', $event.alpha3)"
    />
  </div>
  <div class="columns">
    <TitledInput
      class="city"
      :value="value.city"
      autocomplete="address-level2"
      :title="$t('form.address.labels.city')"
      :placeholder="$t('form.address.placeholders.city')"
      :errorMessage="errors.city"
      @input="handleFormInput('city', $event)"
    />
    <TitledInput
      class="state"
      :value="value.state"
      autocomplete="address-level1"
      :title="$t('form.address.labels.state')"
      :placeholder="$t('form.address.placeholders.state')"
      :errorMessage="errors.state"
      @input="handleFormInput('state', $event)"
    />
    <TitledInput
      class="postal"
      :value="value.postal_code"
      autocomplete="postal-code"
      :title="$t('form.address.labels.postal_code')"
      :placeholder="$t('form.address.placeholders.postal_code')"
      :errorMessage="errors.postal_code"
      @input="handleFormInput('postal_code', $event)"
    />
  </div>
</div>
</template>

<script>
import TitledSelect from '@/components/widget/TitledSelect.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import { CountriesArray, CountryCodeMap } from '@/utils/constants';
import {
  validatePhone,
  validatePostalCode,
  validateWords,
} from '@/utils/stringUtils';
import formMixin from '@/mixins/Form';

export default {
  components: {
    TitledSelect,
    TitledInput,
  },
  mixins: [formMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        line1: '',
        line2: '',
        city: '',
        country_code: '',
        postal_code: '',
        state: '',
        phone_number: '',
      }),
    },
    showPhone: Boolean,
    requirePhone: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      CountriesArray,
      rules: {
        phone_number: {
          // Make sure not to require a phone number if there's no field!
          required: this.requirePhone && this.showPhone,
          validator: validatePhone,
        },
        line1: {
          required: true,
        },
        line2: {
          required: false,
        },
        city: {
          required: true,
          validator: validateWords,
        },
        country_code: {
          required: true,
        },
        state: {
          required: true,
          validator: validateWords,
        },
        postal_code: {
          required: true,
          validator: validatePostalCode,
        },
      },
    };
  },
  computed: {
    country() {
      return CountryCodeMap[this.value.country_code];
    },
    phoneTitleKey() {
      if(this.requirePhone) {
        return 'form.address.labels.phone_number';
      }
      return 'form.address.labels.phone_number_optional';
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    > div:not(:last-child) { padding-right: 16px; }
    .city {
      width: 50%;
    }
    .state, .postal {
      width: 25%;
    }
    .country, .phone {
      width: 50%;
    }
    .line2 {
      flex-grow: 1;
      &.wide {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    .columns > div {
      &.country, &.phone, &.line2 {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  @media (max-width: $mobile-width) {
    .columns > div {
      &.city {
        width: 100%;
        padding-right: 0;
      }
      &.postal, &.state {
        width: 50%;
      }
    }
  }
}
</style>
