<template>
<div
  class="user-projects"
  :class="{ 'created-many': createdProjects.length > 1 }"
>
  <div id="created" class="user-projects-created">
    <ProjectSlider
      v-if="createdProjects.length"
      class="projects"
      :title="$t('user.private.projects.created')"
      :projects="createdProjects"
      :showViewAll="false"
      :showPlaceholders="false"
      :toDashboard="isPrivate"
    />
    <div v-else class="user-empty-container">
      <div>
        {{ $t(`user.${messageKey}.projects.no_projects`) }}
      </div>
      <button v-if="isPrivate" @click="$router.push({ path: '/create' })">
        {{ $t('user.private.projects.created_btn') }}
      </button>
    </div>
  </div>
  <div id="backed" class="user-projects-backed">
    <h3 class="backed-title">
      {{ $t('user.private.projects.backed') }}
    </h3>
    <div v-if="pledgedProjects.length > 0" class="backed-projects">
      <ProjectItem
        v-for="(project, index) in backedProjects"
        :key="index"
        :project="project"
        :viewSmall="true"
      />
      <button v-if="tooManyBackedProjects" class="view-all" @click="showUnimplemented = true">
        {{ $t('view_all') }}
      </button>
    </div>
    <div v-else class="user-empty-container">
      <div>
        {{ $t(`user.${messageKey}.projects.no_backed`) }}
      </div>
      <button v-if="isPrivate" @click="$router.push({ name: 'home' })">
        {{ $t('header.explore_projects') }}
      </button>
    </div>
  </div>
  <UnimplementedModal
    v-if="showUnimplemented"
    @close-modal="showUnimplemented = false"
  />
</div>
</template>

<script>
import ProjectItem from '@/components/widget/ProjectItem.vue';
import ProjectSlider from '@/components/home/ProjectSlider.vue';
import UnimplementedModal from '@/components/widget/UnimplementedModal.vue';

export default {
  name: 'projects',
  components: {
    ProjectItem,
    ProjectSlider,
    UnimplementedModal,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
    createdProjects: {
      type: Array,
      default: null,
    },
    pledgedProjects: {
      type: Array,
      default: null,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showUnimplemented: false,
    };
  },
  computed: {
    tooManyBackedProjects() {
      return this.pledgedProjects.length > 6;
    },
    backedProjects() {
      return this.tooManyBackedProjects ? this.pledgedProjects.slice(0, 6) : this.pledgedProjects;
    },
    messageKey() {
      return this.isPrivate ? 'private' : 'public';
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.user-projects {
  box-sizing: border-box;
  padding: 48px 0;
  display: flex;
  flex-wrap: wrap;

  .backed-title {
    margin-bottom: 16px;
  }

  .user-projects-backed {
    flex-grow: 1;

    .backed-projects {
      display: flex;
      flex-wrap: wrap;

      .project-item {
        margin: 0 56px 56px 0;
      }
    }

    .view-all {
      @include button($main-black, full, primary);
    }
  }

  .user-projects-created {
    min-width: 460px;
    margin-bottom: 64px;
    .container {
      padding: 0;
    }
    .project-slider .project-slider-header .header-title {
      @include h3;
    }
  }

  .user-empty-container {
    @include h4;
    background-color: $bg-grey-light;
    color: $grey70;
    text-align: center;
    min-width: 297px;
    padding: 40px 0;
    margin-right: 24px;

    button {
      @include button($main-blue, small, secondary);
      @include button_small_text;
      margin-top: 24px;
    }
  }

  &.created-many {
    flex-wrap: wrap;

    .user-projects-created,
    .user-projects-backed {
      width: 100%;
    }
  }

  @media (max-width: $tablet-width-large) {
    padding: 0 20px;
    .user-projects-created {
      width: 100%;
    }
  }
}
</style>

