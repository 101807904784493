<template>
<div
  v-bind="$attrs"
  :class="['p-button', size, disabled && 'disabled']"
  :style="style"
  @click="click"
>
  <div v-if="animate" class="p-button-animate">
    <div class="bars">
      <div class="bar" /><div class="bar" /><div class="bar" />
    </div>
  </div>
  <div v-else-if="complete" class="p-button-check">
    <Check color="white" />
  </div>
  <div v-else>
    {{ text }}
    <slot />
  </div>
  <div v-if="complete || animate" class="hidden-text">
    {{ text }}
  </div>
</div>
</template>

<script>
import Check from '@/components/svg/Check.vue';

export default {
  name: 'p-button',
  components: {
    Check,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    animate: Boolean,
    complete: Boolean,
    size: {
      type: String,
      default: 'large',
      validator: value => ['small', 'large', 'full'].indexOf(value) !== -1,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    style() {
      const style = this.primary ? {
        'background-color': this.color,
        color: '#fff',
      } : {
        'background-color': `rgba($${this.color}, 0.1)`,
        color: this.color,
      };
      if(this.disabled) {
        delete style['background-color'];
      }
      return style;
    },
  },
  methods: {
    click(e) {
      if(!this.complete && !this.disabled) {
        this.$emit('click', e);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

$bar-height: 15px;
$bar-height-min: 10px;

.p-button {
  @include button($main-black, large);
  min-width: 100px;
  position: relative;

  &.small {
    @include button-small;
  }
  &.full {
    @include button-full;
  }
  &.disabled {
    background-color: $grey;
  }
  .hidden-text {
    visibility: hidden;
  }
  .p-button-check {
    position: absolute;
    svg {
      height: 100%;
      width: 18px;
    }
  }

  .p-button-animate {
    width: 100%;
    height: 100%;
    position: absolute;
    .bars {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .bar {
        width: 5px;
        height: $bar-height;
        background-color: white;
        margin-bottom: 3px;
        &:first-child {
          animation-delay: $speed2;
        }
        &:nth-child(2) {
          animation-delay: $speed3;
        }
        &:not(:last-child) {
          margin-right: 7px;
        }
        animation-name: bounce;
        animation-timing-function: ease;
        animation-duration: $speed5;
        animation-iteration-count: infinite;
      }
    }
  }
}
@keyframes bounce {
  0% {
    height: $bar-height;
  }
  40% {
    height: $bar-height-min;
  }
  60% {
    height: $bar-height-min;
  }
  100% {
    height: $bar-height;
  }
}
</style>
