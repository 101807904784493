import * as api from '@/utils/api';
import { formatProduct } from '@/utils/objectUtils';
import { PledgeStatus, VoteStatus } from '@/utils/constants';

// import { keepAliveInMinutes } from '@/utils/config';
import {
  ADDRESS_BILLING_SET,
  ADDRESS_SHIPPING_SET,
  GET_CART,
  APPLY_COUPON,
  CART_UPDATE_SHOPPING,
  CART_VARIANT_ADJUST,
  CART_PLEDGES_SET,
  PAYMENT_METHOD_SET,
  CART_RESET,
  CART_RESET_ALL,
  PLEDGE_CANCEL,
  MILESTONE_VOTE,
} from '../actions';

/**
 * Basket topology
 * {
 *   variationId: {
 *     id: <integer> Variant ID
 *     name: <string> reward_name & variant_name
 *     quantity: <integer>
 *     priceUsd: <integer> Value in US cents
 *     rewardId: <integer>
 *     imageUrl: <string>
 *   }
 * }
 */

const getInitialState = () => ({
  projects: {},
});

const state = getInitialState();

const projectState = (cartState, projectId) => (
  cartState.projects[projectId] || {}
);

const projectShoppingCart = (cartState, projectId) => {
  const project = (cartState.projects[projectId] || {});
  return (project.pledges || {}).shopping;
};

const projectMilestoneVote = (cartState, projectId) => {
  const project = (cartState.projects[projectId] || {});
  return project.milestoneVote || { status: VoteStatus.NONE, vote_count: 0 };
};

const postCheckout = (cartState, projectId) => {
  const project = (cartState.projects[projectId] || {});
  return (project.pledges || {}).postCheckout || [];
};

const getters = {
  hasShoppingCart: cartState => (projectId) => {
    if(projectId) {
      const cart = projectShoppingCart(cartState, projectId) || {};
      return Object.keys(cart.products || {}).length > 0;
    }
    return null;
  },
  shoppingCart: cartState => (projectId) => {
    if(projectId) {
      return projectShoppingCart(cartState, projectId);
    }
    return null;
  },
  cartStatus: cartState => projectId => projectState(cartState, projectId).status,
  postCheckoutPledges: cartState => projectId => postCheckout(cartState, projectId),
  totalPledgesValue: cartState => (projectId) => {
    if(projectId) {
      const post = postCheckout(cartState, projectId);
      const shopping = projectShoppingCart(cartState, projectId);
      return post.reduce((acc, cur) => acc + cur.total, shopping.total);
    }
    return 0;
  },
  totalCheckedoutValue: cartState => (projectId) => {
    if(projectId) {
      const post = postCheckout(cartState, projectId);
      return post.reduce((acc, cur) => acc + cur.total, 0);
    }
    return 0;
  },
  milestoneVote: cartState => (projectId) => {
    if(projectId) {
      return projectMilestoneVote(cartState, projectId);
    }
    return null;
  },
};

const cartFromPledge = (pledge) => {
  const cart = {
    status: pledge.status,
    subtotal: pledge.sub_total_usd,
    total: pledge.total_usd,
    shippingTotal: pledge.shipping_total,
    discount: pledge.discount,
    updated: pledge.updated,
    name: pledge.user_name,
    email: pledge.user_email,
    shippingAddress: pledge.shipping_address,
    milestoneVote: pledge.milestone_vote || {},
    id: pledge.id,
    products: {},
  };
  (pledge.products || []).forEach((product) => {
    cart.products[product.reward_variant.id] = formatProduct(product);
  });
  const needsShipping = Object.values(cart.products).some(product => product.needsShipping);
  cart.needsShipping = needsShipping;
  return cart;
};

const actions = {
  [GET_CART]: async (context, projectId) => {
    // Nuke cart state
    context.commit(CART_RESET, projectId);
    const pledges = await api.getPledges(projectId);
    const pledgesDict = {
      shopping: {},
      postCheckout: [],
    };
    pledges.forEach((pledge) => {
      const cart = cartFromPledge(pledge);
      if(pledge.status === PledgeStatus.SHOPPING) {
        pledgesDict.shopping = cart;
      } else {
        pledgesDict.postCheckout.push(cart);
      }
    });
    // Override cart with response from server as source of truth
    context.commit(CART_PLEDGES_SET, { projectId, pledges: pledgesDict });
  },
  [APPLY_COUPON]: async (context, { projectId, couponCode }) => {
    const { data: pledge } = await api.pledgeApplyCoupon(projectId, couponCode);
    context.commit(CART_UPDATE_SHOPPING, { projectId, pledge });
  },
  [CART_VARIANT_ADJUST]: async (context, { projectId, variantId, newQuantity }) => {
    const payload = { projectId, variantId, quantity: newQuantity };
    const pledge = await api.setPledgeItem(payload);
    context.commit(CART_UPDATE_SHOPPING, { projectId, pledge });
  },
  [PLEDGE_CANCEL]: async (context, { projectId, pledgeId }) => {
    await api.pledgeCancel(projectId, pledgeId);
    await context.dispatch(GET_CART, projectId);
  },
  [MILESTONE_VOTE]: async (context, { projectId, refund }) => {
    await api.milestoneVote(projectId, refund);
    context.commit(MILESTONE_VOTE, { refund });
  },
};

const mutations = {
  [ADDRESS_BILLING_SET]: (cartState, billingAddressId) => {
    cartState.billingAddressId = billingAddressId;
  },
  [ADDRESS_SHIPPING_SET]: (cartState, shippingAddressId) => {
    cartState.shippingAddressId = shippingAddressId;
  },
  [CART_PLEDGES_SET]: (cartState, { projectId, pledges }) => {
    cartState.projects = {
      ...cartState.projects,
      [projectId]: {
        ...projectState(cartState, projectId),
        pledges,
      },
    };
  },
  [MILESTONE_VOTE]: (cartState, { projectId, refund }) => {
    const prevVote = cartState.projects[projectId].milestoneVote;
    cartState.projects[projectId].milestoneVote = {
      vote: refund,
      vote_count: (prevVote || 0) + 1,
      status: VoteStatus.PENDING,
    };
  },
  [CART_UPDATE_SHOPPING]: (cartState, { projectId, pledge }) => {
    const project = projectState(cartState, projectId);
    const pledges = project.pledges || {};
    cartState.projects[projectId] = {
      ...project,
      pledges: {
        ...pledges,
        shopping: cartFromPledge(pledge),
      },
    };
  },
  [PAYMENT_METHOD_SET]: (cartState, paymentMethodId) => {
    cartState.paymentMethodId = paymentMethodId;
  },
  [CART_RESET]: (cartState, projectId) => {
    cartState.projects[projectId] = null;
  },
  [CART_RESET_ALL]: (cartState) => {
    cartState.projects = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
