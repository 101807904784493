<template>
<div class="p-input-wrap">
  <TheMask
    v-if="mask"
    v-bind="commonProps"
    :mask="mask"
    :masked="!!mask"
    :value.prop="convertedValue"
    :autocomplete="autocomplete"
    @input.native="handleInput"
    @focusout.native="$emit('focusout', $event.target.value)"
    @keydown.native="handleKeydown"
  />
  <component
    :is="rows ? 'textarea' : 'input'"
    v-else
    v-bind="commonProps"
    :rows="rows"
    :autocomplete="autocomplete"
    :value.prop="convertedValue"
    :step="step"
    :type="inputType"
    @input="handleInput"
    @focusout="$emit('focusout', $event.target.value)"
    @keydown="handleKeydown"
    @keyup.enter="$emit('handle-enter')"
  />
  <label
    v-if="title"
    class="p-input-title"
    :for="`input${_uid}`"
    :class="{ 'active': (placeholder || (value || value === 0)) }"
  >
    {{ title }}
  </label>
  <span v-if="prefix" class="prefix">{{ prefix }}</span>
  <span v-if="suffix" class="suffix">{{ suffix }}</span>
  <img
    v-if="hasIcon"
    :src="require('@/static/img/icons/ic_dollar.png')"
    class="input-icon input-icon-dollar icon-size-small"
  >
  <img
    v-if="type === 'password'"
    :src=" require(showPasswordFlag ? '@/static/img/icons/ic_eye.png' : '@/static/img/icons/ic_hidden.png')"
    class="input-icon input-icon-eye icon-size-small"
    @click="togglePassword"
  >
  <ErrorMessage
    :errorMessage="errorMessage"
    :errorSubMessage="errorSubMessage"
  />
</div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import Info from '@/components/svg/Info.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';

export default {
  name: 'p-input',
  components: {
    Info,
    TheMask,
    ErrorMessage,
  },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    step: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    errorSubMessage: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    showError: Boolean,
    inputClass: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    mask: {
      type: [String, Array],
      default: '',
    },
    regex: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showPasswordFlag: false,
    };
  },
  computed: {
    inputType() {
      return this.showPasswordFlag ? 'text' : (this.type || 'text');
    },
    commonProps() {
      return {
        class: {
          'p-input': true,
          'error-input': this.showError || this.errorMessage,
          'has-prefix': this.prefix || this.hasIcon,
          'has-suffix': this.suffix,
          [this.inputClass]: this.inputClass,
        },
        disabled: this.isDisabled,
        maxlength: this.maxLength,
        // eslint-disable-next-line
        name: `input${this._uid}`,
        placeholder: this.placeholder,
        type: this.inputType,
      };
    },
    convertedValue() {
      if(Number.isNaN(this.value)) {
        return '';
      }
      return this.value;
    },
    inputRegexChar() {
      return this.regex ? RegExp(`^${this.regex}$`) : null;
    },
  },
  methods: {
    togglePassword() {
      this.showPasswordFlag = !this.showPasswordFlag;
    },
    handleInput(e) {
      if(!this.mask) {
        if(this.type === 'number') {
          this.$emit('input', parseInt(e.target.value));
          return;
        }
      }
      if(this.regex) {
        const newVal = e.target.value.replace(RegExp(this.regex, 'g'), '');
        this.$emit('input', newVal);
        this.$forceUpdate();
      } else {
        this.$emit('input', e.target.value);
      }
    },
    handleKeydown(e) {
      // prevent negative numbers
      if(this.type === 'number') {
        if(e.keyCode === 189) {
          e.preventDefault();
        }
      }
      if(
        this.regex
        && !(e.altKey || e.ctrlKey || e.metaKey)
        && e.key.length === 1
        && this.inputRegexChar.test(e.key)
      ) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.p-input-wrap {
  margin-bottom: 15px;
  margin-top: 16px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  > input {
    box-sizing: border-box;
    padding-top: 3px;
  }

  > textarea {
    box-sizing: border-box;
    padding-top: 10px;
  }

  label {
    @include label;
  }

  label.active,
  .p-input:focus ~ label {
    @include label_active;
  }

  .prefix {
    @include subtitle;
    position: absolute;
    top: 13px;
    left: 10px;
    width: 20px;
    text-align: center;
  }

  .suffix {
    @include subtitle;
    color: $black-light;
    font-size: 16px;
    position: absolute;
    top: 13px;
    right: 10px;
  }

  .input-icon {
    position: absolute;
    top: 17px;
    width: auto;
    height: 14px;

    &.input-icon-dollar {
      left: 18px;
    }

    &.input-icon-eye {
      right: 18px;
      z-index: 9;
      cursor: pointer;
    }
  }

  .p-input {
    @include input;

    &.has-prefix {
      padding-left: 36px;

      &::-webkit-input-placeholder {
        font-weight: 300;
      }
    }
    &.has-suffix {
      padding-right: 72px;
    }

    &::placeholder {
      color: $black-light;
    }
    &:disabled {
      background-color: #fff !important;
    }
  }

  textarea.p-input {
    height: auto;
  }

  .bo {
    border: 1px solid red;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity $speed3 ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
