<template>
<Modal
  class="two-factor-modal"
  :cancelable="true"
  :cancelByClickingOutside="false"
  :submitText="submitText"
  :title="$t('settings.account.two_factor.modal_title_disable')"
  :animate="animate"
  @submit="handleSubmit"
  @cancel="$emit('close')"
>
  <template #content>
    <div v-if="currentStep === 0" class="info-section">
      <h4>{{ $t('settings.account.two_factor.password_required.title') }}</h4>
      <p class="p_small">
        {{ $t('settings.account.two_factor.password_required.desc') }}
      </p>
      <TitledInput
        v-model="password"
        type="password"
        :placeholder="$t('auth.placeholders.password')"
        :errorMessage="passwordError"
        @input="passwordError = null"
      />
    </div>

    <div v-if="currentStep === 1" class="info-section">
      <p class="p_small">
        {{ $t('settings.account.two_factor.disable2') }}
      </p>
    </div>
    <ErrorMessage :errorMessage="confirmError" />
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import { fieldErrorKey } from '@/utils/apiError';
import { disableUserTotp } from '@/utils/api';
import { USER_REQUEST } from '@/store/actions';

export default {
  name: 'two-factor-disable',
  components: {
    Modal,
    TitledInput,
    ErrorMessage,
  },
  data() {
    return {
      currentStep: 0,
      password: '',
      passwordError: null,
      confirmError: null,
      animate: false,
    };
  },
  computed: {
    submitText() {
      if(this.currentStep === 1) {
        return this.$t('settings.account.two_factor.done');
      }
      return this.$t('settings.account.two_factor.disable');
    },
  },
  methods: {
    async disableTotp() {
      this.passwordError = null;
      this.confirmError = null;
      if(!this.password) {
        this.passwordError = this.$t('errors.PASSWORD_MISSING');
        return;
      }
      this.animate = true;
      try {
        await disableUserTotp({ password: this.password });
        await this.$store.dispatch(USER_REQUEST, true);
        this.currentStep = 1;
      } catch(error) {
        this.confirmError = this.$t(fieldErrorKey(error.errors));
      }
      this.animate = false;
    },
    handleSubmit() {
      if(this.currentStep === 0) {
        this.disableTotp();
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.two-factor-modal {
  overflow-x: hidden;
  .modal-inner {
    margin-top: 48px;
    padding-bottom: 24px;
  }
  .info-section {
    margin-bottom: 40px;
    p {
      color: $black-med;
    }
  }
  hr {
    margin: 32px 0;
  }
  .submit-button {
    margin-bottom: 16px;
    @include button($main-black, large);
  }
  .error-message-wrap .error {
    padding: 0 0 8px;
  }
}
</style>
