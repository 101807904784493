<template>
<div class="login-form login-modal-form">
  <h2>{{ title }}</h2>
  <div v-if="emailExists" class="back-to-login">
    <img :src="require('@/static/img/art/empty_state1.png')">
    <router-link to="/login" class="link">
      {{ $t('auth.button_text.back_to_login') }}
    </router-link>
  </div>
  <div v-else>
    <div class="login-subtitle">
      {{ $tc('auth.later', missingFieldsCount) }}
    </div>
    <form @submit="handleSubmit">
      <TitledInput
        v-if="missingName"
        v-model="name"
        name="name"
        class="form-input"
        :placeholder="$t('auth.placeholders.name')"
        :maxLength="rules.name.maxLength"
      />
      <TitledInput
        v-if="missingEmail"
        v-model="email"
        name="email"
        class="form-input"
        type="email"
        :placeholder="$t('auth.placeholders.email')"
        :errorMessage="errorEmail"
        @input="clearErrors"
      />
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
      <button
        type="submit"
        class="save-modal-button"
        :disabled="errorEmail"
      >
        {{ $t('auth.button_text.save') }}
      </button>
    </form>
  </div>
</div>
</template>

<script>
import TitledInput from '@/components/widget/TitledInput.vue';
import { validateEmail } from '@/utils/stringUtils';
import { updateEmail } from '@/utils/api';
import { USER_UPDATE } from '@/store/actions';

export default {
  name: 'incomplete',
  components: {
    TitledInput,
  },
  props: {
    missingInfo: {
      type: Array,
      default: () => [],
    },
    credentials: {
      type: Object,
      default: null,
    },
    authInfoComplete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      name: '',
      errorEmail: null,
      errorMessage: null,
      confirmationSent: false,
      rules: {
        name: {
          maxLength: 40,
        },
      },
    };
  },
  computed: {
    missingFieldsCount() {
      return [this.missingName, this.missingEmail].filter(v => v).length;
    },
    missingName() {
      return this.missingInfo.includes('missing_user_name');
    },
    missingEmail() {
      return this.missingInfo.includes('missing_user_email');
    },
    emailExists() {
      return this.missingInfo.includes('email');
    },
    title() {
      if(this.emailExists) {
        return this.$t('auth.email_in_use');
      }
      if(this.missingName && this.missingEmail) {
        return this.$t('auth.need_name_email');
      }
      if(this.missingName) {
        return this.$t('auth.need_name');
      }
      return this.$t('auth.need_email');
    },
  },
  methods: {
    clearErrors() {
      this.errorMessage = null;
      this.errorEmail = null;
    },
    async handleSubmit(e) {
      e.preventDefault();
      const { name, email } = this;
      this.clearErrors();
      if(!email || email === '') {
        this.errorEmail = this.$t('errors.EMAIL_MISSING');
      } else if(!validateEmail(email)) {
        this.errorEmail = this.$t('errors.INVALID_VALUE.email');
      }
      try {
        if(this.missingEmail) {
          await updateEmail(email);
        }
        if(this.missingName) {
          await this.$store.dispatch(USER_UPDATE, { name });
        }
        this.authInfoComplete();
      } catch(err) {
        console.log('updateProfile error: ', err);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.save-modal-button {
  @include button($main-blue, full);
  margin-top: 50px;
}

.back-to-login {
  padding: 32px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 220px;
    margin-bottom: 32px;
  }

  a {
    @include button_small_text;
    color: $main-blue;
  }
}

.login-subtitle > a {
  @include link;
}
</style>
