import { validateRange, validateLength, validateId } from '@/utils/stringUtils';
import { getRewardAnalytics } from '@/utils/api';
import {
  PROJECT_SET,
  GET_PROJECT_REWARD_ANALYTICS,
  SET_PROJECT_REWARD_ANALYTICS,
  SET_PROJECT_ACTIVE_LANGUAGE,
} from '../actions';

const getInitialState = () => ({
  id: null,
  prevRoute: null,
  project: {},
  rewardAnalytics: [],
});

const state = getInitialState();

const getters = {
  previewCode: (projectState) => {
    const { is_collaborator: isCollaborator, preview_code: previewCode } = projectState.project;
    return (isCollaborator && previewCode) ? previewCode : null;
  },
  projectId: projectState => projectState.id,
  prevRoute: projectState => projectState.prevRoute,
  rewardAnalytics: projectState => projectState.rewardAnalytics,
  basicsStatus: (projectState) => {
    const {
      name,
      blurb,
      category,
      location,
      main_image: mainImage,
      duration,
      description,
      rewards,
    } = projectState.project;

    const categoryId = category ? category.id : 0;
    const basicsArray = [
      validateLength(name, { minLength: 3 }),
      validateLength(blurb, { minLength: 10 }),
      validateId(categoryId),
      validateLength(location, { minLength: 1 }),
      validateLength(mainImage.url, { minLength: 1 }),
      validateLength(description, { minLength: 500 }),
      validateLength(rewards, { minLength: 1 }),
      validateRange(duration, { min: 15, max: 60 }),
    ];
    const basicsCompleted = basicsArray.reduce((acc, curVal) => (curVal ? 1 : 0) + acc, 0);
    const canPublish = basicsArray.every(val => val);
    return { completed: basicsCompleted, total: basicsArray.length, canPublish };
  },
};

const actions = {
  [GET_PROJECT_REWARD_ANALYTICS]: async (context, projectId) => {
    const rewardAnalytics = await getRewardAnalytics(projectId);
    context.commit(SET_PROJECT_REWARD_ANALYTICS, rewardAnalytics);
  },
};

const mutations = {
  [PROJECT_SET]: (projectState, project) => {
    if(project) {
      projectState.id = project.id;
      projectState.prevRoute = project.prevRoute;
      // May a copy to avoid issues with external mutation
      projectState.project = JSON.parse(JSON.stringify(project));
    }
  },
  [SET_PROJECT_REWARD_ANALYTICS]: (projectState, rewardAnalytics) => {
    projectState.rewardAnalytics = rewardAnalytics;
  },
  [SET_PROJECT_ACTIVE_LANGUAGE]: (projectState, activeLanguage) => {
    projectState.activeLanguage = activeLanguage;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
