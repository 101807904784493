<template>
<div class="payment-item">
  <div class="payment-item-info">
    <h4 class="payment-title">
      {{ $t('settings.payment.card_name', {'code': last4}) }}
    </h4>
    <div v-if="expired" class="card-expired">
      {{ $t('settings.payment.expired') }}
    </div>
    <div v-else class="payment-expire">
      {{ $t('settings.payment.expires', {'date': formatExpiration }) }}
    </div>
  </div>
  <div class="payment-item-actions">
    <span v-if="card.default" class="payment-is-primary">
      {{ $t('settings.payment.buttons.primary') }}
    </span>
    <span
      v-if="!card.default && !expired"
      class="payment-make-primary"
      @click="$emit('set-default', card)"
    >
      {{ $t('settings.payment.buttons.make_primary') }}
    </span>
    <span class="payment-remove" @click="$emit('remove', card)">
      {{ $t('settings.payment.buttons.remove') }}
    </span>
  </div>
</div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'payment-item',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    last4() {
      if(this.card.last4) {
        return this.card.last4;
      }
      if(this.card.card_number) {
        return this.card.card_number.slice(-4);
      }
      return '';
    },
    expired() {
      return moment(this.card.expiration).isBefore(moment());
    },
    formatExpiration() {
      return moment(this.card.expiration).format('MM/YY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'widgets';

.payment-item {
  border: 1px solid $border-lighter;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 16px 24px;
  margin: 24px 0 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .card-expired {
    color: $main-orange;
    font-size: 16px;
    margin-top: 8px;
  }
  .payment-expire {
    color: $black-med;
    margin: 8px 0 0;
  }
  .payment-item-actions {
    align-items: center;
    display: flex;
    margin: 16px 0;
    .payment-is-primary {
      @include link;
      color: $grey2;
      cursor: auto;
    }
    .payment-make-primary {
      @include link;
    }
    .payment-remove {
      @include link;
      color: $main-orange;
      margin-left: 32px;
    }
  }
}

@media (max-width: $mobile-width) {
  .payment-item .payment-item-actions {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
}
</style>
