
export const bottomVisible = () => {
  const { scrollY } = window;
  const visible = document.documentElement.clientHeight;
  const { scrollHeight } = document.documentElement;
  const footerHeight = 328;
  const pageHeight = scrollHeight - footerHeight;
  const bottomOfPage = visible + scrollY >= pageHeight;
  return bottomOfPage || pageHeight < visible;
};

export const copy = (document, text) => {
  const copyTextArea = document.createElement('textarea');
  copyTextArea.value = text;
  document.body.appendChild(copyTextArea);
  copyTextArea.select();
  document.execCommand('copy');
  document.body.removeChild(copyTextArea);
};

export const saveFile = (filename, data, fileType) => {
  const blob = new Blob([data], { type: fileType });
  if(window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};
