import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home';
import NotFoundPage from '@/views/NotFoundPage';
import Auth from '@/views/Auth';
import UserPrivate from '@/views/UserPrivate';
import UserPublic from '@/views/UserPublic';
import Settings from '@/views/Settings';
import Account from '@/components/user_private/Account';
import Verification from '@/components/user_private/Verification';
import SettingsSubscriptions from '@/components/user_private/SettingsSubscriptions';
import Payment from '@/components/user_private/Payment';
import ForgotPassword from '@/components/auth/ForgotPassword';
import ResetPassword from '@/components/auth/ResetPassword';
import vuexStore from '@/store';
import { PREVIEW_MODE } from '@/store/actions';

const Project = () => import(/* webpackChunkName: "project" */'@/views/Project');
const Preview = () => import(/* webpackChunkName: "project" */'@/views/Preview');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */'@/views/Dashboard');
const DashboardBasics = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardBasics');
const DashboardOverview = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardOverview');
const DashboardRewards = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardRewards');
const DashboardTimeline = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardTimeline');
const DashboardAccount = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardAccount');
const DashboardTranslation = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardTranslation');
const DashboardFaq = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardFaq');
const DashboardBonus = () => import(/* webpackChunkName: "dashboard" */'@/views/DashboardBonus');
const Learn = () => import(/* webpackChunkName: "learn" */'@/views/Learn');
const Questions = () => import(/* webpackChunkName: "learn" */'@/components/learn/Questions');
const Question = () => import(/* webpackChunkName: "learn" */'@/components/learn/Question');
const Search = () => import('@/views/Search');
const Category = () => import('@/views/Category');
const Notifications = () => import('@/views/Notifications');
const Manager = () => import('@/views/Manager');
const Checkout = () => import('@/views/Checkout');
const Wallet = () => import('@/views/Wallet');
const Authenticated = () => import(/* webpackChunkName: "social_auth" */'@/views/Authenticated');
const SocialAuthError = () => import(/* webpackChunkName: "social_auth" */'@/views/SocialAuthError');
const About = () => import(/* webpackChunkName: "about" */'@/views/About');
const Whitepaper = () => import(/* webpackChunkName: "about" */'@/views/Whitepaper');
const MarkdownView = () => import(/* webpackChunkName: "about" */'@/views/MarkdownView');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if(to.hash) {
      return new Promise((resolve, _reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash });
        }, 500);
      });
    }
    if(savedPosition) {
      return savedPosition;
    }
    if(to.meta.noScroll && from.meta.noScroll) {
      return {};
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: 'Pledgecamp', backgroundClass: 'gradient' },
      alias: ['/confirmemail', '/create', '/create/continue', '/create/storefront'],
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: { title: 'About' },
    },
    {
      path: '/whitepaper',
      name: 'whitepaper',
      component: Whitepaper,
      meta: { title: 'Whitepaper' },
    },
    {
      path: '/login',
      name: 'login',
      component: Auth,
      meta: { title: 'Pledgecamp Login' },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: { title: 'Pledgecamp Forgot Password' },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: { title: 'Pledgecamp Reset Password' },
    },
    {
      path: '/signup',
      name: 'signup',
      component: Auth,
      meta: { title: 'Pledgecamp Signup' },
    },
    {
      path: '/user/me',
      name: 'user',
      meta: { requiresAuth: true },
      component: UserPrivate,
    },
    {
      path: '/user/:id',
      name: 'user-public',
      component: UserPublic,
      meta: { title: 'Pledgecamp About' },
    },
    {
      path: '/settings',
      redirect: '/settings/account',
      name: 'settings',
      component: Settings,
      meta: {
        title: 'Settings',
        requiresAuth: true,
      },
      children: [
        {
          path: 'account',
          name: 'settings-account',
          component: Account,
          meta: {
            title: 'Settings Account',
          },
        },
        {
          path: 'verification',
          name: 'settings-verification',
          component: Verification,
          meta: {
            title: 'Settings Verification',
          },
        },
        {
          path: 'notifications',
          name: 'settings-notifications',
          component: SettingsSubscriptions,
          meta: {
            title: 'Settings Notifications',
          },
        },
        {
          path: 'payment',
          name: 'settings-payment',
          component: Payment,
          meta: {
            title: 'Settings Payment',
          },
        },
      ],
    },
    {
      path: '/dashboard/:id',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        title: 'Project Dashboard',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/basics',
      name: 'dashboard-basics',
      component: DashboardBasics,
      meta: {
        title: 'Project Basics',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/overview',
      name: 'dashboard-overview',
      component: DashboardOverview,
      meta: {
        title: 'Project Overview',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/rewards',
      name: 'dashboard-rewards',
      component: DashboardRewards,
      meta: {
        title: 'Project Rewards',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/timeline',
      name: 'dashboard-timeline',
      component: DashboardTimeline,
      meta: {
        title: 'Project Timeline',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/account',
      name: 'dashboard-account',
      component: DashboardAccount,
      meta: {
        title: 'Project Account',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/translation',
      name: 'dashboard-translation',
      component: DashboardTranslation,
      meta: {
        title: 'Project Translation',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/faq',
      name: 'dashboard-faq',
      component: DashboardFaq,
      meta: {
        title: 'Project FAQ',
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:id/bonus',
      name: 'dashboard-bonus',
      component: DashboardBonus,
      meta: {
        title: 'Project Bonus',
        requiresAuth: true,
      },
    },
    {
      path: '/wallet/:tab?',
      name: 'wallet',
      component: Wallet,
      meta: {
        title: 'Pledgecamp Wallet',
        requiresAuth: true,
      },
    },
    {
      path: '/learn',
      redirect: '/learn/questions',
      name: 'learn',
      component: Learn,
      meta: { title: 'Pledgecamp Learn' },
      children: [
        {
          path: 'questions',
          name: 'questions',
          component: Questions,
        },
        {
          path: 'question/:id',
          name: 'question',
          component: Question,
        },
      ],
    },
    {
      path: '/project/:id(\\d+)/checkout',
      name: 'checkout',
      component: Checkout,
      meta: {
        title: 'Pledgecamp Pledge',
        requiresAuth: true,
      },
    },
    {
      path: '/project/preview/:code(.+)/:tab?',
      name: 'project-preview',
      component: Preview,
      props: true,
      meta: {
        title: 'Pledgecamp Project Preview',
        noScroll: true,
        previewMode: true,
      },
    },
    {
      path: '/project/:id([0-9a-z-]+)/:tab(updates)/:updateId([0-9]+)?',
      name: 'project-update',
      component: Project,
      props: true,
      meta: { title: 'Pledgecamp Project', noScroll: true },
    },
    {
      path: '/project/:id([0-9a-z-]+)/:tab?',
      name: 'project',
      component: Project,
      props: true,
      meta: { title: 'Pledgecamp Project', noScroll: true },
    },
    {
      path: '/projects/:filter/:categoryId?',
      name: 'project-list',
      component: Category,
    },
    {
      path: '/search',
      name: 'search',
      component: Search,
      meta: { title: 'Pledgecamp Search' },
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: Notifications,
      meta: {
        title: 'Pledgecamp Notifications',
        requiresAuth: true,
      },
    },
    {
      path: '/manager/:id(\\d+)/:tab?',
      name: 'manager',
      component: Manager,
      meta: {
        title: 'Pledgecamp Project Manager',
        noScroll: true,
        requiresAuth: true,
      },
    },
    { path: '/google_authenticated', component: Authenticated },
    { path: '/facebook_authenticated', component: Authenticated },
    { path: '/social_auth_error', component: SocialAuthError },
    {
      path: '/risk',
      name: 'risk',
      component: MarkdownView,
      props: { contentKey: 'risk_page' },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: MarkdownView,
      props: { contentKey: 'privacy_page' },
    },
    {
      path: '/terms',
      name: 'terms',
      component: MarkdownView,
      props: { contentKey: 'terms_page' },
    },
    { path: '*', component: NotFoundPage },
  ],
});

//  Preview condition middleware
router.beforeEach((to, _from, next) => {
  vuexStore.commit(PREVIEW_MODE, to.meta.previewMode);
  next();
});

router.afterEach((to, _from) => {
  Vue.nextTick(() => {
    const parentTitle = to.matched.some(record => record.meta.title);
    document.title = to.meta.title || parentTitle || 'Pledgecamp';
  });
});

export default router;
