<template>
<svg
  width="13"
  height="13"
  viewBox="0 0 13 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  @click="$emit('click')"
>
  <!-- eslint-disable -->
  <path
    :fill="color"
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.96945 1.47998C7.78658 1.28839 7.78658 0.978402 7.96945 0.786815C8.43134 0.291832 9.06266 0.00885943 9.7244 0.000204503C10.3861 -0.00845043 11.0241 0.257922 11.4977 0.74067C11.9599 1.22328 12.2195 1.87789 12.2195 2.56047C12.2195 3.24305 11.9599 3.89766 11.4977 4.38027C11.3901 4.4879 11.2471 4.54834 11.0981 4.54914C10.9837 4.55247 10.8727 4.50794 10.7898 4.42543L7.96945 1.47998ZM10.1261 5.8098C10.3096 5.6181 10.3096 5.30735 10.1261 5.11565L7.30574 2.1702C7.12218 1.97856 6.82464 1.97856 6.64108 2.1702L1.93022 7.09402C1.74672 7.28572 1.74672 7.59646 1.93022 7.78816L4.75054 10.7336C4.93409 10.9253 5.23163 10.9253 5.41519 10.7336L10.1261 5.8098ZM1.63503 8.86816C1.51731 8.74482 1.34583 8.69584 1.18439 8.73946C1.02295 8.78307 0.895726 8.91275 0.850043 9.08023L0.0180511 12.1239C-0.0287709 12.2952 0.0170205 12.4797 0.137686 12.6057C0.258352 12.7317 0.434937 12.7795 0.599035 12.7306L3.51336 11.8617C3.67342 11.8136 3.79716 11.6807 3.83863 11.5122C3.88137 11.3442 3.83474 11.1651 3.71642 11.0429L1.63503 8.86816Z"
  />
  <!-- eslint-enable -->
</svg>
</template>

<script>

export default {
  name: 'edit',
  props: {
    color: {
      type: String,
      default: '#7C7C7E',
    },
  },
};
</script>
