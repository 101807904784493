<template>
<Modal
  class="two-factor-modal"
  :cancelable="true"
  :cancelByClickingOutside="false"
  :submitable="false"
  :title="$t('settings.account.two_factor.modal_title')"
  @cancel="$emit('close')"
>
  <template #content>
    <div v-if="currentStep === 0" class="info-section">
      <h4>{{ $t('settings.account.two_factor.pair_app.title') }}</h4>
      <p class="p_small">
        {{ $t('settings.account.two_factor.pair_app.desc') }}
      </p>
      <div class="qr-code">
        <QRCode :value="uri" :options="{ width: 180 }" />
      </div>

      <hr>

      <h4>{{ $t('settings.account.two_factor.password_required.title') }}</h4>
      <p class="p_small">
        {{ $t('settings.account.two_factor.password_required.desc') }}
      </p>
      <TitledInput
        v-model="password"
        type="password"
        :placeholder="$t('auth.placeholders.password')"
        :errorMessage="passwordError"
        @input="passwordError = null"
      />

      <hr>

      <h4>{{ $t('settings.account.two_factor.enter_digits.title') }}</h4>
      <p class="p_small">
        {{ $t('settings.account.two_factor.enter_digits.desc') }}
      </p>
      <TitledInput
        v-model="code"
        class="code-input"
        placeholder="000000"
        mask="######"
        :errorMessage="codeError"
      />
    </div>

    <div v-if="currentStep === 1" class="info-section">
      <h4>{{ $t('settings.account.two_factor.almost_done.title') }}</h4>
      <p class="p_small">
        {{ $t('settings.account.two_factor.almost_done.desc') }}
      </p>

      <hr>

      <h4>{{ $t('settings.account.two_factor.recovery_code.title') }}</h4>
      <div class="recovery-codes">
        {{ recoveryCode }}
      </div>
    </div>

    <div v-if="currentStep === 2" class="info-section">
      <h4>{{ $t('settings.account.two_factor.process_complete.title') }}</h4>
      <p class="p_small">
        {{ $t('settings.account.two_factor.process_complete.desc') }}
      </p>
    </div>
    <ErrorMessage :errorMessage="confirmError" />
    <div v-if="currentStep === 1" class="submit-button" @click="handleDownload">
      {{ $t('download') }}
    </div>
    <div v-if="currentStep !== 1 || codesDownloaded" class="submit-button" @click="handleSubmit">
      {{ submitButtonText }}
    </div>
  </template>
</Modal>
</template>

<script>
import QRCode from '@chenfengyuan/vue-qrcode';
import Modal from '@/components/widget/Modal.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import { fieldErrorKey } from '@/utils/apiError';
import { enableUserTotp, confirmEnableUserTotp } from '@/utils/api';
import { USER_REQUEST } from '@/store/actions';

export default {
  name: 'two-factor-enable',
  components: {
    QRCode,
    Modal,
    TitledInput,
    ErrorMessage,
  },
  data() {
    return {
      password: '',
      code: '',
      recoveryCode: '',
      currentStep: 0,
      passwordError: null,
      codeError: null,
      confirmError: null,
      codesDownloaded: false,
      uri: null,
      loading: true,
    };
  },
  computed: {
    submitButtonText() {
      if(this.currentStep === 2) {
        return this.$t('settings.account.two_factor.done');
      }
      return this.$t('next');
    },
  },
  methods: {
    goNext() {
      this.currentStep += 1;
    },
    clearErrors() {
      this.passwordError = null;
      this.codeError = null;
      this.confirmError = null;
    },
    async confirmTotp() {
      const { password, code } = this;
      if(!password) {
        this.passwordError = this.$t('errors.PASSWORD_MISSING');
        return;
      }
      if(!code || code.length < 6) {
        this.codeError = this.$t('errors.INVALID_VALUE.code');
        return;
      }
      this.animate = true;
      try {
        await confirmEnableUserTotp({ password, code });
        await this.$store.dispatch(USER_REQUEST, true);
        this.goNext();
      } catch(error) {
        this.confirmError = this.$t(fieldErrorKey(error.errors));
      }
      this.animate = false;
    },
    handleDownload() {
      const downloadText = this.recoveryCode;
      this.downloadContent('recovery.txt', downloadText);
      this.codesDownloaded = true;
    },
    handleDone() {
      this.$emit('close');
    },
    handleSubmit() {
      this.clearErrors();
      if(this.currentStep === 0) {
        this.confirmTotp();
      } else if(this.currentStep === 1) {
        this.goNext();
      } else if(this.currentStep === 2) {
        this.handleDone();
      }
    },
    downloadContent(filename, text) {
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
  },
  async created() {
    try {
      const { data } = await enableUserTotp();
      this.recoveryCode = data.totp_recovery_key;
      this.uri = data.totp_uri;
      this.loading = false;
    } catch(error) {
      console.log(error);
      if(error.status === 403) {
        this.$emit('close');
      }
      this.confirmError = this.$t(fieldErrorKey(error.errors));
    }
  },
};
</script>

<style lang="scss">
@import 'widgets';

.two-factor-modal {
  overflow-x: hidden;
  .modal-inner {
    margin-top: 48px;
    padding-bottom: 24px;
  }
  .info-section {
    margin-bottom: 40px;
    p {
      color: $black-med;
    }
  }
  .qr-code {
    text-align: center;
    margin-bottom: -8px;
  }
  .code-input input {
    letter-spacing: 3px;
  }
  .recovery-codes {
    display: flex;
    padding: 36px 40px;
    p {
      margin: 0;
    }
  }
  hr {
    margin: 32px 0;
  }
  .submit-button {
    margin-bottom: 16px;
    @include button($main-black, large);
  }
  .error-message-wrap .error {
    padding: 0 0 8px;
  }
}
</style>
