import { fieldErrorKey } from '@/utils/apiError';

export default {
  props: {
    // Designates translations, e.g. `form.${formName}.labels.${key}`
    formName: {
      type: String,
      default: 'form',
    },
    value: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      errors: {},
      rules: {},
      // Support value as prop or data
      dataValue: null,
    };
  },
  computed: {
    noErrors() {
      return Object.values(this.errors).filter(val => val && val.length).length === 0;
    },
    // Support value as prop or data
    formValue() {
      return this.dataValue || this.value;
    },
    // Support formName as prop or data
    formNameFallback() {
      return this.dataFormName || this.formName;
    },
  },
  methods: {
    isValid(exceptKey, exceptValue) {
      return !this.hasEmpty(exceptKey, exceptValue) && this.noErrors;
    },
    // Check if any items are empty. Manually check `exceptKey` since it may not be updated yet.
    hasEmpty(exceptKey, exceptValue) {
      const self = this;
      return Object.keys(this.formValue).some((key) => {
        const val = (key === exceptKey) ? exceptValue : self.formValue[key];
        const rule = self.rules[key];
        return !!rule && (rule.required && (!val || !val.length));
      });
    },
    validateAll() {
      // Use reduce instead of every to avoid short-circuit
      return Object.keys(this.rules)
        .reduce((acc, key) => (this.validateField(key) && acc), true);
    },
    handleFormInput(key, inputValue) {
      this.$emit('input', { ...this.formValue, [key]: inputValue });
      return this.validateRule(key, inputValue, this.rules[key]);
    },
    validateField(key) {
      const inputValue = this.formValue[key];
      return this.validateRule(key, inputValue, this.rules[key]);
    },
    validateRule(key, val, rule) {
      // Default to valid if there are no rules for this field
      let valid = !rule;
      if(rule) {
        const label = this.$t(`form.${this.formNameFallback}.labels.${key}`).toLowerCase();

        delete this.errors[`${key}Sub`];
        if(rule.required && (val === null || val === undefined || val === '' || Number.isNaN(val))) {
          this.$set(this.errors, key, this.$t('errors.VALUE_MISSING', { label }));

        } else if(val && rule.validator && !rule.validator(val)) {

          this.$set(this.errors, key, this.$t('errors.VALUE_INVALID', { label }));
          if(rule.subError) {
            const data = rule.subError.data || {};
            this.$set(this.errors, `${key}Sub`, this.$t(rule.subError.key, { ...data, label }));
          }
        } else {
          valid = true;
          this.$delete(this.errors, key);
        }
      }
      this.$emit('validate', this.isValid(key, val));
      return valid;
    },
    checkApiErrors(errors) {
      if(typeof errors === 'object') {
        Object.keys(this.rules).forEach((ruleKey) => {
          if(errors[ruleKey]) {
            this.$set(this.errors, ruleKey, this.$t(fieldErrorKey(errors[ruleKey])));
          }
        });
      }
    },
    resetErrors() {
      this.errors = {};
    },
  },
};
