<template>
<div class="project-slider section">
  <div class="project-slider-header">
    <div class="project-slider-left">
      <h2 v-if="title" class="header-title">
        {{ title }}
      </h2>
      <div v-if="subTitle" class="p_large">
        {{ subTitle }}
      </div>
    </div>
    <div v-if="projects.length > 1" class="project-slider-right">
      <button
        v-if="showViewAll"
        class="btn-view-all"
        @click="$emit('view-all')"
      >
        {{ $t('view_all') }}
      </button>
    </div>
  </div>
  <Spinner v-if="loading" :size="48" />
  <Carousel
    v-else
    ref="carousel"
    :slideCount="projectsWithPlaceholders.length"
    :slideWidth="297"
    :gutter="48"
    @slide-change="currentPage = $event"
    @slides-visible="perPage = $event"
  >
    <div
      v-for="(project, index) in projectsWithPlaceholders"
      :key="index"
      class="project-slider-item"
    >
      <ProjectItem
        :project="project"
        :funded="funded"
        :toDashboard="toDashboard"
      />
    </div>
  </Carousel>
</div>
</template>

<script>
import Carousel from '@/components/widget/Carousel';
import ProjectItem from '@/components/widget/ProjectItem';
import Spinner from '@/components/widget/Spinner';
import { makeProjectPlaceholders } from '@/utils/objectUtils';

export default {
  name: 'project-slider',
  components: {
    Carousel,
    ProjectItem,
    Spinner,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    showViewAll: {
      type: Boolean,
      default: true,
    },
    showPlaceholders: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    funded: {
      type: Boolean,
      default: false,
    },
    toDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 0,
      perPage: 4,
    };
  },
  computed: {
    projectsWithPlaceholders() {
      if(this.showPlaceholders && (this.projects.length < this.perPage)) {
        const placeholderCount = this.perPage - this.projects.length;
        return makeProjectPlaceholders([...this.projects], placeholderCount);
      }
      return this.projects;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.project-slider {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-width: 1440px;
  width: 100%;

  .pc-carousel-nav {
    right: 144px;
    top: -76px;
    flex-direction: row-reverse;
    .btn-prev {
      margin-right: 16px;
    }
    .btn-prev, .btn-next {
      &:not(:disabled) {
        border: 1px solid $main-black;
      }
    }
  }

  .project-slider-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: flex-end;

    .header-title {
      margin: 0;
    }
  }

  .project-slider-right {
    display: flex;
    align-items: center;
    z-index: 1;

    .prev-next {
      margin-right: 80px;

      .btn-next {
        margin-left: 15px;
      }
    }

    .btn-view-all {
      @include black-button;
      margin-right: -40px;
    }
  }

  @media (max-width: $tablet-width) {
    .project-slider-header {
      flex-direction: column;
      align-items: flex-start;
    }
    .project-slider-right {
      .prev-next {
        display: none;
      }
      .btn-view-all {
        @include link;
        margin-top: 10px;
      }
    }
  }
}
</style>
