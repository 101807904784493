<template>
<Modal
  title="Coming soon"
  submitText="Ok"
  class="unimplemented-modal"
  @submit="$emit('close-modal')"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div v-if="builder" class="unimplemented-content" v-html="$('unimplemented.builder')" />
    <div v-else class="unimplemented-content">
      {{ $t('unimplemented.disabled') }}
      <span v-if="creator" v-html="$('unimplemented.creator')" />
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';

export default {
  name: 'unimplemented-modal',
  components: { Modal },
  props: {
    creator: Boolean,
    builder: Boolean,
  },
  destroyed() {
    document.body.classList.remove('noscroll');
  },
};
</script>

<style lang="scss">
@import 'general';

.unimplemented-modal.modal-outer .modal-inner {
  max-width: 420px;
  .unimplemented-content {
    box-sizing: border-box;
    padding: 8px 0 0;
    font-weight: 400;
    @include p_small;
    a {
      text-decoration: underline;
    }
  }
}

</style>
