<template>
<Modal
  class="login-form login-modal-form"
  :submitable="false"
  :expanded="true"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <h2>{{ $t('auth.confirm') }}</h2>
    <AuthLayout>
      <template #content>
        <div>
          <div class="confirm-description">
            {{ $t('auth.check_inbox') }}
          </div>
          <div class="confirm-description">
            {{ $t('auth.check_inbox2') }}
          </div>
        </div>
        <ErrorMessage :errorMessage="resendError" />
        <div class="confirm-buttons">
          <PButton
            class="resend-button"
            :loading="resending"
            :complete="confirmationSent"
            @click="handleResend"
          >
            {{ $t('resend') }}
          </PButton>
          <PButton
            class="resend-button"
            @click="$emit('close-modal')"
          >
            {{ $t('close') }}
          </PButton>
        </div>
      </template>
    </AuthLayout>
  </template>
</Modal>
</template>

<script>
import AuthLayout from '@/components/auth/AuthLayout';
import ErrorMessage from '@/components/widget/ErrorMessage';
import Modal from '@/components/widget/Modal';
import PButton from '@/components/widget/PButton';
import { resendConfirmation } from '@/utils/api';

export default {
  name: 'confirm-email-modal',
  components: {
    AuthLayout,
    ErrorMessage,
    Modal,
    PButton,
  },
  data() {
    return {
      confirmationSent: false,
      resending: false,
      resendError: null,
      resendTimeout: null,
    };
  },
  methods: {
    async handleResend() {
      this.resending = true;
      try {
        const { email } = (this.$store.getters.profile || {});
        await resendConfirmation(email);
        this.confirmationSent = true;
      } catch(err) {
        this.resendError = this.$t('auth.resend_error');
      }
      this.resending = false;
      this.resendTimeout = setTimeout(this.resetButtonText, 2000);
    },
    resetButtonText() {
      this.confirmationSent = false;
      this.resendTimeout = null;
    },
  },
  beforeDestroy() {
    if(this.resendTimeout) {
      clearTimeout(this.resendTimeout);
    }
  },
};
</script>

<style lang="scss">
@import 'widgets';

.confirm-buttons {
  display: flex;
  justify-content: center;
  .resend-button {
    @include button($main-blue, full);
    margin: 8px 24px 0;
    width: 220px;
  }
}

.login-modal-form {
  h2 {
    text-align: center;
  }
  .modal-inner {
    max-width: 960px;
  }
  .confirm-description {
    @include p_large;
    max-width: 640px;
    margin: 0 auto 20px;
  }
  .modal-auth-form-wrap {
    height: unset;
    margin-top: 24px;
    text-align: center;
    .error-message-wrap .error {
      justify-content: center;
    }
  }
  .modal-auth-inner .modal-auth-main .images {
    margin-top: 30%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .images-left, .images-right {
      float: unset;
    }
  }
}
</style>
