<template>
<div class="container-content settings">
  <h1 class="settings-title">
    {{ $t('settings.title') }}
  </h1>
  <TabBar
    :tabs="this.$t('settings.tabs')"
    :activeTab="activeTab"
    :onChangeTab="setTab"
  >
    <template #right>
      <div class="user-profile">
        <router-link :to="{ name: 'user' }">
          {{ $t('header.profile') }}
        </router-link>
      </div>
    </template>
  </TabBar>
  <router-view />
</div>
</template>

<script>
import TabBar from '@/components/widget/TabBar.vue';

export default {
  name: 'settings',
  components: {
    TabBar,
  },
  data() {
    return {
      activeTab: this.$t('settings.tabs')[0],
    };
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab;
      this.$router.push({ name: `settings-${tab.route}` });
    },
  },
  created() {
    const { name } = this.$route;
    const tab = this.$t('settings.tabs').find(({ route }) => name.includes(route));
    this.activeTab = tab;
  },
};
</script>

<style lang="scss">
@import 'widgets';

.settings {
  .settings-title {
    margin-top: 60px;
    margin-bottom: 80px;
  }
  .user-profile {
    display: flex;
    align-items: center;
    a {
      @include tab_item;
      color: $main-blue;
    }
  }

  .settings-nav {
    > a {
      @include tab_item;
      padding: 16px 0;

      &:not(:last-child) {
        margin-right: 32px;
      }

      &.router-link-active {
        border-bottom: 3px solid $main-orange;
        color: $main-black;
      }
    }

    hr {
      margin: -3px 0 0;
    }
    @media (max-width: $mobile-width) {
      > a {
        font-size: 12px;
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .settings-button {
    @include button($main-blue, small, secondary);
    width: 214px;
    padding: 3px 0;

    &.red {
      @include button($main-red, small, secondary);
      &:disabled {
        background-color: rgba($main-red, 0.1);
        color: $main-red;
      }
    }
    &.grey {
      @include button($grey-button, small, primary);
      &:disabled {
        background-color: $grey-button;
      }
    }
  }

  .section-right {
    display: flex;
    justify-content: flex-end;
    max-width: 500px;
  }
}

@media (max-width: $mobile-width) {
  .settings .section-wrap {
    .section-left {
      max-width: 100%;
    }
    .section-right {
      flex-direction: column;
      margin-top: 48px;
    }
    .settings-button {
      width: 100%;
    }
  }
}
</style>
