<template>
<div class="login-modal-form login-2fa-form">
  <div class="login-form-title">
    {{ $t('auth.two_factor_auth') }}
  </div>
  <div v-if="showReset">
    <div class="login-subtitle">
      {{ $t('auth.return_code') }}
      <a @click="showReset = false">{{ $t('back') }}</a>
    </div>
    <h4>{{ $t('auth.recovery') }}</h4>
    <p>{{ $t('auth.enter_recovery_code') }}</p>
    <TitledInput
      v-model="recoveryKey"
      class="form-input"
      type="password"
      :placeholder="$t('auth.recovery_key')"
      :errorMessage="recoveryError"
      @keyup.enter="handleRecovery"
    />
    <PButton class="login-modal-button" @click="handleRecovery">
      {{ $t('auth.reset') }}
    </PButton>
  </div>
  <div v-else>
    <div class="login-subtitle">
      {{ $t('auth.unable_provide_code') }}
      <a @click="showReset = true">{{ $t('auth.click_here') }}</a>
    </div>
    <h4>{{ $t('auth.enter_digits') }}</h4>
    <p>{{ $t('auth.enter_verification_code') }}</p>
    <TitledInput
      v-model="code"
      class="code-input"
      placeholder="000000"
      mask="######"
      :errorMessage="codeError"
      @keyup.enter="handleSubmit"
    />
    <!--TODO: Needs to be implemented in the backend
    <Checkbox
      :item="{ checked: dontAsk, label: $t('auth.dont_ask_30_days') }"
      :onCheck="handleCheck"
    />
    -->
    <button type="submit" class="login-modal-button" @click="handleSubmit">
      <span>{{ $t('auth.button_text.verify') }}</span>
      <Arrow color="white" />
    </button>
  </div>
</div>
</template>

<script>
import PButton from '@/components/widget/PButton.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import Arrow from '@/components/svg/Arrow.vue';
import { firstErrorKey } from '@/utils/apiError';

export default {
  name: 'login-2fa',
  components: {
    PButton,
    TitledInput,
    Arrow,
  },
  props: {
    authorize2fa: {
      type: Function,
      required: true,
    },
    reset2fa: {
      type: Function,
      required: true,
    },
    credentials: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      recoveryError: null,
      codeError: null,
      dontAsk: false,
      showReset: false,
      recoveryKey: '',
    };
  },
  methods: {
    handleCheck(checked) {
      this.dontAsk = checked;
    },
    clearErrors() {
      this.recoveryError = null;
      this.codeError = null;
    },
    async handleRecovery() {
      this.clearErrors();
      if(!this.recoveryKey) {
        this.recoveryError = this.$t('errors.TOTP_INVALID_RECOVERY_KEY');
        return;
      }
      try {
        await this.reset2fa(this.recoveryKey);
      } catch(error) {
        this.recoveryError = this.$t(firstErrorKey(error.errors));
      }
    },
    async handleSubmit() {
      this.clearErrors();
      if(this.code.length < 6) {
        this.codeError = this.$t('errors.CODE_INVALID');
        return;
      }
      try {
        await this.authorize2fa({
          auth: this.credentials,
          code: this.code,
        });
      } catch(error) {
        this.codeError = this.$t(firstErrorKey(error.errors));
      }
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.login-2fa-form {
  .login-form-title {
    max-width: unset;
  }
  .login-subtitle a {
    margin-left: 8px;
  }
  p {
    color: $black-med;
    margin: 16px 0 8px;
  }
  .p-checkbox-wrap {
    margin-bottom: 28px;
    .p-checkbox {
      font-family: $font-title;
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      color: rgba($main-black, 0.3);
    }
  }
  .code-input input {
    letter-spacing: 3px;
  }
}
</style>
