export const RewardStatus = {
  ADD: 'add', // Able to add reward into user's cart
  SOLD: 'sold', // All items sold out
};

export const EmailConfirmStatus = {
  SUCCESS: 'success',
  SUCCESS_NO_EMAIL: 'success_no_email',
  MISSING_CODE: 'missing_code',
  NOT_FOUND: 'not_found',
  ALREADY_CONFIRMED: 'already_confirmed',
  EXPIRED: 'expired',
};

export const KYCStatus = {
  KYC_NOT_DONE: 'KYC_NOT_DONE',
  KYC_PENDING: 'KYC_PENDING',
  KYC_COMPLETE: 'KYC_COMPLETE',
  KYC_FAILED: 'KYC_FAILED',
};

export const PledgeStatus = {
  SHOPPING: 'SHOPPING',
  CHECKED_OUT: 'CHECKED_OUT',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
};

export const VoteStatus = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
};

export const ProjectStatus = {
  INITIAL: 'INITIAL',
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  COMPLETE: 'COMPLETE',
};

export const ProjectType = {
  CROWDFUNDING: 'CROWDFUNDING',
  PREORDER: 'PREORDER',
  STOREFRONT: 'STOREFRONT',
};

// Identifiers used when getting a list of login methods from the backend
export const LoginMethod = {
  LOCAL: 'local',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const AccountInformationStatus = {
  NONE: 'None',
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
};

export const VuexStatus = {
  NONE: 'NONE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const Currencies = [
  'USD',
  'CAD',
];

// Country and alpha-3 codes derived from https://en.wikipedia.org/wiki/ISO_3166-1
export const CountriesArray = [
  {
    name: 'Afghanistan',
    alpha3: 'AFG',
  },
  {
    name: 'Åland Islands',
    alpha3: 'ALA',
  },
  {
    name: 'Albania',
    alpha3: 'ALB',
  },
  {
    name: 'Algeria',
    alpha3: 'DZA',
  },
  {
    name: 'American Samoa',
    alpha3: 'ASM',
  },
  {
    name: 'Andorra',
    alpha3: 'AND',
  },
  {
    name: 'Angola',
    alpha3: 'AGO',
  },
  {
    name: 'Anguilla',
    alpha3: 'AIA',
  },
  {
    name: 'Antarctica',
    alpha3: 'ATA',
  },
  {
    name: 'Antigua and Barbuda',
    alpha3: 'ATG',
  },
  {
    name: 'Argentina',
    alpha3: 'ARG',
  },
  {
    name: 'Armenia',
    alpha3: 'ARM',
  },
  {
    name: 'Aruba',
    alpha3: 'ABW',
  },
  {
    name: 'Australia',
    alpha3: 'AUS',
  },
  {
    name: 'Austria',
    alpha3: 'AUT',
  },
  {
    name: 'Azerbaijan',
    alpha3: 'AZE',
  },
  {
    name: 'Bahamas',
    alpha3: 'BHS',
  },
  {
    name: 'Bahrain',
    alpha3: 'BHR',
  },
  {
    name: 'Bangladesh',
    alpha3: 'BGD',
  },
  {
    name: 'Barbados',
    alpha3: 'BRB',
  },
  {
    name: 'Belarus',
    alpha3: 'BLR',
  },
  {
    name: 'Belgium',
    alpha3: 'BEL',
  },
  {
    name: 'Belize',
    alpha3: 'BLZ',
  },
  {
    name: 'Benin',
    alpha3: 'BEN',
  },
  {
    name: 'Bermuda',
    alpha3: 'BMU',
  },
  {
    name: 'Bhutan',
    alpha3: 'BTN',
  },
  {
    name: 'Bolivia',
    alpha3: 'BOL',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha3: 'BES',
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha3: 'BIH',
  },
  {
    name: 'Botswana',
    alpha3: 'BWA',
  },
  {
    name: 'Bouvet Island',
    alpha3: 'BVT',
  },
  {
    name: 'Brazil',
    alpha3: 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    alpha3: 'IOT',
  },
  {
    name: 'Brunei Darussalam',
    alpha3: 'BRN',
  },
  {
    name: 'Bulgaria',
    alpha3: 'BGR',
  },
  {
    name: 'Burkina Faso',
    alpha3: 'BFA',
  },
  {
    name: 'Burundi',
    alpha3: 'BDI',
  },
  {
    name: 'Cambodia',
    alpha3: 'KHM',
  },
  {
    name: 'Cameroon',
    alpha3: 'CMR',
  },
  {
    name: 'Canada',
    alpha3: 'CAN',
  },
  {
    name: 'Cape Verde',
    alpha3: 'CPV',
  },
  {
    name: 'Cayman Islands',
    alpha3: 'CYM',
  },
  {
    name: 'Central African Republic',
    alpha3: 'CAF',
  },
  {
    name: 'Chad',
    alpha3: 'TCD',
  },
  {
    name: 'Chile',
    alpha3: 'CHL',
  },
  {
    name: 'China',
    alpha3: 'CHN',
  },
  {
    name: 'Christmas Island',
    alpha3: 'CXR',
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha3: 'CCK',
  },
  {
    name: 'Colombia',
    alpha3: 'COL',
  },
  {
    name: 'Comoros',
    alpha3: 'COM',
  },
  {
    name: 'Congo',
    alpha3: 'COG',
  },
  {
    name: 'Congo, Democratic Republic of the',
    alpha3: 'COD',
  },
  {
    name: 'Cook Islands',
    alpha3: 'COK',
  },
  {
    name: 'Costa Rica',
    alpha3: 'CRI',
  },
  {
    name: "Côte d'Ivoire",
    alpha3: 'CIV',
  },
  {
    name: 'Croatia',
    alpha3: 'HRV',
  },
  {
    name: 'Cuba',
    alpha3: 'CUB',
  },
  {
    name: 'Curaçao',
    alpha3: 'CUW',
  },
  {
    name: 'Cyprus',
    alpha3: 'CYP',
  },
  {
    name: 'Czech Republic',
    alpha3: 'CZE',
  },
  {
    name: 'Denmark',
    alpha3: 'DNK',
  },
  {
    name: 'Djibouti',
    alpha3: 'DJI',
  },
  {
    name: 'Dominica',
    alpha3: 'DMA',
  },
  {
    name: 'Dominican Republic',
    alpha3: 'DOM',
  },
  {
    name: 'Ecuador',
    alpha3: 'ECU',
  },
  {
    name: 'Egypt',
    alpha3: 'EGY',
  },
  {
    name: 'El Salvador',
    alpha3: 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    alpha3: 'GNQ',
  },
  {
    name: 'Eritrea',
    alpha3: 'ERI',
  },
  {
    name: 'Estonia',
    alpha3: 'EST',
  },
  {
    name: 'Ethiopia',
    alpha3: 'ETH',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha3: 'FLK',
  },
  {
    name: 'Faroe Islands',
    alpha3: 'FRO',
  },
  {
    name: 'Fiji',
    alpha3: 'FJI',
  },
  {
    name: 'Finland',
    alpha3: 'FIN',
  },
  {
    name: 'France',
    alpha3: 'FRA',
  },
  {
    name: 'French Guiana',
    alpha3: 'GUF',
  },
  {
    name: 'French Polynesia',
    alpha3: 'PYF',
  },
  {
    name: 'French Southern Territories',
    alpha3: 'ATF',
  },
  {
    name: 'Gabon',
    alpha3: 'GAB',
  },
  {
    name: 'Gambia',
    alpha3: 'GMB',
  },
  {
    name: 'Georgia',
    alpha3: 'GEO',
  },
  {
    name: 'Germany',
    alpha3: 'DEU',
  },
  {
    name: 'Ghana',
    alpha3: 'GHA',
  },
  {
    name: 'Gibraltar',
    alpha3: 'GIB',
  },
  {
    name: 'Greece',
    alpha3: 'GRC',
  },
  {
    name: 'Greenland',
    alpha3: 'GRL',
  },
  {
    name: 'Grenada',
    alpha3: 'GRD',
  },
  {
    name: 'Guadeloupe',
    alpha3: 'GLP',
  },
  {
    name: 'Guam',
    alpha3: 'GUM',
  },
  {
    name: 'Guatemala',
    alpha3: 'GTM',
  },
  {
    name: 'Guernsey',
    alpha3: 'GGY',
  },
  {
    name: 'Guinea',
    alpha3: 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    alpha3: 'GNB',
  },
  {
    name: 'Guyana',
    alpha3: 'GUY',
  },
  {
    name: 'Haiti',
    alpha3: 'HTI',
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha3: 'HMD',
  },
  {
    name: 'Honduras',
    alpha3: 'HND',
  },
  {
    name: 'Hong Kong',
    alpha3: 'HKG',
  },
  {
    name: 'Hungary',
    alpha3: 'HUN',
  },
  {
    name: 'Iceland',
    alpha3: 'ISL',
  },
  {
    name: 'India',
    alpha3: 'IND',
  },
  {
    name: 'Indonesia',
    alpha3: 'IDN',
  },
  {
    name: 'Iran',
    alpha3: 'IRN',
  },
  {
    name: 'Iraq',
    alpha3: 'IRQ',
  },
  {
    name: 'Ireland',
    alpha3: 'IRL',
  },
  {
    name: 'Isle of Man',
    alpha3: 'IMN',
  },
  {
    name: 'Israel',
    alpha3: 'ISR',
  },
  {
    name: 'Italy',
    alpha3: 'ITA',
  },
  {
    name: 'Jamaica',
    alpha3: 'JAM',
  },
  {
    name: 'Japan',
    alpha3: 'JPN',
  },
  {
    name: 'Jersey',
    alpha3: 'JEY',
  },
  {
    name: 'Jordan',
    alpha3: 'JOR',
  },
  {
    name: 'Kazakhstan',
    alpha3: 'KAZ',
  },
  {
    name: 'Kenya',
    alpha3: 'KEN',
  },
  {
    name: 'Kiribati',
    alpha3: 'KIR',
  },
  {
    name: 'Kosovo',
    alpha3: 'XKX',
  },
  {
    name: 'Kuwait',
    alpha3: 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    alpha3: 'KGZ',
  },
  {
    name: "Lao People's Democratic Republic",
    alpha3: 'LAO',
  },
  {
    name: 'Latvia',
    alpha3: 'LVA',
  },
  {
    name: 'Lebanon',
    alpha3: 'LBN',
  },
  {
    name: 'Lesotho',
    alpha3: 'LSO',
  },
  {
    name: 'Liberia',
    alpha3: 'LBR',
  },
  {
    name: 'Libya',
    alpha3: 'LBY',
  },
  {
    name: 'Liechtenstein',
    alpha3: 'LIE',
  },
  {
    name: 'Lithuania',
    alpha3: 'LTU',
  },
  {
    name: 'Luxembourg',
    alpha3: 'LUX',
  },
  {
    name: 'Macao',
    alpha3: 'MAC',
  },
  {
    name: 'Madagascar',
    alpha3: 'MDG',
  },
  {
    name: 'Malawi',
    alpha3: 'MWI',
  },
  {
    name: 'Malaysia',
    alpha3: 'MYS',
  },
  {
    name: 'Maldives',
    alpha3: 'MDV',
  },
  {
    name: 'Mali',
    alpha3: 'MLI',
  },
  {
    name: 'Malta',
    alpha3: 'MLT',
  },
  {
    name: 'Marshall Islands',
    alpha3: 'MHL',
  },
  {
    name: 'Martinique',
    alpha3: 'MTQ',
  },
  {
    name: 'Mauritania',
    alpha3: 'MRT',
  },
  {
    name: 'Mauritius',
    alpha3: 'MUS',
  },
  {
    name: 'Mayotte',
    alpha3: 'MYT',
  },
  {
    name: 'Mexico',
    alpha3: 'MEX',
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha3: 'FSM',
  },
  {
    name: 'Moldova, Republic of',
    alpha3: 'MDA',
  },
  {
    name: 'Monaco',
    alpha3: 'MCO',
  },
  {
    name: 'Mongolia',
    alpha3: 'MNG',
  },
  {
    name: 'Montenegro',
    alpha3: 'MNE',
  },
  {
    name: 'Montserrat',
    alpha3: 'MSR',
  },
  {
    name: 'Morocco',
    alpha3: 'MAR',
  },
  {
    name: 'Mozambique',
    alpha3: 'MOZ',
  },
  {
    name: 'Myanmar',
    alpha3: 'MMR',
  },
  {
    name: 'Namibia',
    alpha3: 'NAM',
  },
  {
    name: 'Nauru',
    alpha3: 'NRU',
  },
  {
    name: 'Nepal',
    alpha3: 'NPL',
  },
  {
    name: 'Netherlands',
    alpha3: 'NLD',
  },
  {
    name: 'Netherlands Antilles',
    alpha3: 'ANT',
  },
  {
    name: 'New Caledonia',
    alpha3: 'NCL',
  },
  {
    name: 'New Zealand',
    alpha3: 'NZL',
  },
  {
    name: 'Nicaragua',
    alpha3: 'NIC',
  },
  {
    name: 'Niger',
    alpha3: 'NER',
  },
  {
    name: 'Nigeria',
    alpha3: 'NGA',
  },
  {
    name: 'Niue',
    alpha3: 'NIU',
  },
  {
    name: 'Norfolk Island',
    alpha3: 'NFK',
  },
  {
    name: 'North Korea',
    alpha3: 'PRK',
  },
  {
    name: 'North Macedonia',
    alpha3: 'MKD',
  },
  {
    name: 'Northern Mariana Islands',
    alpha3: 'MNP',
  },
  {
    name: 'Norway',
    alpha3: 'NOR',
  },
  {
    name: 'Oman',
    alpha3: 'OMN',
  },
  {
    name: 'Pakistan',
    alpha3: 'PAK',
  },
  {
    name: 'Palau',
    alpha3: 'PLW',
  },
  {
    name: 'Palestine',
    alpha3: 'PSE',
  },
  {
    name: 'Panama',
    alpha3: 'PAN',
  },
  {
    name: 'Papua New Guinea',
    alpha3: 'PNG',
  },
  {
    name: 'Paraguay',
    alpha3: 'PRY',
  },
  {
    name: 'Peru',
    alpha3: 'PER',
  },
  {
    name: 'Philippines',
    alpha3: 'PHL',
  },
  {
    name: 'Pitcairn',
    alpha3: 'PCN',
  },
  {
    name: 'Poland',
    alpha3: 'POL',
  },
  {
    name: 'Portugal',
    alpha3: 'PRT',
  },
  {
    name: 'Puerto Rico',
    alpha3: 'PRI',
  },
  {
    name: 'Qatar',
    alpha3: 'QAT',
  },
  {
    name: 'Réunion',
    alpha3: 'REU',
  },
  {
    name: 'Romania',
    alpha3: 'ROU',
  },
  {
    name: 'Russia',
    alpha3: 'RUS',
  },
  {
    name: 'Rwanda',
    alpha3: 'RWA',
  },
  {
    name: 'Saint Barthélemy',
    alpha3: 'BLM',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha3: 'SHN',
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha3: 'KNA',
  },
  {
    name: 'Saint Lucia',
    alpha3: 'LCA',
  },
  {
    name: 'Saint Martin',
    alpha3: 'MAF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha3: 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha3: 'VCT',
  },
  {
    name: 'Samoa',
    alpha3: 'WSM',
  },
  {
    name: 'San Marino',
    alpha3: 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    alpha3: 'STP',
  },
  {
    name: 'Saudi Arabia',
    alpha3: 'SAU',
  },
  {
    name: 'Senegal',
    alpha3: 'SEN',
  },
  {
    name: 'Serbia',
    alpha3: 'SRB',
  },
  {
    name: 'Seychelles',
    alpha3: 'SYC',
  },
  {
    name: 'Sierra Leone',
    alpha3: 'SLE',
  },
  {
    name: 'Singapore',
    alpha3: 'SGP',
  },
  {
    name: 'Sint Maarten',
    alpha3: 'SXM',
  },
  {
    name: 'Slovakia',
    alpha3: 'SVK',
  },
  {
    name: 'Slovenia',
    alpha3: 'SVN',
  },
  {
    name: 'Solomon Islands',
    alpha3: 'SLB',
  },
  {
    name: 'Somalia',
    alpha3: 'SOM',
  },
  {
    name: 'South Africa',
    alpha3: 'ZAF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha3: 'SGS',
  },
  {
    name: 'South Korea',
    alpha3: 'KOR',
  },
  {
    name: 'South Sudan',
    alpha3: 'SSD',
  },
  {
    name: 'Spain',
    alpha3: 'ESP',
  },
  {
    name: 'Sri Lanka',
    alpha3: 'LKA',
  },
  {
    name: 'Sudan',
    alpha3: 'SDN',
  },
  {
    name: 'Suriname',
    alpha3: 'SUR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha3: 'SJM',
  },
  {
    name: 'Swaziland',
    alpha3: 'SWZ',
  },
  {
    name: 'Sweden',
    alpha3: 'SWE',
  },
  {
    name: 'Switzerland',
    alpha3: 'CHE',
  },
  {
    name: 'Syria',
    alpha3: 'SYR',
  },
  {
    name: 'Taiwan',
    alpha3: 'TWN',
  },
  {
    name: 'Tajikistan',
    alpha3: 'TJK',
  },
  {
    name: 'Tanzania',
    alpha3: 'TZA',
  },
  {
    name: 'Thailand',
    alpha3: 'THA',
  },
  {
    name: 'Timor-Leste',
    alpha3: 'TLS',
  },
  {
    name: 'Togo',
    alpha3: 'TGO',
  },
  {
    name: 'Tokelau',
    alpha3: 'TKL',
  },
  {
    name: 'Tonga',
    alpha3: 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    alpha3: 'TTO',
  },
  {
    name: 'Tunisia',
    alpha3: 'TUN',
  },
  {
    name: 'Turkey',
    alpha3: 'TUR',
  },
  {
    name: 'Turkmenistan',
    alpha3: 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    alpha3: 'TCA',
  },
  {
    name: 'Tuvalu',
    alpha3: 'TUV',
  },
  {
    name: 'Uganda',
    alpha3: 'UGA',
  },
  {
    name: 'Ukraine',
    alpha3: 'UKR',
  },
  {
    name: 'United Arab Emirates',
    alpha3: 'ARE',
  },
  {
    name: 'United Kingdom',
    alpha3: 'GBR',
  },
  {
    name: 'United States of America',
    alpha3: 'USA',
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha3: 'UMI',
  },
  {
    name: 'Uruguay',
    alpha3: 'URY',
  },
  {
    name: 'Uzbekistan',
    alpha3: 'UZB',
  },
  {
    name: 'Vanuatu',
    alpha3: 'VUT',
  },
  {
    name: 'Vatican City State (Holy See)',
    alpha3: 'VAT',
  },
  {
    name: 'Venezuela',
    alpha3: 'VEN',
  },
  {
    name: 'Vietnam',
    alpha3: 'VNM',
  },
  {
    name: 'Virgin Islands (British)',
    alpha3: 'VGB',
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha3: 'VIR',
  },
  {
    name: 'Wallis and Futuna',
    alpha3: 'WLF',
  },
  {
    name: 'Western Sahara',
    alpha3: 'ESH',
  },
  {
    name: 'Yemen',
    alpha3: 'YEM',
  },
  {
    name: 'Zambia',
    alpha3: 'ZMB',
  },
  {
    name: 'Zimbabwe',
    alpha3: 'ZWE',
  },
  {
    name: 'Everywhere Else',
    alpha3: '*',
  },
];

export const Countries = CountriesArray.map(country => country.name);

export const CountriesAlpha3 = {};
export const CountryCodeMap = {};
CountriesArray.forEach((country) => {
  CountriesAlpha3[country.name] = country.alpha3;
  CountryCodeMap[country.alpha3] = country;
});
