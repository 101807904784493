<template>
<form class="credit-card-form form-wrap">
  <TitledInput
    :value="value.name"
    :title="$t('form.credit_card.labels.name')"
    :placeholder="$t('form.credit_card.placeholders.name')"
    :errorMessage="errors.name"
    autocomplete="cc-name"
    @input="handleFormInput('name', $event)"
  />
  <div class="card-row2">
    <TitledInput
      class="card-number"
      :value="value.card_number"
      :title="$t('form.credit_card.labels.card_number')"
      :placeholder="$t('form.credit_card.placeholders.card_number')"
      :errorMessage="errors.card_number"
      mask="#### #### #### ####"
      autocomplete="cc-number"
      @input="handleFormInput('card_number', $event)"
    />
    <TitledInput
      :value="value.expiration"
      class="card-expire"
      :title="$t('form.credit_card.labels.expiration')"
      :placeholder="$t('form.credit_card.placeholders.expiration')"
      :errorMessage="errors.expiration"
      :maxLength="5"
      mask="##/##"
      autocomplete="cc-exp"
      @input="handleFormInput('expiration', $event)"
    />
    <TitledInput
      v-if="hasCVV"
      :value="value.cvv"
      class="card-cvv"
      :title="$t('form.credit_card.labels.cvv')"
      placeholder="000"
      :errorMessage="errors.cvv"
      mask="####"
      @input="handleFormInput('cvv', $event)"
    />
  </div>
</form>
</template>

<script>
import moment from 'moment';
import TitledInput from '@/components/widget/TitledInput.vue';
import { validateCreditCard, validateCardName, validateDate } from '@/utils/stringUtils';
import formMixin from '@/mixins/Form';

export default {
  name: 'credit-card-form',
  components: {
    TitledInput,
  },
  mixins: [formMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        card_number: '',
        name: '',
        expiration: '',
        cvv: '',
      }),
    },
    formName: {
      type: String,
      default: 'credit_card',
    },
    hasCVV: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules: {
        card_number: {
          required: true,
          validator: validateCreditCard,
        },
        name: {
          required: true,
          validator: validateCardName,
        },
        expiration: {
          required: true,
          validator: str => (
            validateDate(str, 'MM/YY') && moment(str, 'MM/YY').isAfter(moment())
          ),
        },
        cvv: {
          required: this.hasCVV,
          validator: str => (str.length === 3) || (str.length === 4),
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.credit-card-form {
  .card-row2 {
    display: flex;
    flex-wrap: wrap;
    .card-number {
      padding-right: 16px;
      width: 52%;
    }
    .card-expire {
      padding-right: 16px;
      width: 28%;
      min-width: 78px;
    }
    .card-cvv {
      width: 20%;
    }
  }
  @media (max-width: 1176px) {
    .card-row2 {
      .card-number {
        padding-right: 0;
        width: 100%;
      }
      .card-expire, .card-cvv {
        width: 50%;
      }
    }
  }
}
</style>
