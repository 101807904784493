<template>
<div class="edit-email">
  <form class="edit-email-form" @submit="handleSubmit">
    <TitledInput
      v-model="password"
      name="password"
      class="form-input"
      type="password"
      :placeholder="$t('auth.placeholders.current_password')"
      @input="clearErrors"
    />
    <TitledInput
      v-model="newEmail"
      name="new-email"
      class="form-input"
      type="email"
      :placeholder="$t('user.private.account.placeholders.new_email')"
      @input="clearErrors"
    />
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div
      class="edit-email-cancel"
      @click="$emit('close')"
    >
      {{ $t('cancel') }}
    </div>
    <button
      type="submit"
      class="edit-email-submit"
      :disabled="errorMessage"
    >
      {{ $t('settings.account.email.update') }}
    </button>
  </form>
</div>
</template>

<script>
import TitledInput from '@/components/widget/TitledInput.vue';
import { fieldErrorKey } from '@/utils/apiError';
import { USER_EMAIL_UPDATE } from '@/store/actions';

export default {
  name: 'edit-email',
  components: {
    TitledInput,
  },
  data() {
    return {
      newEmail: '',
      password: '',
      errorMessage: null,
    };
  },
  methods: {
    clearErrors() {
      this.errorMessage = null;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.clearErrors();
      const { password, newEmail } = this;
      if(!newEmail || newEmail === '') {
        this.errorMessage = this.$t('errors.EMAIL_MISSING');
      } else if(!password || password === '') {
        this.errorMessage = this.$t('errors.PASSWORD_MISSING');
      } else {
        try {
          await this.$store.dispatch(USER_EMAIL_UPDATE, { password, email: newEmail });
          this.$emit('close');
        } catch(err) {
          this.errorMessage = this.$t(fieldErrorKey(err.errors));
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.edit-email {
  .validate {
    @include p_info;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 15px;
    .validate-item {
      margin-right: 10px;
      height: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        height: 15px;
        width: 15px;
        margin-right: 4px;
      }
    }
    .check {
      padding-bottom: 2px;
      width: 12px;
      filter: invert(19%) sepia(96%) saturate(2869%) hue-rotate(234deg) brightness(91%) contrast(103%);
    }
    .close {
      padding-bottom: 1px;
      width: 10px;
      filter: invert(11%) sepia(99%) saturate(6577%) hue-rotate(2deg) brightness(99%) contrast(112%);
    }
  }
  .edit-email-cancel {
    @include button_small_text;
    cursor: pointer;
    color: $main-orange;
    display: inline-block;
    margin-right: 24px;
  }
  .edit-email-submit {
    @include button($main-black);
    margin-top: 40px;
  }
  .error-message {
    @include error;
  }
}
</style>
